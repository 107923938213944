import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VentaService {

    private http = inject(HttpClient);

    /**
     * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
     * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
     */
    private ruta: string = `${environment.base_url}/venta/variables`;


    /**
   * Obtenemos las variables que corresponde al tipo de documento cargado
   * @returns data en estructura JSON proporcionada de manera directa por la API
   */
    public getVariables(id: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/getVariables/${id}`, {});
    }

    /**
     * Obtenemos la datos de la API
     * @param referencia Informacion adicional para filtrar la busqueda
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public getDatos(id_con_periodo: number, id_periodo: number, referencia: string): Observable<any> {
        let parametros = new HttpParams()
            .append('id_con_periodo', String(id_con_periodo))
            .append('id_periodo', String(id_periodo))
            .append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/getDatos`, { params: parametros });
    }

    /**
     * Metodo para mandar data a la API para almacenarla en la DB
     * @param modelo Objeto el cual contiene estructura e informacion para ser almacenada en la DB
     * es importante que la estructura del objeto (modelo) sea identica dentro de Angular y de la API
     * @returns
     */
    public setVariables(id: number, modelo: any): Observable<any> {
        return this.http.post(`${this.ruta}/setVariables/${id}`, modelo);
    }

    /**
     * Metodo para eliminar registri dentro de la DB
     * @param id Numero o identificador del registro que será eliminado
     * Las reglas de eliminación se procesaran dentro de la DB con sus propias reglas como son constraint
     * así como posibles trigger
     * @returns
     */
    public deleteDato(id: number): Observable<any> {
        return this.http.delete(`${this.ruta}/deleteDato/id`, {});
    }

    /**
     * Metodo para actualizar informacion dentro de la DB
     * @param modelo Objeto que contiene estructura e informacion para ser almacenada
     * @returns
     */
    public putDato(modelo: any): Observable<any> {
        return this.http.put(`${this.ruta}/putDato`, modelo);
    }


}
