<section>
    <div class="table-responsive">
        <table *ngIf="this.data" class="table table-hover table-bordered">
            <thead>
                <th *ngFor="let columna of this.columnas">
                    {{columna | translate}}
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.data">
                    <td *ngFor="let columna of this.columnas">
                        <ng-container>
                            <div *ngIf="typeOf(item[columna]) === 'string'">
                                {{item[columna]}}
                            </div>
                            <div *ngIf="typeOf(item[columna]) === 'number'" style="text-align: right;">
                                {{item[columna] | number: '.2-2'}}
                            </div>
                            
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>