import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DomicilioService {

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Metodo para buscar Cliente y domicilio.
   * Esto sera utilizado para documentos de venta donde es necesario obtener
   * el ID del cliente y el ID del domicilio del mismo cliente
   * @param referencia
   * @returns
   */
  public getClienteDomicilio(referencia: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('referencia', referencia);
    return this.http.get<any>(`${environment.base_url}/venta/cliente/getClienteDomicilio`, { params: parametros });
  }
}
