import { CommonModule } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, Component, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { IConversacionMessage } from '../../interfaces/conversacion-message';


@Component({
  selector: 'app-conversacion',
  standalone: true,
  templateUrl: './conversacion.component.html',
  styleUrls: ['./conversacion.component.scss'],
  imports: [CommonModule]
})
export class ConversacionComponent implements AfterViewChecked {
  @ViewChild('conversationContainer', { static: true }) conversationContainer!: ElementRef<HTMLDivElement>;

  @Input() messages: IConversacionMessage[] = [];

  ngAfterViewChecked(): void {
    const calculatedTop = this.conversationContainer.nativeElement.scrollHeight
      - this.conversationContainer.nativeElement.clientHeight;
    if (calculatedTop != this.conversationContainer.nativeElement.scrollTop)
      this.conversationContainer.nativeElement.scrollTop = calculatedTop;
  }

}
