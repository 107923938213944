import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Grid } from '@syncfusion/ej2-grids';
import { ProductoService } from '../../services/MMinventario/Producto.service';
import { EmitType } from '@syncfusion/ej2-base';

@Component({
    selector: 'dlgProducto',
    templateUrl: './dlgProducto.component.html',
    styleUrls: ['./dlgProducto.component.scss']
})
export class DlgProductoComponent {
    @ViewChild('tabla') tabla: Grid;
    @ViewChild('clasificacion') clasificacion: DropDownListComponent;
    @ViewChild('dlgProducto') public dlgProducto: DialogComponent;
    public fields: Object = { text: 'descripcion', value: 'id' };
    public data_clasificacion: any;
    @Output('producto') producto = new EventEmitter<any>();
    @ViewChild('referencia') referencia: ElementRef;
    public data: any;
    public columns: Object[];
    ClickedRow: any;
    // ===============================================================================================
    // Configuracion de botones para le ventana emergente
    // isPrimary: false | true = Configuramos el boton por default. Si damos Enter ejecuta este boton
    // ===============================================================================================
    public buttons: Object = [
        {
            click: this.cerrar.bind(this),
            buttonModel: {
                content: 'Cerrar',
                isPrimary: false
            },
        },
    ];
    UtilComponent: any;

    @Input('show') show() {
        this.referencia.nativeElement.value = '';
        this.data = undefined;
        this.clasificacion.value = -1;
        this.dlgProducto.show();
    };

    @Input('hide') hide() {
        this.dlgProducto.hide();
    };

    public dialogOpen = (): void => { }

    constructor(
        private ProductoService: ProductoService
    ) {
        this.ClickedRow = function (index) {
            this.HighlightRow = index;
            this.dlgProducto.hide();
            this.Numero.emit(index);
        }
        this.ProductoService.getClasificacion().subscribe((resp) => {
            this.data_clasificacion = resp.respuesta;
        });
    }

    public cerrar(): void {
        this.dlgProducto.hide();
    }

    public btnbuscar() {
        if (this.referencia.nativeElement.value == '') {
            this.UtilComponent = DialogUtility.confirm({
                title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>Es posible que la busqueda devuelva demasiados registros, ¿Deseas continuar?</b>',
                okButton: { text: 'Si', click: this.btnbuscar2.bind(this) },
                cancelButton: { text: 'No' },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
        } else {
            this.btnbuscar2();
        }
    }

    public btnbuscar2: EmitType<object> = () => {
        this.ProductoService.buscar_Producto(this.referencia.nativeElement.value, this.clasificacion.value).subscribe(resp => {
            this.columns = JSON.parse(JSON.stringify(resp)).respuesta.columns;
            this.data = JSON.parse(JSON.stringify(resp)).respuesta.dataSource;
        });
        if (this.UtilComponent) {
            this.UtilComponent.hide();
        }
    };

    /**
     * Se encarga de obtener el dato del componente y enviarlo al EventEmitter
     */
    public onRowSelected(args: any) {
        this.dlgProducto.hide();
        // ====================================================================
        // Retornamos un JSON para regresar un conjutno de informacion
        // ====================================================================
        this.producto.emit(
            {
                id: args.data.id,
                nombre: args.data.descripcion,
                codigo: args.data.codigo,
                precio: args.data.precio
            }
        );
    }



}
