import { Component, Input } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DocumentoComponent } from 'src/app/pages/SDventa/documento/documento.component';
import { TableroService } from '../../services/tablero.service';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';

@Component({
    selector: 'tabla',
    templateUrl: './tabla.component.html',
    styleUrls: ['./tabla.component.scss']
})
export class TablaComponent {

    @Input('data') data: any;
    @Input('id') id: number = -1;
    //public data: any;
    public columnas: any = [];
    public tipos: any = [];

    constructor(
        private servicio: TableroService,
        private offcanvasService: NgbOffcanvas,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.servicio.getTabla(this.id).subscribe(resp => {
            if (resp.numero > 0) {
                this.data = resp.respuesta.datos;
                this.columnas = resp.respuesta.columnas
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }
        });
    }

    public typeOf(value) {
        return typeof value;
    }

    openByLink(link: string) {
        const [tipo, id] = link.split('/'); // Separa el tipo y el ID
        const documentoId = parseInt(id, 10);

        if (tipo === 'venta') {
            const modalRef = this.offcanvasService.open(DocumentoComponent, { position: 'end' });
            modalRef.componentInstance.id = documentoId;
        } else if (tipo === 'remision') {
            //this.openDocumento(documentoId, RemisionComponent);
        }
    }

}
