<div class="modal-header">
    <h5 class="modal-title">Seleccion de Documento</h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <span *ngFor="let item of data">
      <button class="btn btnEstatus" (click)="setDocumento(item.id)"
        [ngStyle]="{'background-color': item.color, 'color': item.texto_color}">
        <i class="fa-solid fa-circle-check"></i>
        {{item.descripcion}}
      </button>
    </span>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cerrar" aria-label="Close" (click)="close()">
      Cerrar
    </button>
  </div>
  