import { Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ContactoService } from '../../services/Contactos.service';
import { MdlContacto } from '../../models/MdlContacto';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { DlgClienteDomicilio } from 'src/app/components/dlgClienteDomicilio/dlgClienteDomicilio.component';
import { DlgcorreoComponent } from 'src/app/components/dlgcorreo/dlgcorreo.component';
import { DlgActividadComponent } from '../../../actividades/components/actividad/actividad.component';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.js';
import BlotFormatter from 'quill-blot-formatter';
import { QuillEditorComponent, QuillViewHTMLComponent } from 'ngx-quill';
import { MdlComentario } from '../../models/MdlComentario.model';
import { DlgPlantillas } from '../plantillas/plantillas.component';
import { MdlLog } from '../../models/MdlLog.model';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';
Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
    selector: 'app-dlgcontacto',
    templateUrl: './dlgcontacto.component.html',
    styleUrls: ['./dlgcontacto.component.scss'],
})
export class DlgcontactoComponent {
    @BlockUI() blockUI: NgBlockUI;
    public fields: Object = { text: 'descripcion', value: 'id' };
    @Input('datos') datos: any;
    @Output('cerrar') cerrar = new EventEmitter<number>();
    @ViewChild('tfComentarios') tfComentarios: QuillEditorComponent;

    public formulario: UntypedFormGroup;
    public data_pais: any;
    public data_estado: any;
    public data_municipio: any;
    public data_clasificacion: any;
    public data_idioma: any;
    public data_origen: any;
    public data_nivel: any;
    public data_campana: any;
    public dataComentarios: any;
    public data_cliente: any;

    public modules = {
        'emoji-shortname': true,
        'emoji-textarea': false,
        'emoji-toolbar': true,
        blotFormatter: {},
        toolbar: {
			container: [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				['blockquote', 'code-block'],
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link', 'image', 'video'],
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				['clean'], // remove formatting button
				['emoji'],
			],
			handlers: { emoji: function () { } },
		},
    };

    constructor(
        public offcanvasService: NgbOffcanvas,
        private modalService: NgbModal,
        private servicio: ContactoService,
        private fb: UntypedFormBuilder
    ) {
        this.formulario = this.fb.group(new MdlContacto());

        /*
         * Metodo utilizado para mandar llamar el listado de paises
         *
         */
        this.servicio.get_paises().subscribe((resp) => {
            this.data_pais = resp.respuesta;
        });

        /*
         * Metodo utilizado para mandar llamar el listado de claisificaciones
         *
         */
        this.servicio.get_parametros().subscribe((resp) => {
            this.data_clasificacion = resp.respuesta.clasificacion;
            this.data_idioma = resp.respuesta.idioma;
            this.data_origen = resp.respuesta.origen;
            this.data_nivel = resp.respuesta.nivel;
            this.data_campana = resp.respuesta.campana;
        });
    }

    ngOnInit(): void { }

    /*
     * Metodo utilizado para mostrar los listados de los estdos y municipios
     *
     */
    ngAfterContentInit(): void {
        if (this.datos != undefined) {
            this.data_estado = this.datos.estados;
            this.data_municipio = this.datos.municipios;
            this.formulario = this.fb.group(this.datos.contacto as MdlContacto);
            this.servicio.getComentarios(this.datos.contacto.id).subscribe((resp) => {
                if (resp.numero > 0) {
                    this.dataComentarios = resp.respuesta;
                }
            });
        }
    }

    /*
     * Metodo utilizado para validar el nombre del contacto
     *
     */
    get nombrenNoValido() {
        return (
            this.formulario.get('nombre').invalid &&
            this.formulario.get('nombre').touched
        );
    }

    /*
     * Metodo utilizado para validar el telefono movil
     *
     */
    get TelefonoNoValido() {
        return (
            this.formulario.get('tel_movil').invalid &&
            this.formulario.get('tel_movil').touched
        );
    }

    /*
     * Metodo utilizado para Validar el correo electronico
     *
     */
    get CorreoNoValido() {
        return (
            this.formulario.get('correo').invalid &&
            this.formulario.get('correo').touched
        );
    }

    /*
     * Metodo utilizado para validar el formulario
     *
     */
    public revisa() {
        if (this.formulario.invalid) {
            return Object.values(this.formulario.controls).forEach(
                (control) => {
                    control.markAsTouched();
                }
            );
        }
    }

    /*
     * Metodo utilizado para mandar llamar los Estados con el id del pais
     *
     */
    public getEstados() {
        if (this.formulario.controls['id_pais'].value > 0) {
            this.servicio.get_estados(this.formulario.controls['id_pais'].value).subscribe((resp) => {
                this.data_estado = resp.respuesta;
            });
        }
    }

    /*
     * Metodo utilizado para mandar llamar los municipios con el id del estado
     *
     */
    public getMunicipios() {
        if (this.formulario.controls['id_estado'].value > 0) {
            this.servicio.get_municipios(this.formulario.controls['id_estado'].value).subscribe((resp) => {
                this.data_municipio = resp.respuesta;
            });
        }
    }

    /*
     * Metodo utilizado para guardar contactos
     *
     */
    public Guardar() {
        this.revisa();
        if (!this.formulario.invalid) {
            this.blockUI.start('Procesando...');
            this.servicio.guardar(this.formulario.value).subscribe(resp => {
                if (resp.numero > 0) {
                    this.formulario.controls['id'].setValue(resp.numero);
                    const modalRef = this.modalService.open(dlgMensaje);
                    modalRef.componentInstance.encabezado = resp.titulo;
                    modalRef.componentInstance.contenido = resp.mensaje;
                    //this.cerrar.emit(1);
                    //this.modalService.dismissAll();
                    this.blockUI.stop();
                } else {
                    this.blockUI.stop();
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = resp.titulo;
                    modalRef.componentInstance.contenido = resp.mensaje;
                }
            });
        }
    }

    /*
     * Metodo utilizado para buscar clientes
     *
     */
    public btnBuscarCliente() {
        const modalRef = this.modalService.open(DlgClienteDomicilio);
        modalRef.componentInstance.cliente.subscribe((resp) => {
            this.formulario.controls['id_cliente'].setValue(resp.id);
            this.formulario.controls['cliente'].setValue(resp.nombre);
        });
    }

    /**
     * Metodo para enviar la información a guardar para el contacto seleccionado.
     * El ID del usuario (id_rh_empleado), se asigna dentro de la API, por este motivo
     * no es necesario mandarlos desde aqui, pero es necesario para cumplir con el modelo.
     *
     */
    public btnComentarios() {
        const body: MdlComentario = {
            id: -1,
            id_rh_empleado: -1,
            id_cliente_contacto: this.formulario.get('id').value,
            comentario: this.tfComentarios.quillEditor.root.innerHTML,
        };
        this.servicio.setComentarios(body).subscribe((resp) => {
            if (JSON.parse(JSON.stringify(resp)).numero > 0) {
                this.tfComentarios.quillEditor.root.innerHTML = '';
                this.dataComentarios = JSON.parse(JSON.stringify(resp)).respuesta;
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
            }
            this.blockUI.stop();
        });
    }

    public btnWhatsapp() { }

    public btnSMS() { }

    /*
     * Metodo utilizado para enviar correos
     * En caso del CRM se requiere mostrar opciones de plantillas de correo existentes
     * para poder utilizarlas y enviar dicha información previamente construida
     *
     */
    public btnCorreo() {
        const modalRef = this.modalService.open(DlgPlantillas);
        modalRef.componentInstance.respuesta.subscribe(resp => {
            if (Number(resp) > 0) {
                // Si tenemos respuesta > 0, entonces seleccionamos una plantilla
                this.servicio.getCorreoPlantilla(Number(resp)).subscribe(resp => {
                    if (resp.numero > 0) {
                        const informacion = {
                            mensaje: resp.respuesta.texto,
                            correo: this.formulario.controls['correo'].value,
                            asunto: resp.respuesta.descripcion
                        };
                        const modalRef = this.modalService.open(DlgcorreoComponent);
                        modalRef.componentInstance.informacion = informacion;
                        modalRef.componentInstance.modulo = 'crm';
                        modalRef.componentInstance.respuesta.subscribe(resp => {
                            // Si el correo se envio de manera correcta se debe registrar
                            // del log para dar seguimiento
                            if (Number(resp.id) > 0) {
                                let modelo = new MdlLog();
                                modelo.id_cliente_contacto = this.formulario.controls['id'].value;
                                modelo.observaciones = resp.descripcion;
                                this.servicio.setLog(modelo).subscribe(resp => {
                                    if (resp.numero > 0) {
                                        // se registro log
                                    } else {
                                        const modalRef = this.modalService.open(dlgMensajeError);
                                        modalRef.componentInstance.encabezado = resp.titulo;
                                        modalRef.componentInstance.contenido = resp.mensaje;
                                    }
                                });
                            }
                        });

                    } else {
                        const modalRef = this.modalService.open(dlgMensajeError);
                        modalRef.componentInstance.encabezado = resp.titulo;
                        modalRef.componentInstance.contenido = resp.mensaje;
                    }
                });
            } else {
                // Al no seleccionar una plantilla se abre la ventana vacia para redactar un correo
                const informacion = {
                    mensaje: '',
                    correo: this.formulario.controls['correo'].value,
                    asunto: '',
                };
                const modalRef = this.modalService.open(DlgcorreoComponent);
                modalRef.componentInstance.informacion = informacion;
                modalRef.componentInstance.modulo = 'crm';
            }
        });
    }

    /**
     * Metodo para crear una actividad y esta quede regitrada dentro del tablero correspondiente
     */
    public btnActividad() {
        const modalRef = this.offcanvasService.open(DlgActividadComponent, { position: 'end', panelClass: 'clsPanel', });
        modalRef.componentInstance.id_cliente_contacto = this.formulario.controls['id'].value;
        modalRef.componentInstance.estatus.subscribe($event => {
            // No se que hacer aqui aun
        });
    }
}
