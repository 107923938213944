import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { environment } from 'src/environments/environment';
import { MdlSubtarea } from '../../models/MdlSubtarea';
import { ActividadService } from '../../services/actividad.service';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.js';
import BlotFormatter from 'quill-blot-formatter';
import { QuillEditorComponent, QuillViewHTMLComponent } from 'ngx-quill';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { DlgClienteContacto } from 'src/app/components/dlgClienteContacto/dlgClienteContacto.component';
import { MdlActividad } from '../../models/MdlActividad';
import { ContactoService } from '../../../contactos/services/Contactos.service';
import { DlgcontactoComponent } from '../../../contactos/components/dlgcontacto/dlgcontacto.component';
import { DlgEmpleadoComponent } from 'src/app/components/dlgEmpleado/dlgEmpleado.component';


Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
	selector: 'DlgActividad',
	templateUrl: './actividad.component.html',
	styleUrls: ['./actividad.component.scss'],
})
export class DlgActividadComponent {
	@BlockUI() blockUI: NgBlockUI;
	@Input('id') id: number = -1;
	@Input('id_cliente_contacto') id_cliente_contacto: number = -1;
	@Output('cerrar') cerrar = new EventEmitter<number>();
	@ViewChild('dlgSubtarea') dlgSubtarea: Component;
	@ViewChild('tfComentarios') tfComentarios: QuillEditorComponent;
	@ViewChild('tfSubtarea') tfSubtarea: ElementRef;

	private archivos: UploaderComponent;
	public dataComentarios: any;
	public dataArchivos: any;
	public dataSubtareas: any;
	public editable = true;
	public frmSubtarea: UntypedFormGroup;
	public formulario: UntypedFormGroup;

	public modules = {
		'emoji-shortname': true,
		'emoji-textarea': false,
		'emoji-toolbar': true,
		blotFormatter: {},
		toolbar: {
			container: [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				['blockquote', 'code-block'],
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link', 'image', 'video'],
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				['clean'], // remove formatting button
				['emoji'],
			],
			handlers: { emoji: function () { } },
		},
	};

	private ruta = `${environment.base_url}/correo/enviarCorreoCompra`;

	constructor(
		private modalService: NgbModal,
		public modalActivo: NgbActiveModal,
		public offcanvasService: NgbOffcanvas,
		private fb: UntypedFormBuilder,
		private servicio: ActividadService,
		private http: HttpClient,
		private servicioContacto: ContactoService
	) {
		this.crearFormulario();
		this.frmSubtarea = this.fb.group(new MdlSubtarea());
	}

	ngOnInit(): void { }

	ngAfterViewInit() {
		this.formulario.controls['id'].setValue(this.id);
		this.formulario.controls['id_cliente_contacto'].setValue(this.id_cliente_contacto);
		this.cargaActividad();
	}

	ngOnDestroy() {
		this.cerrar.emit(-1);
	}

	crearFormulario() {
		this.formulario = this.fb.group(new MdlActividad);
	}

	public files: NgxFileDropEntry[] = [];

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					let formData = new FormData();
					formData.append('archivo', file, droppedFile.relativePath);
					formData.append('Content-Type', 'application/json');
					formData.append('Accept', `application/json`);
					this.servicio.setArchivos(this.formulario.get('id').value, formData).subscribe((resp) => {
						//==============================================================================
						// Listamos los archivos que tiene la actividad
						//==============================================================================
						this.servicio.cargaArchivos(this.formulario.get('id').value).subscribe((resp) => {
							this.dataArchivos = resp.respuesta;
						});
					});
				});
			} else {
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	public openFileSelector() {
		document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
		return false;
	}

	/**
	 * Metodo para cargar la actividad seleccionada en el formulario
	 * así como las subtareas asignadas a dicha actividad
	 * @param id Numero de Actividad
	 */
	public cargaActividad(): void {
		// Si tenemos un ID mayor a cero, entonces tenemos una actividad ya creada de manera previa
		if (this.formulario.get('id').value > 0) {
			this.blockUI.start('Procesando...');
			this.servicio.getActividad(this.formulario.get('id').value).subscribe(resp => {
				if (resp.numero > 0) {
					this.formulario = this.fb.group(resp.respuesta as MdlActividad);
					this.editable = JSON.parse(resp.respuesta.editable);
					this.dataComentarios = resp.respuesta.comentarios;
					this.servicio.getListadoSubtareas(this.formulario.get('id').value).subscribe((respSubtarea) => {
						this.dataSubtareas = respSubtarea.respuesta;
					});
					// =========================================================
					// Carga listado de archivos
					// =========================================================
					this.servicio.cargaArchivos(this.formulario.get('id').value).subscribe((resp) => {
						this.dataArchivos = resp.respuesta;
					});
					// =========================================================
					let subtarea = new MdlSubtarea();
					subtarea.id_crm_actividad = this.formulario.get('id').value;
					this.frmSubtarea = this.fb.group(subtarea);
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
				this.blockUI.stop();
			});
		}
	}

	/**
	 * Metodos para las validaciones de Nuevoformulario
	 */
	get paraNoValida() {
		return (
			this.formulario.get('para').invalid &&
			this.formulario.get('para').touched
		);
	}
	get asuntoNoValida() {
		return (
			this.formulario.get('asunto').invalid &&
			this.formulario.get('asunto').touched
		);
	}
	get mensajeNoValida() {
		return (
			this.formulario.get('mensaje').invalid &&
			this.formulario.get('mensaje').touched
		);
	}

	public revisa() {
		if (this.formulario.invalid) {
			return Object.values(this.formulario.controls).forEach(
				(control) => {
					control.markAsTouched();
				}
			);
		}
	}

	public validaActividad(): boolean {
		if (!this.formulario.get('descripcion').value) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Arcarius ERP';
			modalRef.componentInstance.contenido = 'El titulo no puede quedar vacio!';
			return false;
		}
		if (!this.formulario.get('texto').value) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Arcarius ERP';
			modalRef.componentInstance.contenido =
				'La descripcion no puede quedar vacia';
			return false;
		}
		return true;
	}

	public guardarActividad() {
		if (this.validaActividad()) {
			this.blockUI.start('Procesando...');
			this.http.post(`${environment.base_url}/rm/actividad/guardar`, this.formulario.value).subscribe((respuesta) => {
				this.formulario.controls['id'].setValue(JSON.parse(JSON.stringify(respuesta)).numero);
				if (this.formulario.get('id').value > 0) {
					const modalRef = this.modalService.open(dlgMensaje);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
				}
				this.blockUI.stop();
			});
		}
	}

	/**
	 * Metodo para abrir panel y mostrar informacion del cliente
	 * @param id Identificador del cliente
	 */
	public pnContacto() {
		if (this.formulario.controls['id_cliente_contacto'].value > 0) {
			this.servicioContacto.get_contacto(this.formulario.controls['id_cliente_contacto'].value).subscribe(resp => {
				const modalRef = this.offcanvasService.open(DlgcontactoComponent, { position: 'end', });
				modalRef.componentInstance.datos = resp.respuesta;
			});
		}
	}

	/**
	 * Metodo para asignar usuarios a una subtarea a nivel individual
	 */
	public btnAsignar(subtarea: any) {
		const modalRef = this.modalService.open(DlgEmpleadoComponent);
		modalRef.componentInstance.empleado.subscribe(($event) => {
			this.servicio.setTareaAsignar(subtarea.id, $event.id).subscribe(resp => {
				if (resp.numero > 0) {
					this.servicio.getListadoSubtareas(this.formulario.get('id').value).subscribe(respSubtarea => {
						this.dataSubtareas = respSubtarea.respuesta;
					});
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = resp.titulo;
					modalRef.componentInstance.contenido = resp.mensaje;
				}
			});
		});
	}

	/**
	 * Metodo para retirar usuarios a una subtarea a nivel individual
	 */
	public btnDesasignar(event: any) {
		console.log('Eliminar id: ', event.data.value);
		this.servicio.delTareaAsignar(event.data.value).subscribe(resp => {
			if (resp.numero > 0) {
				this.servicio.getListadoSubtareas(this.formulario.get('id').value).subscribe(respSubtarea => {
					this.dataSubtareas = respSubtarea.respuesta;
				});
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = resp.titulo;
				modalRef.componentInstance.contenido = resp.mensaje;
			}
		});

	}

	public btnModificarSubtarea(args: any) {
		this.frmSubtarea = this.fb.group(args);
		this.modalService.open(this.dlgSubtarea);

	}

	public abrirSubdialogo() {
		let subtarea = new MdlSubtarea();
		subtarea.id_crm_actividad = this.formulario.get('id').value;
		this.frmSubtarea = this.fb.group(subtarea);
		this.modalService.open(this.dlgSubtarea);
	}

	public btnSubtareaGuardar(): void {
		if (this.validaActividad()) {
			if (this.frmSubtarea.controls['descripcion'].value == '') {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Subtareas';
				modalRef.componentInstance.contenido = 'La descripcion no puede quedar vacia!';
				return;
			}
			this.blockUI.start('Procesando...');
			if (this.formulario.get('id').value < 0) {
				this.http.post(`${environment.base_url}/rm/actividad/guardar`, this.formulario.value).subscribe((respuesta) => {
					this.formulario.controls['id'].setValue(
						JSON.parse(JSON.stringify(respuesta)).numero
					);
					if (this.formulario.get('id').value > 0) {
						//this.getActividadListado();
						//=======================================================================================
						// Guardamos el Subtarea
						//=======================================================================================
						if (
							!this.frmSubtarea.get('id_crm_actividad').value ||
							this.frmSubtarea.get('id_crm_actividad').value < 0
						) {
							this.frmSubtarea.controls['id_crm_actividad'].setValue(
								this.formulario.get('id').value
							);
						}
						this.servicio
							.subtareaGuardar(this.frmSubtarea.value)
							.subscribe((resp) => {
								if (resp.numero > 0) {
									this.servicio
										.getListadoSubtareas(this.formulario.get('id').value)
										.subscribe((respSubtarea) => {
											this.dataSubtareas = respSubtarea.respuesta;
											this.frmSubtarea.controls['descripcion'].setValue('');
											this.frmSubtarea.controls['id'].setValue(-1);
											//=======================================================================================
											// Recuperamos los comentarios
											//=======================================================================================
											this.servicio
												.getListadoComentarios(
													this.formulario.get('id').value
												)
												.subscribe((resp) => {
													this.dataComentarios = resp.respuesta;
												});
										});
								} else {
									const modalRef = this.modalService.open(dlgMensajeError);
									modalRef.componentInstance.encabezado = JSON.parse(
										JSON.stringify(resp)
									).titulo;
									modalRef.componentInstance.contenido = JSON.parse(
										JSON.stringify(resp)
									).mensaje;
								}
							});
					} else {
						const modalRef = this.modalService.open(dlgMensajeError);
						modalRef.componentInstance.encabezado = JSON.parse(
							JSON.stringify(respuesta)
						).titulo;
						modalRef.componentInstance.contenido = JSON.parse(
							JSON.stringify(respuesta)
						).mensaje;
					}
				});
			} else {
				//=======================================================================================
				// Cuando ya existe un Id de actividad
				//=======================================================================================
				if (
					!this.frmSubtarea.get('id_crm_actividad').value ||
					this.frmSubtarea.get('id_crm_actividad').value < 0
				) {
					this.frmSubtarea.controls['id_crm_actividad'].setValue(
						this.formulario.get('id').value
					);
				}
				this.servicio
					.subtareaGuardar(this.frmSubtarea.value)
					.subscribe((resp) => {
						if (resp.numero > 0) {
							this.servicio
								.getListadoSubtareas(this.formulario.get('id').value)
								.subscribe((respSubtarea) => {
									this.dataSubtareas = respSubtarea.respuesta;
									this.frmSubtarea.controls['descripcion'].setValue('');
									this.frmSubtarea.controls['id'].setValue(-1);
									//=======================================================================================
									// Recuperamos los comentarios
									//=======================================================================================
									this.servicio
										.getListadoComentarios(this.formulario.get('id').value)
										.subscribe((resp) => {
											this.dataComentarios = resp.respuesta;
										});
								});
						} else {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = JSON.parse(
								JSON.stringify(resp)
							).titulo;
							modalRef.componentInstance.contenido = JSON.parse(
								JSON.stringify(resp)
							).mensaje;
						}
					});
			}
			this.modalService.dismissAll();
			this.blockUI.stop();
		}
	}

	public btnSubtareaEliminar(subtarea: MdlSubtarea): void {
		this.blockUI.start('Procesando...');
		this.servicio.subtareaEliminar(subtarea).subscribe((resp) => {
			if (resp.numero > 0) {
				this.servicio.getListadoSubtareas(this.formulario.get('id').value).subscribe((respSubtarea) => {
					this.dataSubtareas = respSubtarea.respuesta;
					//=======================================================================================
					// Recuperamos los comentarios
					//=======================================================================================
					this.servicio.getListadoComentarios(this.formulario.get('id').value).subscribe((resp) => {
						this.dataComentarios = resp.respuesta;
					});
				});
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
			}
			this.blockUI.stop();
		});
	}

	public btnSubtareaTerminada(args: any, subtarea: MdlSubtarea) {
		this.blockUI.start('Procesando...');
		subtarea.terminado = args.checked;
		this.servicio.subtareaGuardar(subtarea).subscribe((resp) => {
			if (resp.numero > 0) {
				this.servicio
					.getListadoSubtareas(this.formulario.get('id').value)
					.subscribe((respSubtarea) => {
						this.dataSubtareas = respSubtarea.respuesta;
						this.frmSubtarea.controls['descripcion'].setValue('');
						//=======================================================================================
						// Recuperamos los comentarios
						//=======================================================================================
						this.servicio
							.getListadoComentarios(this.formulario.get('id').value)
							.subscribe((resp) => {
								this.dataComentarios = resp.respuesta;
							});
					});
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(
					JSON.stringify(resp)
				).titulo;
				modalRef.componentInstance.contenido = JSON.parse(
					JSON.stringify(resp)
				).mensaje;
			}
			this.blockUI.stop();
		});
	}

	/**
   * Metodo para guardar los comentarios de las actividades
   */
	public guardarComentario() {
		if (this.validaActividad()) {
			if (this.formulario.get('id').value < 0) {
				this.http
					.post(
						`${environment.base_url}/rm/actividad/guardar`,
						this.formulario.value
					)
					.subscribe((respuesta) => {
						this.formulario.controls['id'].setValue(
							JSON.parse(JSON.stringify(respuesta)).numero
						);
						if (this.formulario.get('id').value > 0) {
							//this.getActividadListado();
							//=======================================================================================
							// Guardamos el comentario
							//=======================================================================================
							const body = {
								id: -1,
								id_crm_actividad: this.formulario.get('id').value,
								comentario: this.tfComentarios.quillEditor.root.innerHTML,
							};
							this.http
								.post(
									`${environment.base_url}/rm/actividad/guardarComentario`,
									body
								)
								.subscribe((resp) => {
									if (JSON.parse(JSON.stringify(resp)).numero > 0) {
										// Cargamos los comentarios
										this.tfComentarios.quillEditor.root.innerHTML = '';
										this.dataComentarios = JSON.parse(
											JSON.stringify(resp)
										).respuesta;
									} else {
										const modalRef = this.modalService.open(dlgMensajeError);
										modalRef.componentInstance.encabezado = JSON.parse(
											JSON.stringify(resp)
										).titulo;
										modalRef.componentInstance.contenido = JSON.parse(
											JSON.stringify(resp)
										).mensaje;
									}
									this.blockUI.stop();
								});
						} else {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = JSON.parse(
								JSON.stringify(respuesta)
							).titulo;
							modalRef.componentInstance.contenido = JSON.parse(
								JSON.stringify(respuesta)
							).mensaje;
						}
						this.blockUI.stop();
					});
			} else {
				//=======================================================================================
				// Cuando ya existe un Id de actividad
				//=======================================================================================
				const body = {
					id: -1,
					id_crm_actividad: this.formulario.get('id').value,
					comentario: this.tfComentarios.quillEditor.root.innerHTML,
				};
				this.http
					.post(`${environment.base_url}/rm/actividad/guardarComentario`, body)
					.subscribe((resp) => {
						if (JSON.parse(JSON.stringify(resp)).numero > 0) {
							// Cargamos los comentarios
							this.tfComentarios.quillEditor.root.innerHTML = '';
							this.dataComentarios = JSON.parse(JSON.stringify(resp)).respuesta;
						} else {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = JSON.parse(
								JSON.stringify(resp)
							).titulo;
							modalRef.componentInstance.contenido = JSON.parse(
								JSON.stringify(resp)
							).mensaje;
						}
						this.blockUI.stop();
					});
			}
		}
	}

	public guardarArchivo() {
		if (this.validaActividad()) {
			this.blockUI.start('Procesando...');
			if (this.archivos == undefined) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Arcarius ERP';
				modalRef.componentInstance.contenido = 'No has seleccionado un archivo';
				return;
			}
			if (this.formulario.get('id').value < 0) {
				this.http.post(`${environment.base_url}/rm/actividad/guardar`, this.formulario.value).subscribe((respuesta) => {
					this.formulario.controls['id'].setValue(
						JSON.parse(JSON.stringify(respuesta)).numero
					);
					if (this.formulario.get('id').value > 0) {
						//this.getActividadListado();
						//=======================================================================================
						// Guardamos el archivo
						//=======================================================================================
						let formulario = new FormData();
						formulario.append(
							'archivo',
							this.archivos.filesData[0].rawFile,
							this.archivos.filesData[0].name
						);
						formulario.append('Content-Type', 'application/json');
						formulario.append('Accept', `application/json`);
						this.http.post(`${environment.base_url}/rm/actividad/subirArchivo/${this.formulario.get('id').value}`, formulario).subscribe((resp) => {
							let respuesta = JSON.parse(JSON.stringify(resp));
							if (respuesta.numero > 0) {
								//==============================================================================
								// Cargamos los nuevos valores de los archivoExpediente procesados
								//==============================================================================
								this.servicio.cargaArchivos(this.formulario.get('id').value).subscribe((resp) => {
									this.dataArchivos = resp.respuesta;
								});
								//=======================================================================================
								// Recuperamos los comentarios
								//=======================================================================================
								this.servicio.getListadoComentarios(this.formulario.get('id').value).subscribe((resp) => {
									this.dataComentarios = resp.respuesta;
								});
								//==============================================================================
								// Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
								// Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
								//==============================================================================
								(
									document.getElementById('archivoCarga') as any
								).ej2_instances[0].clearAll();
								//==============================================================================
							}
						});
					} else {
						const modalRef = this.modalService.open(dlgMensajeError);
						modalRef.componentInstance.encabezado = JSON.parse(
							JSON.stringify(respuesta)
						).titulo;
						modalRef.componentInstance.contenido = JSON.parse(
							JSON.stringify(respuesta)
						).mensaje;
					}
				});
			} else {
				let formulario = new FormData();
				formulario.append(
					'archivo',
					this.archivos.filesData[0].rawFile,
					this.archivos.filesData[0].name
				);
				formulario.append('Content-Type', 'application/json');
				formulario.append('Accept', `application/json`);
				this.http
					.post(
						`${environment.base_url}/rm/actividad/subirArchivo/${this.formulario.get('id').value
						}`,
						formulario
					)
					.subscribe((resp) => {
						let respuesta = JSON.parse(JSON.stringify(resp));
						if (respuesta.numero > 0) {
							//==============================================================================
							// Cargamos los nuevos valores de los archivoExpediente procesados
							//==============================================================================
							this.servicio
								.cargaArchivos(this.formulario.get('id').value)
								.subscribe((resp) => {
									this.dataArchivos = resp.respuesta;
								});
							//=======================================================================================
							// Recuperamos los comentarios
							//=======================================================================================
							this.servicio
								.getListadoComentarios(this.formulario.get('id').value)
								.subscribe((resp) => {
									this.dataComentarios = resp.respuesta;
								});
							//==============================================================================
							// Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
							// Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
							//==============================================================================
							(
								document.getElementById('archivoCarga') as any
							).ej2_instances[0].clearAll();
							//==============================================================================
						}
					});
			}
			this.blockUI.stop();
		}
	}

	/**
	* Metodo para la descarga del archivo desde la API
	*
	* Se utiliza un elemento <a> para gestionar la url de descarga, así como el nombre del archivo
	*
	* @id Numero o identificador de la imagen
	* @nombre Nombre del archivo con el cual se descargara dicho archivo
	* @returns Ejecuta el metodo "click()" del elemento '<a>'
	*/
	public decarga(id: number, nombre: string) {
		this.blockUI.start('Procesando...');
		this.servicio.getDescarga(id).subscribe(resp => {
			const element = document.createElement('a');
			const blob = new Blob([resp], { type: 'application/octet-stream' });
			element.href = window.URL.createObjectURL(blob);
			element.download = nombre;
			element.click();
			this.blockUI.stop();
		});
	}

	/**
	* Metodo para buscar contacto
	*/
	public btnBuscarContacto() {
		const modalRef = this.modalService.open(DlgClienteContacto);
		modalRef.componentInstance.contacto.subscribe(resp => {
			this.formulario.controls['id_cliente_contacto'].setValue(resp.id);
			this.formulario.controls['nombre_contacto'].setValue(resp.nombres);
		});
	}

	/**
	 * Metodo para confirmar la eliminación del archivo de las actividades
	 * @param id
	 */
	public btnEliminarArchivo(id) {
		const modalRef = this.modalService.open(dlgMensajeConfirma);
		modalRef.componentInstance.encabezado = 'Arcarius ERP';
		modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
		modalRef.result.then((resultado) => {
			if (resultado == 'Si') {
				this.blockUI.start('Procesando...');
				//==============================================================================================
				//--> creamos la funcionalidad de eliminar
				//==============================================================================================
				this.servicio.eliminarArchivos(id).subscribe((resp) => {
					// recargamos el data
					this.servicio.cargaArchivos(this.formulario.get('id').value).subscribe((resp) => {
						this.dataArchivos = resp.respuesta;
					});
					//=======================================================================================
					// Recuperamos los comentarios
					//=======================================================================================
					this.servicio.getListadoComentarios(this.formulario.get('id').value).subscribe((resp) => {
						this.dataComentarios = resp.respuesta;
					});
				});
				this.blockUI.stop();
			}
		});
	}

	/**
	 * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
	 * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
	 * es utilizada para guardar el archivo con el metodo "guardarImagen()"
	 *
	 * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
	 * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
	 */
	public selected(args: UploaderComponent) {
		this.archivos = args;
		this.guardarArchivo();
	}

	/**
   * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
   * @returns
   */
	public browseClick() {
		document
			.getElementsByClassName('e-file-select-wrap')[0]
			.querySelector('button')
			.click();
		return false;
	}


	/**
	 * Metodo para el envio de correos
	 * @returns
	 */
	public enviarCorreo() {
		this.revisa();
		if (!this.formulario.invalid) {
			this.blockUI.start('Procesando...');
			this.http
				.get<any>(this.ruta, { params: this.formulario.value })
				.subscribe((resp) => {
					if (resp.numero < 0) {
						this.blockUI.stop();
						const modalRef =
							this.modalService.open(dlgMensajeError);
						modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
						modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
						modalRef.componentInstance.error = JSON.parse(JSON.stringify(resp)).error;
					} else {
						this.modalService.dismissAll();
						const modalRef = this.modalService.open(dlgMensaje);
						modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
						modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
						this.blockUI.stop();
						//this.estatus.emit(id);
					}
				});
		} else {
			this.blockUI.stop();
		}
	}

	/**
	 * Metodo para cerrar la ventana modal
	 */
	public close() {
		this.offcanvasService.dismiss();
	}
}
