import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { DomicilioService } from './services/domicilio.service';

@Component({
  selector: 'dlgClienteDomicilio',
  templateUrl: './dlgClienteDomicilio.component.html',
  styleUrls: ['./dlgClienteDomicilio.component.scss']
})
export class DlgClienteDomicilio {
  @ViewChild('referencia') referencia: ElementRef;
  @Output('cliente') cliente = new EventEmitter<any>();
  public data: any[];

  constructor(
    private servicio: DomicilioService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  /**
   * Metodo para obtener listado de cliente con base en la referencia proporcionada
   */
  public getClienteDomicilio() {
    this.servicio.getClienteDomicilio(this.referencia.nativeElement.value).subscribe(resp => {
      if (resp.numero > 0) {
        this.data = resp.respuesta;
      } else {
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
      }
    }
    );
  }

  /**
   * Metodo para devolver el cliente seleccionado
   * @param id
   */
  public getDomicilio(args: any) {
    this.activeModal.close('Close click')
    this.cliente.emit(
      {
        id: args.id,
        id_domicilio: args.id_domicilio,
        nombre: args.nombre,
        domicilio: args.domicilio
      }
    );
  }

  /**
   * Metodo para cerrar la ventana modal
   */
  public close() {
    this.modalService.dismissAll();
  }
}
