import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TableroService {

    /**
    * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
    * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
    */
    private ruta: string = `${environment.base_url}/tablero`;

    constructor(
        private http: HttpClient
    ) { }

    /**
       * Metodo para obtner datos detalle con respecto al grafico seleccionado
       * @param id Identificador del grafico seleccionado
       * @param param1 Parametro 1 seleccionado
       * @param param2 Parametro 2 seleccionado
       * @returns 
       */
    public getDetalle(id: number, param1: number, param2: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/getDetalle/${id}/${param1}/${param2}`);
    }

    public getTabla(id: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/getTabla/${id}`);
    }

}
