import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactoService } from '../../services/Contactos.service';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';

@Component({
    selector: 'app-plantillas',
    templateUrl: './plantillas.component.html',
    styleUrls: ['./plantillas.component.scss']
})
export class DlgPlantillas {

    @Input('id') id: number = -1;
    @Output('respuesta') respuesta = new EventEmitter<number>();
    public trabajando: boolean = true;
    public data: any;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private servicio: ContactoService
    ) { }

    ngOnInit(): void {
        this.servicio.getPlantillas().subscribe(resp => {
            if (resp.numero > 0) {
                this.data = resp.respuesta;
                this.trabajando = false;
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
                this.trabajando = true;
            }
        });
    }

    /**
     * Metodo para identificar el ID seleccionado del listado de plantillas de correo
     */
    public btnSeleccion(id: number) {
        this.close(id);
    }

    /**
     * Metodo para cerrar la ventana modal
     */
    public close(id: number) {
        this.respuesta.emit(id);
        this.activeModal.close();
    }
}
