import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IConversacionMessage } from '../interfaces/conversacion-message';


const apiUrl: string = 'https://api.openai.com/v1/completions';
const apiKey: string = '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';

@Injectable({
    providedIn: 'root'
})
export class ChatBotService {
    public messages: IConversacionMessage[] = [{
        text: "Hello Arcarius ERP",
        from: "user"
    },
    {
        text: "Hola soy Joss Bot, estoy en fase de pruebas. En poco tiempo tendrás nuevas noticias de mi.",
        from: "bot"
    }];

    constructor(private http: HttpClient) { }

    submitPrompt(userInput: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${apiKey}`);

        return this.http.post<any>(
            apiUrl,
            {
                model: 'gpt-3.5-turbo-0125',
                prompt: userInput,
                max_tokens: 150,
                temperature: 0.7,
                stop: ['\n']
            },
            { headers }
        );
    }
}
