<!--Dialogo par busqueda de empleados
    Esta ventana puede ser utilizada por el resto de los modulos para buscar y seleccionar
    a los empleados registrados
-->
<ejs-dialog id="dlgProducto" #dlgProducto header='Datos de Productos' maxHeight='500px' width='600px' isModal='true' [visible]='false' [buttons]='buttons' showCloseIcon='true'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i> Buscar Productos</div>
    </ng-template>
    <ng-template #content>
        <div class='form-group'>
            <div class='form-group'>
                <label>Clasificación:</label>
                <ejs-dropdownlist #clasificacion [fields]='fields' [dataSource]='data_clasificacion'></ejs-dropdownlist>
            </div>
        </div>
        <div class='form-group'>
            <label>Descripcion:</label>
            <div class="input-group">
                <button class="btn feedback-form-control" (click)="btnbuscar()">
                    <i class="fa fa-search"></i>
                </button>
                <input type="text" #referencia autofocus class="form-control feedback-input" aria-label="Small" aria-describedby="inputGroup-sizing-sm" (keydown.enter)="btnbuscar()">
                <div class="input-group-append">
                    <button class="btn btn-aceptar-cuadrado" (click)="btnbuscar()"></button>
                </div>
            </div>
        </div>
        <ejs-grid #tabla [dataSource]='data' (rowSelected)="onRowSelected($event)" allowResizing='true' height='400'>
            <e-columns>
                <e-column field='id' headerText='' width='0'></e-column>
                <e-column *ngFor="let col of columns" field='{{col.field}}' headerText='{{col.headerText}}' width='{{col.width}}' textAlign='{{col.textAlign}}' format='N2'>
                </e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>
