import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MdlSubtarea } from '../models/MdlSubtarea';
import { ErroresService } from '../../../../shared/errores.service';

@Injectable({
	providedIn: 'root'
})
export class ActividadService {

	constructor(
		private http: HttpClient,
		private errores: ErroresService
	) { }

	/**
	 * Metodo para obtener la data de la actividad
	 * @param id_crm_actividad Identificador de la actividad
	 */
	public getActividad(id_crm_actividad: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rm/actividad/carga/${id_crm_actividad}`);
	}

	/**
	 * Metodo para obtener el listado de las actibvidades
	 * @param referencia Palabra clave para buscar actividades
	 * @param historico Boolean para buscar en actividades cerradas
	 * @param kanban Boolean para poder activar la modalidad en Kanban
	 */
	public getActividadListado(referencia: string, historico: boolean, kanban: boolean): Observable<any> {
		const parametro: HttpParams = new HttpParams()
			.append('referencia', referencia)
			.append('historico', String(historico))
			.append('kanban', String(kanban));
		return this.http.get<any>(`${environment.base_url}/rm/actividad/listado`, { params: parametro });
	}

	/**
	 * Este metodo proporciona el listado de estatus, los cuales son de utilidad para
	 * crear las columnas del tablero kanban
	 * @returns Data en formato JSON para crear columnas Kanban
	 */
	public getEstatus(): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rm/actividad/listadoEstatus`);
	}

	/**
	 * Este metodo proporciona el listado de Subtareas que corresponden a una actividad
	 * @returns Data en formato JSON para listar las Subtareas
	 */
	public getListadoSubtareas(id: number): Observable<any> {
		const parametros: HttpParams = new HttpParams().append('id', String(id));
		return this.http.get<any>(`${environment.base_url}/rm/actividad/listadoSubtareas`, { params: parametros });
	}

	/**
   * Este metodo proporciona el listado de Comentarios que corresponden a una actividad
   * @returns Data en formato JSON para listar los Comentarios
   */
	public getListadoComentarios(id: number): Observable<any> {
		const parametros: HttpParams = new HttpParams().append('id', String(id));
		return this.http.get<any>(`${environment.base_url}/rm/actividad/listadoComentarios`, { params: parametros });
	}

	/**
	 * Metodo que cambiar el estatus de la actividad
	 * @param _id_actividad Numero o identificador de la actividad
	 * @param _estatus Descripcion del estatus, con el cual determinamos el ID de dicho estatus
	 * @returns 
	 */
	public setEstatusTexto(_id_actividad: number, _estatus: string): Observable<any> {
		const parametro: HttpParams = new HttpParams()
			.append('id', String(_id_actividad))
			.append('texto', String(_estatus));
		return this.http.post<any>(`${environment.base_url}/rm/actividad/guardarEstatusTexto`, parametro);
	}

	/**
	 * Metodo para asignar tarea a un determinado usuario
	 * @param id_tarea numero identificador de la tarea
	 * @param id_rh_empleado numero identificador del usuario
	 * @returns 
	 */
	public setTareaAsignar(id_tarea: number, id_rh_empleado: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/rm/actividad/setTareaAsignar/${id_tarea}/${id_rh_empleado}`, {});
	}

	/**
	 * Metodo para eliminar al usuario de alguna tarea
	 * @param id Numero identifdicador del usuario asignado a dicha tarea
	 * @returns 
	 */
	public delTareaAsignar(id: number): Observable<any> {
		return this.http.delete<any>(`${environment.base_url}/rm/actividad/delTareaAsignar/${id}`);
	}

	/**
	 * Metodo para guardarla informacion de la subtarea en la DB
	 * @param subtarea Objeto que contiene la subtarea a Guardar
	 * @returns Observable con la respuesta de la Api
	 */
	public subtareaGuardar(subtarea: MdlSubtarea): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/rm/actividad/subtareaGuardar`, subtarea);
	}

	/**
	 * Metodo para eliminar la subtarea de la DB
	 * @param subtarea Objeto que contiene la subtarea a Eliminar
	 * @returns Observable con la respuesta de la Api
	 */
	public subtareaEliminar(subtarea: MdlSubtarea): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/rm/actividad/subtareaEliminar`, subtarea);
	}

	/**
	 * Metodo para eliminar la actividad de la DB
	 * @param subtarea Objeto que contiene la subtarea a Eliminar
	 * @returns Observable con la respuesta de la Api
	 */
	public actividadEliminar(id: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/rm/actividad/actividadEliminar/${id}`, {});
	}

	/**
	 * Carga listado de Archivos o documentos
	 * @param id Numero identificador de la actividad
	 * @returns Observable para obtener la data
	 */
	public cargaArchivos(id: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rm/actividad/lstArchivos/${id}`);
	}

	/**
	 * Descargamos el archivo seleccionado
	 * @param id Numero identificador de la actividad
	 * @returns 
	 */
	public getDescarga(id: number): Observable<any> {
		let resonseType: any = 'blob';
		let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
		return this.http.get<any>(`${environment.base_url}/rm/actividad/descarga/${id}`, { responseType: resonseType, headers: headers });
	}

	/**
	 * Elimnadmos el archivo con base en el identificador
	 * @param id Numero de identificacion del archivo
	 * @returns Observable para su ejecución
	 */
	public eliminarArchivos(id: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/rm/actividad/borrar_archivo/${id}`, {});
	}

	/**
	 * Metodo para subir archivos
	 * @param formulario
	 * @returns
	 */
	public setArchivos(id_crm_actividad: number, formulario: any): Observable<any> {
		return this.http.post(`${environment.base_url}/rm/actividad/subirArchivo/${id_crm_actividad}`, formulario);
	}

}
