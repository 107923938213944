import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { ContactoService } from './services/contacto.service';

@Component({
	selector: 'dlgClienteContacto',
	templateUrl: './dlgClienteContacto.component.html',
	styleUrls: ['./dlgClienteContacto.component.scss']
})
export class DlgClienteContacto implements AfterViewInit {
	
	@ViewChild('referencia') referencia: ElementRef;
	@Output('contacto') contacto = new EventEmitter<any>();
	public data: any[];

	constructor(
		private servicio: ContactoService,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal
	) { }
	

	/**
	 * Metodo a ejecutar despues de la carga de componentes web
	 * Para este caso poner el foco dentro del componente input de referencia
	 * listo para recibir captura de datos y busca al empleado
	 */
	ngAfterViewInit(): void {
		this.referencia.nativeElement.focus();
	}

	/**
	 * Metodo para obtener listado de cliente con base en la referencia proporcionada
	 */
	public getClienteContacto() {
		this.servicio.getClienteContacto(this.referencia.nativeElement.value, false).subscribe(resp => {
			if (resp.numero > 0) {
				this.data = resp.respuesta;
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
			}
		}
		);
	}

	/**
	 * Metodo para devolver el cliente seleccionado
	 * @param id
	 */
	public getContacto(args: any) {
		console.log(args);
		this.contacto.emit(
			{
				id: args.id,
				id_cliente: args.id_cliente,
				id_cliente_domicilio: args.id_cliente_domicilio,
				nombres: args.nombres

			}
		);
		this.activeModal.close('Close click')
	}

	/**
	 * Metodo para cerrar la ventana modal
	 */
	public close() {
		this.modalService.dismissAll();
	}
}
