import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClienteService {
    
    /**
    * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
    * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
    */
    private ruta: string = `${environment.base_url}/venta/cliente`;

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Metodo para obtener los archivos de un determinado documento
     * @param id 
     * @returns 
     */
    public getArchivos(id_cliente: number) {
        return this.http.get<any>(`${this.ruta}/getArchivos/${id_cliente}`);
    }

    /**
     * Metodo para subir archivo al expediente de cliente
     * @param id_cliente Identificador del cliente
     * @param formulario Formulario que contienen el archivo
     * @returns 
     */
    public setArchivo(id_cliente: number, formulario: any): Observable<any>{
        return this.http.post(`${this.ruta}/setArchivo/${id_cliente}`, formulario);
    }

    /**
     * Metodo para gestionar la descarga del archivo seleccionado
     * @param id Identificador del archivo
     * @returns 
     */
    public getArchivo(id: number): Observable<any> {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.get<any>(`${this.ruta}/getArchivo/${id}`, { responseType: resonseType, headers: headers });
    }

    /**
     * Elimnadmos el archivo con base en el identificador
     * @param id Numero de identificacion del archivo
     * @returns Observable para su ejecución
     */
    public delArchivo(id: number): Observable<any> {
        return this.http.post<any>(`${this.ruta}/delArchivo/${id}`, {});
    }
}
