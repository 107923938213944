export class MdlDocumento {
  public id: number = -1;
  public id_doc_tipo: number = -1;
  public id_cliente: number = -1;
  public id_cliente_contacto: number = -1;
  public id_cliente_domicilio: number = -1;
  public id_rh_empleado: number = -1;
  public id_doc_cobro: number = -1;
  public id_doc_venta_metodopago: number = -1;
  public id_sat_usocfdi: number = -1;
  public id_fin_moneda: number = -1;
  public dias: number = 0;

  public fecha: Date = new Date();
  public tc_general: number = 1.0;

  public cliente: string = '';
  public contacto: string = '';
  public domicilio: string = '';
  public observaciones: string = '';
  public referencia: string = '';
  public ordencompra: string = '';
  public estatus: string = '';
  public folio: string = '';
  public uuid: string = '';
  public telefono: string = '';
  public tipo: string = '';
  public modoedicion: boolean = true;
}
