import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from '../../../shared/errores.service';
import { MdlRegistroClientes } from '../clientes/models/MdlRegistroClientes';
import { MdlRegistroSucursal } from '../clientes/models/MdlRegistroSucursal';
import { MdlRegistroCorreo } from '../clientes/models/MdlRegistroCorreo';

@Injectable({
  providedIn: 'root',
})
export class RegistroClienteService {
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private errores: ErroresService
  ) { }
  /**
   * Metodo para el DropDownList en el que se mostrarán los estatus que se pueden
   * asignar al cliente
   *
   * @return Información en formato JSON
   **/
  public Registra_Estatus(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/venta/cliente/estatus`);
  }

  /**
   * Metodo para el DropDownList en el que se mostrarán la clasificación que se pueden
   * asignar al cliente
   *
   * @return Información en formato JSON
   **/
  public Registra_Clasificacion(): Observable<any> {
    return this.http.get<any>(
      `${environment.base_url}/venta/cliente/clasificacion`
    );
  }

  /**
   * Metodo para el DropDownList en el que se mostrarán la data de origen que se pueden
   * asignar al cliente
   *
   * @return Información en formato JSON
   **/
  public Registra_Origen(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/venta/cliente/origen`);
  }
    /**
   * Metodo para el DropDownList en el que se mostrarán la data de regimen que se pueden
   * asignar al cliente
   *
   * @return Información en formato JSON
   **/
     public Registra_Regimen(): Observable<any> {
      return this.http.get<any>(`${environment.base_url}/venta/cliente/regimen`);
    }

  /**
   * Metodo para obtener listado de los clientes potenciales
   *@param referencia Nombre del cliente para relizar busqueda
   * @return Información en formato JSON
   **/
  public buscarClientePotencial(referencia: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('referencia', referencia);
    return this.http.get<any>(`${environment.base_url}/venta/cliente/ClientePotencial`, {
      params: parametros,
    });
  }
    /**
     * Metodo para el referencia de rfc
     *
     * @param referencia RFC DEL CLIENTE.
     * @return Listado de regimenes fiscales en formato JSON.
     */
   public buscarRegimenFiscal(referencia: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('referencia', referencia);
    return this.http.get<any>(`${environment.base_url}/venta/cliente/regimenFiscalReferencia`, {
      params: parametros,
    });
  }

  /**
   * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
   * Se almacena el proyecto
   *
   * @param modelo Objeto dentro del cual se tiene toda la información del proyecto
   */
  public guardarCliente(modelo: MdlRegistroClientes) {
    return this.http
      .post(`${environment.base_url}/venta/cliente/guardarCliente`, modelo)
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
  * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
  * 
  * @param id Numero o identificador
  * @returns Observable para recuperar la data
  */
  public getClienteIndividual(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(`${environment.base_url}/venta/cliente/cargaCliente/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
   *
   * @param id Numero o identificador
   * @returns Observable para recuperar la data
   */
  public getCliente(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/clasificacion/cargaClasificacion/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para el listado de los domicilios de los clientes
   * @param id ID del cliente al cual se le van a mostrar los domicilios registrados.
   * @return Información en formato JSON
   **/
  public getSucursalCliente(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/cargaSucursalCliente/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para el listado de los correos de los clientes
   * @param id ID del domicilio al cual se le van a mostrar los correos relacionados.
   * @return Información en formato JSON
   **/
  public getSucursalClienteCorreo(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/cargaSucursalCorreo/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para el listado de las colonias disponibles
   * @return Información en formato JSON
   **/
     public getCP(cp: String): Observable<any> {
      const parametro: HttpParams = new HttpParams();
      return this.http
        .get(
          `${environment.base_url}/venta/cliente/list_CP/${cp}`
        )
        .pipe(
          catchError((error) => {
            return throwError(this.errores.getErrores(error));
          })
        );
    }

  /**
   * Metodo para el listado de los paises disponibles
   * @return Información en formato JSON
   **/
  public getPaises(): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/list_pais`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
   * Metodo para el listado de los estados disponibles.
   * @param id ID del pais que se esta seleccionando.
   * @return Información en formato JSON
   **/
  public getEstado(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/list_estado/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
   * Metodo para el listado de las localidades disponibles.
   * @param id ID del estado que se esta seleccionando.
   * @return Información en formato JSON
   **/
  public getLocalidad(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/list_localidad/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
   * Metodo para el listado de los municipios disponibles.
   * @param id ID del estado que se esta seleccionando.
   * @return Información en formato JSON
   **/
  public getMunicipio(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/list_municipio/${id}`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
   * Metodo para el listado de los empleados que estan en el departamento de ventas.
   * @return Información en formato JSON
   **/
  public getEjecutivo(): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(
        `${environment.base_url}/venta/cliente/list_ejecutivo`
      )
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
  * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
  * Se almacena el domicilio del cliente
  *
  * @param modelo Objeto dentro del cual se tiene toda la información del proyecto
  */
  public guardarSucursal(modelo: MdlRegistroSucursal) {
    return this.http
      .post(`${environment.base_url}/venta/cliente/guardarSucursal`, modelo)
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
  * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
  * Se almacena el correo del domicilio
  *
  * @param modelo Objeto dentro del cual se tiene toda la información del proyecto
  */
  public guardarCorreo(modelo: MdlRegistroCorreo) {
    return this.http
      .post(`${environment.base_url}/venta/cliente/guardarCorreo`, modelo)
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }


  /**
  * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
  * 
  * @param id Numero o identificador
  * @returns Observable para recuperar la data
  */
  public getSucursalIndividual(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(`${environment.base_url}/venta/cliente/cargaSucursalSola/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  /**
  * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
  * 
  * @param id Numero o identificador
  * @returns Observable para recuperar la data
  */
  public getCorreoIndividual(id: number): Observable<any> {
    const parametro: HttpParams = new HttpParams();
    return this.http
      .get(`${environment.base_url}/venta/cliente/cargaCorreoSolo/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }










}
