export class MdlRegistroSucursal{
	public id: number = -1;
	public id_cliente: number = -1;
	public calle: String = "";
	public colonia: String = "";
	public num_ext: String = "";
	public num_int: String = "";
	public id_municipio: number = -1;
	public id_estado: number = -1;
	public cp: String = "";
	public nombre: String = "";
	public id_rh_empleado: number = -1;
	public pais: String = "";
	public correo: String = "";
	public localidad: String = "";
	public id_localidad: number = -1;
	public id_colonia: number = -1;
}