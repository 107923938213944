export class MdlRegistroClientes{
	public id: number = -1;
	public codigo: String = "";
	public nombre: String = "";
	public paterno: String = "";
	public materno: String = "";
	public correo: String = "";
	public tel_movil: String = "";
	public tel_oficina: String = "";
	public tel_fax: String = "";
	public estatus: number = -1;
	public id_cliente_clasifica: number = -1;
	public fecha_ingreso: Date = new Date();
	public rfc: String = "";
	public id_con_catalogo: number = -1;
	public id_con_catalogo_anticipo: number = -1;
	public id_cliente_origen: number = -1;
	public referencia_bancaria: String = "";
	public twitter: String = "";
	public whatsapp: String = "";
	public id_fiscal: String = "";
	public id_cliente_potencial: number = -1;
	public id_cliente: number = -1;
	public id_sat_regimenfiscal: number = -1;
}