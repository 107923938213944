import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { ClienteService } from '../../services/cliente.service';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';

@Component({
    selector: 'app-dlgArchivo',
    templateUrl: './dlgArchivo.component.html',
    styleUrls: ['./dlgArchivo.component.scss']
})
export class DlgArchivoComponent {

    @Input() id_cliente: number;
    @Input() public TITULO: string = 'titulo';
    public data: any[];
    private archivos: UploaderComponent;
    public archivoArea: HTMLElement;

    @Input() carga() {
        this.servicio.getArchivos(this.id_cliente).subscribe(
            resp => {
                if (resp.numero > 0) {
                    this.data = resp.respuesta;
                } else {
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
                }
            }
        );
    };

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private servicio: ClienteService
    ) { }

    ngAfterContentChecked(): void {
        this.archivoArea = document.getElementById('droparea');
    }

    /**
     * Metodo para descargar el archivo seleccionado
     * @param id Identificador del registro del archivo
     * @param nombre Nombre que se le dara al archivo cuando se descargue
     */
    public btnDescarga(id: number, nombre: string) {
        this.servicio.getArchivo(id).subscribe(resp => {
            const element = document.createElement('a');
            const blob = new Blob([resp], { type: 'application/octet-stream' });
            element.href = window.URL.createObjectURL(blob);
            element.download = nombre;
            element.click();
        });
    }

    /**
     * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
     * @returns 
     */
    public browseClick() {
        document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
    }

    /**
     * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
     * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
     * es utilizada para guardar el archivo con el metodo "guardarImagen()"
     * 
     * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
     * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
     */
    public selected(args: UploaderComponent) {
        this.archivos = args;
        this.guardarArchivo();
    }

    /**
     * Metodo para almacenar los archivos
     * @returns 
     */
    public guardarArchivo() {
        if (this.archivos == undefined) {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = this.TITULO;
            modalRef.componentInstance.contenido = 'No has seleccionado un archivo';
            return;
        }
        let formulario = new FormData();
        formulario.append('archivo', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
        formulario.append('Content-Type', 'application/json');
        formulario.append('Accept', `application/json`);
        console.log('que_enviamos');
        this.servicio.setArchivo(this.id_cliente, formulario).subscribe(resp => {
            if (resp.numero > 0) {
                //==============================================================================
                // Cargamos los nuevos valores de los archivoExpediente procesados
                //==============================================================================
                this.carga();
                //==============================================================================
                // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
                // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
                //==============================================================================
                (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                //==============================================================================
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }
        });
    }

    /**
     * Metodo para eliminar el archivo selecionado
     */
    public btnBorrar(id: number) {
        const modalRef = this.modalService.open(dlgMensajeConfirma);
        modalRef.componentInstance.encabezado = this.TITULO;
        modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
        modalRef.result.then((resultado) => {
            if (resultado == 'Si') {
                this.servicio.delArchivo(id).subscribe(resp => {
                    if (resp.numero > 0) {
                        this.carga();
                    } else {
                        const modalRef = this.modalService.open(dlgMensajeError);
                        modalRef.componentInstance.encabezado = resp.titulo;
                        modalRef.componentInstance.contenido = resp.mensaje;
                    }
                });
            }
        }).catch(err => {
            // Es utilizado para posible error y evitar mensaje en consola
        });
    }
}
