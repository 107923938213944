<div class="modal-header">
    <h5 class="modal-title"> Buscar Clientes</h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="col-md-12">
        <div class='form-group'>
            <label>{{"Documento.Referencia" | translate}}</label>
            <div class="input-group">
                <input type="text" #referencia class="form-control" (keydown.enter)="getClienteDomicilio()">
                <div class="input-group-append">
                    <button class="btn btn-buscar-cuadrado" (click)="getClienteDomicilio()"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table *ngIf="this.data" class="table table-hover table-bordered">
            <thead>
                <th></th>
                <th>Nombre</th>
                <th>Domicilio</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.data" (click)="getDomicilio(item)">
                    <td>
                        <button class="btn btn-aceptar" (click)="getDomicilio(item)"></button>
                    </td>
                    <td>
                        {{item.nombre}}
                    </td>
                    <td>
                        {{item.domicilio}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-cerrar" aria-label="Close" (click)="close()">
        Cerrar
    </button>
</div>
