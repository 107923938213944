import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MdlDocVentaExtra } from '../models/MdlDocVentaExtra';
import { MdlDocumento } from '../models/MdlDocumento';

@Injectable({
    providedIn: 'root',
})
export class docVentaService {

    constructor(
        private http: HttpClient,
        public datepipe: DatePipe
    ) { }

    /**
     * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
     * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
     */
    private ruta: string = `${environment.base_url}/venta/documentosventa`;


    /**
     * Metodo para el listado de las formas de pago que estan disponibles para el uso fiscal.
     * @return Información en formato JSON
     **/
    public formapago(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/formapago`, { params: parametros });
    }

    /**
     * Metodo para el listado de los metodos de pago que estan disponibles para el uso fiscal.
     * @return Información en formato JSON
     **/
    public metodopago(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/metodopago`, { params: parametros });
    }
    /**
     * Metodo para el listado del uso del CFDI que se pueden asiganar al documento para uso.
     * @return Información en formato JSON
     **/
    public usoCFDI(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/usoCFDI`, { params: parametros });
    }
    /**
     * Metodo para el listado de los empleados que estan en el departamento de ventas.
     * @return Información en formato JSON
     **/
    public getEmpleado(_id_rh_empleado: any): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('_id_rh_empleado', _id_rh_empleado);
        return this.http.get<any>(`${this.ruta}/list_vendedor`, { params: parametros });
    }

    /**
    * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador
    * @returns Observable para recuperar la data
    */
    public cargaDetalleSolo(id: number): Observable<any> {
        const parametro: HttpParams = new HttpParams();
        return this.http.get(`${this.ruta}/cargaDetalleSolo/${id}`);
    }

    /**
     * Metodo para el listado de los tipos de documento que se pueden generar.
     *  @return Información en formato JSON
     **/
    public getDoc_tipo(): Observable<any> {
        const parametro: HttpParams = new HttpParams();
        return this.http.get(`${this.ruta}/lstdoc_tipo`);
    }

    /**
     * Metodo para el listado de los tipo de moneda que estarán disponibles para
     * asignar el documento de venta.
     * @return Información en formato JSON
     **/
    public getMoneda(_id_cliente: any): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('_id_cliente', _id_cliente);
        return this.http.get<any>(`${this.ruta}/lstfin_moneda`, { params: parametros });
    }

    public guardarDocumento(documento: MdlDocumento): Observable<any> {
        return this.http.post<any>(`${this.ruta}/guardarDocumento`, documento);
    }

    /**
     * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
     *
     * @param id Numero o identificador del documento de venta.
     * @returns Observable para recuperar la data
     */
    public getProductoDoc_venta(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/lstProducto/${id}`);
    }

    /**
    * Metodo para obtener la data del documento encabezado de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador del documento de venta.
    * @returns Observable para recuperar la data del encabezado
    */
    public cargaEncabezado(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaEncabezado/${id}`);
    }

    public bus_producto_cod(codigo: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('codigo', codigo);
        return this.http.get<any>(`${this.ruta}/bus_producto_cod`, { params: parametros });
    }
    /**
    * Metodo para obtener la data del detalle encabezado de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador del documento de venta.
    * @returns Observable para recuperar la data del encabezado
    */
    public cargaDetalle(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaDetalle/${id}`);
    }

    public guardarProducto(
        id_doc_detalle: any,
        _id_doc_venta_encabezado: any,
        _id_inv_producto: any,
        _cantidad: any,
        _descuento: any,
        _precio: any,
        _descripcion: string
    ): Observable<any> {
        const parametros: HttpParams = new HttpParams()
            .append('id_doc_detalle', id_doc_detalle)
            .append('_id_doc_venta_encabezado', _id_doc_venta_encabezado)
            .append('_id_inv_producto', _id_inv_producto)
            .append('_cantidad', _cantidad)
            .append('_descuento', _descuento)
            .append('_precio', _precio)
            .append('_descripcion', _descripcion);
        return this.http.get<any>(`${this.ruta}/guardarProducto`, { params: parametros, });
    }

    /**
    * METODO PARA EL TIMBRADO DEL DOCUMENTO DE VENTA.
    * @param id : Este ID es el del documento encabezado.
    */
    public getTimbrado(id: any): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', id);
        return this.http.post(`${this.ruta}/getTimbrado`, parametros);
    }


    /////////////////////////////////////////////////////////
    // --> INFORMACIÓN EXTRA
    /////////////////////////////////////////////////////////
    // data_tipoOperacion
    // data_clavePedimento
    // data_certificadoOrigen
    // data_incoterm
    // data_subdivision
    // data_motivoTraslado
    /**
     * Metodo para el listado de los tipos de operación.
     * @return Información en formato JSON
     **/
    public tipoOperacion(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/tipoOperacion`, { params: parametros });
    }
    /**
     * Metodo para el listado de las claves de Pedimento.
     * @return Información en formato JSON
     **/
    public clavePedimento(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/clavePedimento`, { params: parametros });
    }
    /**
     * Metodo para el listado de los certificados de origen.
     * @return Información en formato JSON
     **/
    public certificadoOrigen(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/certificadoOrigen`, { params: parametros });
    }
    /**
     * Metodo para el listado de las subdivisiones.
     * @return Información en formato JSON
     **/
    public subdivisión(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/subdivisión`, { params: parametros });
    }
    /**
     * Metodo para el listado del tipo de traslado.
     * @return Información en formato JSON
     **/
    public motivoTraslado(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/motivoTraslado`, { params: parametros });
    }
    /**
     * Metodo para el listado de incoterm.
     * @return Información en formato JSON
     **/
    public incoterm(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/incoterm`, { params: parametros });
    }
    /**
   * Metodo para el listado del Tipo de Relación.
   * @return Información en formato JSON
   **/
    public tiporelacionextra(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/tiporelacionextra`, { params: parametros });
    }
    /**
     * Metodo para el listado de Prioridad.
     * @return Información en formato JSON
     **/
    public prioridadExtra(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/prioridadExtra`, { params: parametros });
    }
    /**
     * Metodo para el listado de Prioridad.
     * @return Información en formato JSON
     **/
    public tipoOperacionINE(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/tipoOperacionINE`, { params: parametros });
    }
    /**
     * Metodo para el listado de Prioridad.
     * @return Información en formato JSON
     **/
    public comiteINE(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/comiteINE`, { params: parametros });
    }
    /**
     * Metodo para el listado de Prioridad.
     * @return Información en formato JSON
     **/
    public ambitoINE(): Observable<any> {
        const parametros: HttpParams = new HttpParams();
        return this.http.get<any>(`${this.ruta}/ambitoINE`, { params: parametros });
    }

    /**
    * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON.
    *
    * @param id Numero o identificador
    * @returns Observable para recuperar la data.
    */
    public cargaDocVentaExtra(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaDocVentaExtra/${id}`);
    }
    /**
    * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
    * Se almacena la información extra.
    *
    * @param modelo Objeto dentro del cual se tiene toda la información del doc_extra.
    */
    public GuardarDocVentaExtra(modelo: MdlDocVentaExtra,
        observaciones: any,
        puertoexporta: any,
        puertodescarga: any,
        viaembarque: any,
        transportista: any,
        termimoventa: any,
        terminopago: any,
        observacion_a: any,
        observacion_b: any,
        deducible: any,
        coaseguro: any,
        otros: any,
        numerico1: any,
        numerico2: any,
        numerico3: any,
        numerico4: any
    ): Observable<any> {
        const parametros: HttpParams = new HttpParams()
            .append('observaciones', observaciones)
            .append('puertoexporta', puertoexporta)
            .append('puertodescarga', puertodescarga)
            .append('viaembarque', viaembarque)
            .append('transportista', transportista)
            .append('termimoventa', termimoventa)
            .append('terminopago', terminopago)
            .append('observacion_a', observacion_a)
            .append('observacion_b', observacion_b)
            .append('deducible', deducible)
            .append('coaseguro', coaseguro)
            .append('otros', otros)
            .append('numerico1', numerico1)
            .append('numerico2', numerico2)
            .append('numerico3', numerico3)
            .append('numerico4', numerico4);
        return this.http.post(`${this.ruta}/guardarDocVentaExtra`, modelo, { params: parametros });
    }

    public GuardarDocVentaExtraComplemento(
        _id: any,
        _id_doc_encabezado: any,
        _fiscal_disposicion: any,
        _fiscal_norma: any,
        _fiscal_leyenda: any
    ): Observable<any> {
        const parametros: HttpParams = new HttpParams()
            .append('_id', _id)
            .append('_id_doc_encabezado', _id_doc_encabezado)
            .append('_fiscal_disposicion', _fiscal_disposicion)
            .append('_fiscal_norma', _fiscal_norma)
            .append('_fiscal_leyenda', _fiscal_leyenda);
        return this.http.get<any>(`${this.ruta}/GuardarDocVentaExtraComplemento`, { params: parametros });
    }

    /**
    * Metodo para el listado de complementos Fiscales.
    * @return Información en formato JSON
    **/
    public cargaComplementosFiscales(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaComplementosFiscales/${id}`);
    }
    /**
    * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador
    * @returns Observable para recuperar la data.
    */
    public cargaComplementoEditar(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaComplementoEditar/${id}`);
    }
    /**
    * Metodo para obtener la data del historial del documento de venta de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador del documento de venta.
    * @returns Observable para recuperar la data del encabezado
    */
    public cargaHistorial(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaHistorial/${id}`);
    }
    /**
    * Metodo para obtener la data extra del documento de venta extra de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador del documento de venta.
    * @returns Observable para recuperar la data del encabezado extra
    */
    public cargaDocVentaExtraInfo(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaDocVentaExtraInfo/${id}`);
    }

    /**
    * Metodo para la data del Documento Relacionado al documento.
    * @param id ID del documento encabezado
    * @return Información en formato JSON
    **/
    public getDocumentoRelacionado(id: number): Observable<any> {
        return this.http.get(`${this.ruta}/cargaDocumentoRelacionado/${id}`);
    }


    /**
     * Metodo para enviar asignacion de estatus al documento
     * @param id_doc_venta_encabezado
     * @param id_doc_estatus
     * @returns
     */
    public setEstatus(id_doc_venta_encabezado: number, id_doc_estatus: number): Observable<any> {
        const parametros: HttpParams = new HttpParams()
            .append('id_doc_venta_encabezado', id_doc_venta_encabezado)
            .append('id_doc_estatus', id_doc_estatus);
        return this.http.get<any>(`${this.ruta}/setEstatus`, { params: parametros });
    }

    /**
     * Metodo para enviar asignacion de estatus al documento
     * @param id_doc_venta_encabezado
     * @param id_doc_estatus
     * @returns
     */
    public setConvertir(id: number, id_doc_tipo: number): Observable<any> {
        return this.http.post<any>(`${this.ruta}/setConvertir/${id}/${id_doc_tipo}`, {});
    }

    /**
   * Metodo para generar el reporte con base en ID del documento
   * @param id Numero o identificador del documento a imprimir
   * @returns
   */
    public setImprimir(id: number): Observable<any> {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.post<any>(`${environment.base_url}/reporte/reporteVenta/${id}`, {}, { responseType: resonseType, headers: headers });
    }

    /**
  * Metodo para obtener informacion default para envio de correo
  * @param id Numero o identificador del documento a enviar
  * @returns
  */
    public getCorreo(id: number): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', id);
        return this.http.get<any>(`${this.ruta}/getCorreo`, { params: parametros });
    }
}

