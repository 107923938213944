export class MdlDocVentaExtra {
  public id: number = null;
  public id_doc_venta_encabezado: number = null;
  public texto01: string = null;
  public numero01: number = null;
  public fecha_entrega: Date =  new Date();
  public fecha_devolucion: Date =  new Date();
  public id_doc_venta_metodopago: number = null;
  public id_sat_usocfdi: number = null;
  public anticipo: Boolean = false;
  public id_sat_tipo_operacion: number = null;
  public id_sat_tipo_comite: number = null;
  public id_sat_tipo_ambito: number = null;
  public ine_contabilidad: string = null;
  public id_sat_tipo_relacion: number = null;
  public id_doc_venta_relacion: number = null;
  public id_sat_aduana_tipo_operacion: number = null;
  public id_sat_aduana_pedimento: number = null;
  public id_sat_aduana_certificado: number = null;
  public id_sat_aduana_motivotraslado: number = null;
  public num_certificado_origen: string = null;
  public num_exportador_confiable: string = null;
  public id_sat_terminos_internacionales: number = null;
  public id_sat_aduana_subdivision: number = null;
  public id_crm_prioridad: number = null;
  public informacion1: string = null;
  public observaciones: String = "";
  public puertoexporta: String = "";
  public puertodescarga: String = "";
  public viaembarque: String = "";
  public transportista: String = "";
  public termimoventa: String = "";
  public terminopago: String = "";
  public observacion_a: String = "";
  public observacion_b: String = "";
  public deducible: Number = 0;
  public coaseguro: Number = 0;
  public otros: Number = 0;
  public numerico1: Number = 0;
  public numerico2: Number = 0;
  public numerico3: Number = 0;
  public numerico4: Number = 0;
}


