import { Component, Input } from '@angular/core';
import { RegistroslogsService } from './services/registroslogs.service';

@Component({
    selector: 'pnTimeLine',
    templateUrl: './pnTimeLine.component.html',
    styleUrls: ['./pnTimeLine.component.scss']
})
export class PnTimeLineComponent {

    @Input('id') id: number = -1;
    public data: any;
    public anticipado: string;

    constructor(
        public servicio: RegistroslogsService
    ) {

    }

    ngOnInit(): void {
        if (this.id > 0) {
            this.servicio.getDatos('cliente_contacto', this.id).subscribe(resp => {
                if (resp.numero > 0) {
                    this.data = JSON.parse(JSON.stringify(resp.respuesta));
                    this.anticipado = this.data[0].anticipado;
                }
            });
        }
    }

}
