<div style="overflow: auto; padding-top: 20px;">
    <div class="Timeline">

        <div *ngFor="let item of this.data" [ngClass]="item.posicion%2 == 0 ? 'event2' : 'event1'">
            <svg height="5" width="150">
                <line x1="0" y1="0" x2="150" y2="0" style="stroke:#004165;stroke-width:5" />
            </svg>
            <div [ngClass]="item.posicion%2 == 0 ? 'event2Bubble' : 'event1Bubble'">
                <div class="eventTime">
                    <div class="DayDigit">{{item.dia}}</div>
                    <div class="Day">
                        {{item.dia_semana}}
                        <div class="MonthYear">{{item.mes}} {{item.anio}}</div>
                    </div>
                </div>
                <div class="eventTitle">{{item.observaciones}}</div>
            </div>
            <div [ngClass]="item.posicion%2 == 0 ? 'event2Author' : 'eventAuthor'">{{item.nombre}}</div>
            <svg height="20" width="20">
                <circle cx="10" cy="11" r="5" fill="#004165" />
            </svg>
            <div [ngClass]="item.posicion%2 == 0 ? 'time2' : 'time1'">{{item.hora}}</div>
        </div>

        <!--
    <div class="now">
        NOW
    </div>
    -->


        <div class="event3 futureGray ">
            <svg height="5" width="150">
                <line x1="0" y1="0" x2="150" y2="0" style="stroke:rgba(162, 164, 163, 0.37);stroke-width:5" />
                Sorry, your browser does not support inline SVG.
            </svg>
            <div class="event1Bubble futureOpacity">
                <div class="eventTime" *ngIf="anticipado">
                    <div class="DayDigit">{{anticipado.split("-")[0]}}</div>
                    <div class="Day">
                        {{anticipado.split("-")[1]}}
                        <div class="MonthYear">{{anticipado.split("-")[2]}}</div>
                    </div>
                </div>
                <div class="eventTitle">Anticipado</div>
            </div>
            <svg height="20" width="20">
                <circle cx="10" cy="11" r="5" fill="rgba(162, 164, 163, 0.37)" />
            </svg>
            <svg height="5" width="50">
                <line x1="0" y1="0" x2="50" y2="0" style="stroke:#004165;stroke-width:5" />
            </svg>
            <svg height="20" width="42">
                <line x1="1" y1="0" x2="1" y2="20" style="stroke:#004165;stroke-width:2" />
            </svg>
        </div>

    </div>
</div>