import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { ArchivosService } from '../services/archivos.service';

@Component({
	selector: 'dlgVentaArchivos',
	templateUrl: './archivos.component.html',
	styleUrls: ['./archivos.component.scss']
})
export class dlgVentaArchivos {
	public breadcrumbs1: string = '';
	public breadcrumbs2: string = '';



	@BlockUI() blockUI: NgBlockUI;
	@Input() encabezado;
	@Input() contenido;
	@Input() modoEdicion: boolean = false;

	@Input() id: number;
	public data: any[];

	private archivos: UploaderComponent;
	public archivoArea: HTMLElement;

	@Input() carga() {
		this.servicio.getArchivos(this.id).subscribe(
			resp => {
				if (resp.numero > 0) {
					this.data = resp.respuesta;
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
			}
		);
	};

	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private servicio: ArchivosService,
		private http: HttpClient,
		private route: ActivatedRoute
	) { }

	ngAfterContentChecked(): void {
		this.archivoArea = document.getElementById('droparea');
	}

	public btnDescarga(id: number, nombre: string) {
		// const modalRef = this.modalService.open(dlgMensajeError);
		// modalRef.componentInstance.encabezado = 'Problemas con Archivo';
		// modalRef.componentInstance.contenido = 'El archivo que intenta descargar no tiene un formato';

		this.servicio.getDescarga(id).subscribe(resp => {
			const element = document.createElement('a');
			const blob = new Blob([resp], { type: 'application/octet-stream' });
			element.href = window.URL.createObjectURL(blob);
			element.download = nombre;
			element.click();
		});
	}

	/**
	 * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
	 * @returns 
	 */
	public browseClick() {
		document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
	}

	/**
	 * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
	 * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
	 * es utilizada para guardar el archivo con el metodo "guardarImagen()"
	 * 
	 * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
	 * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
	 */
	public selected(args: UploaderComponent) {
		this.archivos = args;
		this.guardarArchivo();
	}

	/**
	 * Metodo para almacenar los archivos
	 * @returns 
	 */
	public guardarArchivo() {
		if (this.id < 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Archivos';
			modalRef.componentInstance.contenido = 'Documento no creado';
			return;
		}
		if (this.archivos == undefined) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Archivos';
			modalRef.componentInstance.contenido = 'No tenemos archivo';
			return;
		}
		this.blockUI.start('Procesando...');
		let formulario = new FormData();
		formulario.append('archivo', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
		formulario.append('Content-Type', 'application/json');
		formulario.append('Accept', `application/json`);
		this.http.post(`${environment.base_url}/venta/documentosventa/subirArchivo/${this.id}`, formulario).subscribe(resp => {
			let respuesta = JSON.parse(JSON.stringify(resp));
			if (respuesta.numero > 0) {
				//==============================================================================
				// Cargamos los nuevos valores de los archivoExpediente procesados
				//==============================================================================
				this.carga();
				//==============================================================================
				// Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
				// Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
				//==============================================================================
				(document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
				//==============================================================================
			}
		});
		this.blockUI.stop();
	}

	/**
	 * Metodo para confirmar la eliminación del archivo
	 * @param id 
	 */
	public MsjEliminarArchivo(id) {
		const modalRef = this.modalService.open(dlgMensajeConfirma);
		modalRef.componentInstance.encabezado = 'Documento de Ventas';
		modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
		modalRef.result.then((resultado) => {
			if (resultado == 'Si') {
				this.blockUI.start('Procesando...');
				//==============================================================================================
				//--> creamos la funcionalidad de eliminar
				//==============================================================================================
				this.servicio.borrarArchivo(id).subscribe((resp) => {
					// recargamos el data
					this.carga();
				});
				this.blockUI.stop();
			}
		});
	};

}
