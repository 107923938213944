<div style="text-align: center;">
    <h4>Imagen Soporte </h4>

    <div class="dropzone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <p>Arrastra y suelta una imagen aquí o
            <a href="#" (click)="fileInput.click(); $event.preventDefault();">
                busca el archivo
            </a>
        </p>
    </div>

    <!-- Input de archivo oculto -->
    <input #fileInput type="file" (change)="selectFile($event)" accept="image/*" style="display: none;">
    <!-- Pegar desde el portapapeles -->
    <p>Pega una imagen aquí (Ctrl+V)</p>

    <!-- Capturar imagen con cámara -->
    <button class="btn btn-normal" *ngIf="allowWebcam" (click)="toggleWebcam()">Activa Webcam</button>
    <div *ngIf="isWebcamActive">
        <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
        <button class="btn btn-normal" (click)="triggerSnapshot()">Capturar</button>
    </div>

    <!-- Mostrar la imagen seleccionada -->
    <div *ngIf="selectedImage">
        <img [src]="selectedImage" alt="Imagen seleccionada" style="max-width: 300px;">
    </div>
</div>