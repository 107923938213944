import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';

@Component({
	selector: 'dlgDocumentos',
	templateUrl: './dlgDocumentos.component.html',
	styleUrls: ['./dlgDocumentos.component.scss']
})
export class dlgDocumentos {
	@Input() id: number;
	@Output('documento') documento = new EventEmitter<number>();
	public data: any[];

	@Input() carga() {
		this.getListado();
	};

	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private http: HttpClient
	) { }

	/**
	 * Metodo para obtener listado de documentos a los cuales se podra convertir un documento
	 */
	public getListado() {
		this.http.get<any>(`${environment.base_url}/sistema/controlfolios/getDocumentosConvertir/${this.id}`, {}).subscribe(
			resp => {
				if (resp.numero > 0) {
					this.data = resp.respuesta;
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
			}
		);
	}

	/**
	 * Metodo para asignar el ID del documento seleccionado y devolverlo a componente que lo solicito
	 * @param id
	 */
	public setDocumento(id: number) {
		console.log('Documento seleccionado: ', id);
		this.modalService.dismissAll();
		this.documento.emit(id);
	}

	/**
	 * Metodo para cerrar la ventana modal
	 */
	public close() {
		this.modalService.dismissAll();
	}
}
