import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactoService {

    private ruta: string = `${environment.base_url}/rm/contacto`;

    constructor(
        private http: HttpClient
    ) { }

    public getClienteContacto(referencia: string, privado: boolean): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia).append('privado', privado);
        return this.http.get<any>(`${this.ruta}/get_contacto`, { params: parametros });
    }

    public getContacto(referencia: string, privado: boolean): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia).append('privado', privado);
        return this.http.get<any>(`${this.ruta}/get_contacto`, { params: parametros });
    }
}
