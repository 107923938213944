import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { MdlLog } from '../models/MdlLog.model';

@Injectable({
    providedIn: 'root',
})
export class ContactoService {
    /**
     * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
     * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
     */
    private ruta: string = `${environment.base_url}/rm/contacto`;

    constructor(private http: HttpClient, private errores: ErroresService) { }

    /**
     * Obtenemos el listado de contactos
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_contactos(referencia: string, clasificacion: number, grupo: number, etiqueta: number, privado: boolean): Observable<any> {
        let parametros = new HttpParams()
            .append('referencia', referencia)
            .append('clasificacion', clasificacion)
            .append('grupo', grupo)
            .append('etiqueta', etiqueta)
            .append('privado', privado);
        return this.http.get<any>(`${this.ruta}/get_contactos`, { params: parametros });
    }

    /**
     * Metodo para obtener listado de clasificaciones de contactos
     * @param referencia 
     * @returns 
     */
    public getClasificaciones(referencia: string): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/get_contacto_clasificaciones`, { params: parametros });
    }

    /**
     * Metodo para obtener listado de grupos de contactos
     * @param referencia 
     * @returns 
     */
    public getGrupos(referencia: string): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/get_contacto_grupos`, { params: parametros });
    }

    /**
     * Metodo para obtener listado de etiquetas de contactos
     * @param referencia 
     * @returns 
     */
    public getEtiquetas(referencia: string): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/get_contacto_etiquetas`, { params: parametros });
    }

    /**
     * Metodo para la obtencion de la data de un contacto a nivel individual.
     * Esto será utilizado para cargar el formulario y posiblmenete editar la informacion
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_contacto(id_cliente_contacto: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/get_contacto/${id_cliente_contacto}`, {});
    }

    /**
     * Obtenemos el listado de comentarios del contacto seleccionado
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public getComentarios(id: number): Observable<any> {
        let parametros = new HttpParams().append('id', String(id));
        return this.http.get<any>(`${this.ruta}/getComentarios`, { params: parametros });
    }

    /**
     * Obtenemos el listado de plantillas de correo
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public getPlantillas(): Observable<any> {
        return this.http.get<any>(`${this.ruta}/getPlantillas`, {});
    }

    /**
     * Metodo para almacenar los comentarios del contacto
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public setComentarios(modelo: any): Observable<any> {
        return this.http.post(`${this.ruta}/setComentario`, modelo);
    }

    /**
     * Metodo para almacenar comportamiento o cambios en los contactos
     * @param modelo Objeto el cual contiene estructura e informacion para ser almacenada en la DB
     * es importante que la estructura del objeto (modelo) sea identica dentro de Angular y de la API
     * @returns
     */
    public setLog(modelo: MdlLog): Observable<any> {
        return this.http.post(`${this.ruta}/setLog`, modelo);
    }

    /**
     * Obtenemos la datos de parametros de la API del listado de paises
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_paises(): Observable<any> {
        return this.http.get<any>(`${this.ruta}/get_paises`, {}).pipe(
            catchError((error) => {
                return throwError(this.errores.getErrores(error));
            })
        );
    }

    /**
     * Obtenemos la datos de parametros de la API el listado de estados con el id del pais seleccionado
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_estados(id_pais: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/get_estados/${id_pais}`, {});
    }

    /**
     * Obtenemos la datos de parametros de la API el listado de municipios con el id del estado seleccionado
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_municipios(id_estado: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/get_municipios/${id_estado}`, {});
    }

    /**
     * Obtenemos la datos de parametros de la API el listado de clasificacion
     *
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_parametros(): Observable<any> {
        return this.http.get<any>(`${this.ruta}/get_parametros`, {});
    }

    /**
     * Metodo para obtener el cuerpo de la plantilla en formato web para envio
     * correo electronico
     * @returns 
     */
    public getCorreoPlantilla(id: number): Observable<any> {
        return this.http.get<any>(`${this.ruta}/getCorreoPlantilla/${id}`, {});
    }

    /**
     * Metodo para mandar guardar el contacto  a la API para almacenarla en la DB
     * @param modelo Objeto el cual contiene estructura e informacion para ser almacenada en la DB
     * es importante que la estructura del objeto (modelo) sea identica dentro de Angular y de la API
     * @returns
     */
    public guardar(modelo: any): Observable<any> {
        return this.http.post(`${this.ruta}/guardar`, modelo);
    }

    /**
     * Metodo para eliminar el registro del contacto dentro de la DB
     * @param id Numero o identificador del registro que será eliminado
     * Las reglas de eliminación se procesaran dentro de la DB con sus propias reglas como son constraint
     * así como posibles trigger
     * @returns
     */
    public eliminar(id: number) {
        return this.http.post(`${this.ruta}/eliminar/${id}`, {});
    }

    /**
     * Metodo para guardar grupo
     * @param formulario objeto que contiene la informacion para registrar el grupo
     * @returns
     */
    public setGrupo(formulario: any): Observable<any> {
        return this.http.post(`${environment.base_url}/rm/actividad/guardarGrupo`, formulario);
    }

    /**
     * Metodo para guardar etiqueta
     * @param formulario objeto que contiene la informacion para registrar la etiqueta
     * @returns
     */
    public setEtiqueta(formulario: any): Observable<any> {
        return this.http.post(`${environment.base_url}/rm/actividad/guardarEtiqueta`, formulario);
    }

    /**
     * Metodo para asignar la etiqueta al contacto
     * @param id
     * @param id_crm_etiqueta
     * @returns
     */
    public asignaEtiqueta(id: number, id_crm_etiqueta: number): Observable<any> {
        return this.http.post(`${this.ruta}/asignarEtiqueta/${id}/${id_crm_etiqueta}`, {});
    }

    /**
     * Metodo para asignar el grupo  al contacto
     * @param id
     * @param id_crm_actividad_grupo
     * @returns
     */
    public asignaGrupo(id: number, id_crm_actividad_grupo: number): Observable<any> {
        return this.http.post(`${this.ruta}/asignarGrupo/${id}/${id_crm_actividad_grupo}`, {});
    }
}
