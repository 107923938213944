<div class="clsPanelBoton" *ngIf="!this.trabajando">
    <button class="btn btn-nuevo" (click)="btnNuevoDocumento()"> {{"Boton.Nuevo" | translate}}</button>
    <button class="btn btn-guardar" (click)="btnGuardar()" [disabled]="!this.modoedicionGeneral">
        {{"Boton.Guardar" | translate}}</button>
    <button class="btn btn-estatus" (click)="btnEstatus()"> {{"Boton.Estatus" | translate}}</button>
    <button class="btn btn-correo" (click)="btnCorreo()"> {{"Boton.Correo" | translate}}</button>
    <!-- hay que cambiar el 1 por el ID necesario -->
    <button class="btn btn-opcion" (click)="btnOpciones($event, 1)">
        <i class="fa-solid fa-ellipsis-vertical"></i>
    </button>

    <button class="btn-derecha btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="clsPanelContenedor">
    <div class="row">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="formulario">
                    <div class="row">
                        <div class="col-md-2">
                            <label>{{"Documento.Tipo" | translate}}</label>
                            <select class="form-select" required formControlName="id_doc_tipo"
                                [disabled]="this.modoedicionGeneral" (change)="onchange($event)">
                                <option *ngFor="let item of data_doc_tipo" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                            <small *ngIf="doc_tipoNoValida" class="text-danger">Se requiere un tipo de documento</small>
                        </div>

                        <div class="col-md-2">
                            <div class='form-group'>
                                <label>{{"Documento.Fecha" | translate}}</label>
                                <ejs-datepicker required formControlName="fecha" [format]='formatoFecha'>
                                </ejs-datepicker>
                                <small *ngIf="fechaNoValida" class="text-danger">Se requiere fecha</small>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class='form-group'>
                                <label>{{"Documento.OrdenCompra" | translate}}</label>
                                <input type="text" class="form-control" formControlName="ordencompra">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class='form-group'>
                                <label>{{"Documento.DiasCredito" | translate}}</label>
                                <input type="number" class="form-control" required formControlName="dias" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class='form-group'>
                                <label>{{"Documento.Estatus" | translate}}</label>
                                <input type="text" class="form-control" formControlName="estatus" [readOnly]="true">
                            </div>
                        </div>
                    </div>
                    <!-- FILA 2 (Folio, Dias, Referencia) -->
                    <div class="row">
                        <div class="col-md-2">
                            <label for="tfFolio" class="form-label">{{"Documento.Folio" | translate}}</label>
                            <input type="text" class="form-control" id="tfFolio" formControlName="folio"
                                [readOnly]="true">
                        </div>

                        <div class="col-md-2">
                            <div class='form-group'>
                                <label>{{"Documento.Referencia" | translate}}</label>
                                <input type="text" class="form-control" formControlName="referencia">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>{{"Documento.Vendedor" | translate}}</label>
                            <select class="form-select" formControlName="id_rh_empleado">
                                <option *ngFor="let item of data_vendedor" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- FILA 3 (Cliente, UUID)-->
                    <div class="row">
                        <div class="col-md-4" *ngIf="this.contacto_bandera">
                            <label class="form-label">
                                {{"General.Contacto" | translate}}
                                <i class="fa-solid fa-arrow-up-right-from-square" (click)="pnContacto()"></i>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="contacto" [readOnly]="true">
                                <div class="input-group-append">
                                    <button class="btn btn-buscar-cuadrado" (click)="selectOption()"
                                        [disabled]="!this.modoedicionGeneral"></button>
                                </div>
                                <input type="hidden" class="form-control" formControlName="id_cliente_contacto">
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="!this.contacto_bandera">
                            <label class="form-label">
                                {{"General.Cliente" | translate}}
                                <i class="fa-solid fa-arrow-up-right-from-square" (click)="pnCliente()"></i>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="cliente" [readOnly]="true">
                                <div class="input-group-append">
                                    <button class="btn btn-buscar-cuadrado" (click)="selectOption()"
                                        [disabled]="!this.modoedicionGeneral"></button>
                                </div>
                                <input type="hidden" class="form-control" formControlName="id_cliente">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>{{"General.Sucursal" | translate}}</label>
                            <input type="text" class="form-control" formControlName="domicilio" [readOnly]="true">
                        </div>
                        <div class="col-md-2">
                            <label>{{"Documento.Moneda" | translate}}</label>
                            <select class="form-select" formControlName="id_fin_moneda">
                                <option value="-1">Seleccionar</option>
                                <option *ngFor="let item of data_fin_moneda" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                            <small *ngIf="tipo_cambioNoValida" class="text-danger">Se requiere un tipo de cambio</small>
                        </div>
                        <div class="col-md-2">
                            <label for="tfReferencia" class="form-label">{{"Documento.TipoCambio" | translate}}</label>
                            <input type="text" class="form-control" formControlName="tc_general">
                        </div>
                    </div>
                    <div class="row" *ngIf="timbra">
                        <div class="col-md-3">
                            <label>Forma de pago:</label>
                            <select class="form-select" formControlName="id_doc_cobro">
                                <option *ngFor="let item of data_formapago" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <label>Metodo de pago:</label>
                            <select class="form-select" formControlName="id_doc_venta_metodopago">
                                <option *ngFor="let item of data_metodopago" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                            <small *ngIf="metodoPagoNoValida" class="text-danger">Se requiere un metodo de pago</small>
                        </div>
                        <div class="col-md-3">
                            <label>Uso del CFDI:</label>
                            <select class="form-select" formControlName="id_sat_usocfdi">
                                <option *ngFor="let item of data_usoCFDI" value="{{item.id}}">{{item.descripcion}}
                                </option>
                            </select>
                            <small *ngIf="usoCFDINoValida" class="text-danger">Se requiere un uso</small>
                        </div>
                        <div class="col-md-3">
                            <div class='form-group'>
                                <label>UUID:</label>
                                <input type="text" class="form-control" formControlName="uuid" [readOnly]="true">
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <!-- INICIO TABLA DE PRODUCTOS O SERVICIOS -->
            <div class="row" style="margin-top: 5px">
                <div class="col-md-12" *ngIf="this.modoedicionGeneral">
                    <button id="btnAgregarProducto" class="btn btn-nuevo" (click)="btnNuevoProducto()">
                        {{"Boton.Agregar" |
                        translate}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ejs-grid #GridDetalles [dataSource]='data_detalle'>
                        <e-columns>
                            <e-column field='id' headerText='ID' width='0'>
                            </e-column>
                            <e-column headerText='' width='65' minWidth='65' *ngIf="this.modoedicionGeneral">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-editar-sm" [disabled]="buttonDisabled" title="Modificar"
                                            (click)="btnCargaProducto(item.id, item.descripcion)"></button>
                                        <button class="btn btn-borrar-sm" [disabled]="buttonDisabled"
                                            title="Eliminar Producto"
                                            (click)="btnBorrarProductoDocVenta(item.id)"></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='cantidad' [headerText]='"Producto.Cantidad" | translate' width='60'
                                minWidth='60'>
                            </e-column>
                            <e-column field='codigo' [headerText]='"Producto.Codigo" | translate' width='120'
                                minWidth='120' [allowEditing]='false'>
                            </e-column>
                            <e-column field='codigo_sat' [headerText]='"Producto.Codigo" | translate' width='150'
                                minWidth='150' [allowEditing]='false'>
                            </e-column>
                            <e-column field='descripcion' [headerText]='"Producto.Descripcion" | translate'
                                minWidth='200'>
                            </e-column>
                            <e-column field='precio' [headerText]='"Producto.Precio" | translate' width='80'
                                minWidth='80' textAlign="right" format='N2'>
                            </e-column>
                            <e-column field='descuento' [headerText]='"Producto.Descuento" | translate' width='80'
                                minWidth='80' textAlign="right" format='N2'>
                            </e-column>
                            <e-column field='importe' [headerText]='"Producto.Importe" | translate' width='80'
                                minWidth='90' textAlign="right" format='N2' [allowEditing]='false'>
                            </e-column>
                        </e-columns>
                    </ejs-grid>

                </div>
            </div>

            <!-- FIN TABLA DE PRODUCTOS O SERVICIOS -->

            <div class="row">
                <div class="col-md-9">
                </div>
                <div class="col-md-3 justify-content-end">
                    <table class="table table-bordered float-right">
                        <tr>
                            <td style="text-align: left; width: 100px;">{{"General.SubTotal" | translate}}</td>
                            <td style="text-align: right; width: 100px;">{{this.totales.subtotal | number: '.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td>{{"General.Impuesto" | translate}}</td>
                            <td style="text-align: right;">{{this.totales.impuesto | number: '.2-2'}}</td>
                        </tr>
                        <tr>
                            <td>{{"General.Total" | translate}}</td>
                            <td style="text-align: right;">{{this.totales.total | number: '.2-2'}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<popupmenu>
    <div class="title">Menu Ventas</div>
    <div class="items">
        <ul>
            <li (click)="mnVariable()"><i class="fa-regular fa-calendar-days"></i> {{"General.Variable" | translate}}
            </li>
            <li (click)="mnConvertir()" class="li-convertir"> {{"Boton.Convertir" | translate}}
            </li>
            <li (click)="btnArchivos()"><i class="fa-solid fa-file-import"></i> {{"Boton.Archivos" | translate}}</li>
            <li (click)="btnInfoExtra()"><i class="fas fa-cubes"></i> {{"Boton.Extra" | translate}}</li>
            <li (click)="btnImprimir()" class="li-imprimir">{{"Boton.Imprimir" | translate}} </li>
            <li (click)="btnMensaje()" class="li-mensaje">{{"Boton.Mensaje" | translate}} </li>
            <li (click)="Timbrado()"><i class="fa-solid fa-certificate"></i> {{"Boton.Timbrar" | translate}} </li>
        </ul>

    </div>
</popupmenu>

<!-- INFORMACIÓN EXTRA DEL DOCUMENTO -->
<ejs-dialog id="dlgInformaciónExtra" #dlgInformaciónExtra header='Información Extra' height="90%" width='60%'
    isModal='true' [visible]='false' showCloseIcon='true'>
    <block-ui>
        <form autocomplete="off" [formGroup]="frm_doc_venta_extra">
            <ng-template #content width='50%'>
                <ejs-tab id="tab_default" overflowMode='Popup' width='100%'>
                    <e-tabitems>
                        <e-tabitem [header]='tbExpediente[0]' class="nav-link active">
                            <ng-template #content>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Fecha Entrega:</label>
                                            <ejs-datepicker [format]='formatoFecha' formControlName="fecha_entrega">
                                            </ejs-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Fecha Devolución:</label>
                                            <ejs-datepicker [format]='formatoFecha' formControlName="fecha_devolucion">
                                            </ejs-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Prioridad:</label>
                                            <ejs-dropdownlist [dataSource]="data_prioridadExtra" [fields]='fields'
                                                formControlName="id_crm_prioridad">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class='form-group'>
                                            <div class="card" style="height: 150px; overflow: auto;">
                                                <div class="card-title mb-1 p-2">
                                                    <h6>Información Adicional</h6>
                                                </div>
                                                <!-- <ejs-richtexteditor [toolbarSettings]='tools'>
                                                            <ng-template #valueTemplate>
                                                                <p></p>
                                                            </ng-template>
                                                        </ejs-richtexteditor> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <div class="card" style="height: 200px; overflow: auto;">
                                                <div class="card-title mb-1 p-2">
                                                    <h6>Observaciones</h6>
                                                </div>
                                                <!-- <ejs-richtexteditor [toolbarSettings]='tools' height="200px" formControlName="observaciones">
                                                        </ejs-richtexteditor> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <div class="card" style="height:200px;">
                                                <div class="card-title mb-1 p-2">
                                                    <h6>Información para Aseguradoras</h6>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="Deducible"
                                                        class="col-sm-3 col-form-label">Deducible:</label>
                                                    <div class="col-sm-9">
                                                        <ejs-numerictextbox [validateDecimalOnType]='false'
                                                            [showSpinButton]='false' decimals='0' value='0' format='n0'
                                                            formControlName="deducible">
                                                        </ejs-numerictextbox>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="Coaseguro"
                                                        class="col-sm-3 col-form-label">Coaseguro:</label>
                                                    <div class="col-sm-9">
                                                        <ejs-numerictextbox [validateDecimalOnType]='false'
                                                            [showSpinButton]='false' decimals='0' value='0' format='n0'
                                                            formControlName="coaseguro">
                                                        </ejs-numerictextbox>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="Otros" class="col-sm-3 col-form-label">Otros:</label>
                                                    <div class="col-sm-9">
                                                        <ejs-numerictextbox [validateDecimalOnType]='false'
                                                            [showSpinButton]='false' decimals='0' value='0' format='n0'
                                                            formControlName="otros">
                                                        </ejs-numerictextbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class='form-group'>
                                            <label>Numerico 1:</label>
                                            <ejs-numerictextbox [validateDecimalOnType]='false' [showSpinButton]='false'
                                                decimals='0' value='0' format='n0' formControlName="numerico1">
                                            </ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class='form-group'>
                                            <label>Numerico 2:</label>
                                            <ejs-numerictextbox [validateDecimalOnType]='false' [showSpinButton]='false'
                                                decimals='0' value='0' format='n0' formControlName="numerico2">
                                            </ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class='form-group'>
                                            <label>Numerico 3:</label>
                                            <ejs-numerictextbox [validateDecimalOnType]='false' [showSpinButton]='false'
                                                decimals='0' value='0' format='n0' formControlName="numerico3">
                                            </ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class='form-group'>
                                            <label>Numerico 4:</label>
                                            <ejs-numerictextbox [validateDecimalOnType]='false' [showSpinButton]='false'
                                                decimals='0' value='0' format='n0' formControlName="numerico4">
                                            </ejs-numerictextbox>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[1]'>
                            <ng-template #content>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Tipo de Operación:</label>
                                            <ejs-dropdownlist [dataSource]="data_tipoOperacion" [fields]='fields'
                                                formControlName="id_sat_aduana_tipo_operacion">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Subdivisión:</label>
                                            <ejs-dropdownlist [dataSource]="data_subdivision" [fields]='fields'
                                                formControlName="id_sat_aduana_subdivision">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Clave de Pedimento:</label>
                                            <ejs-dropdownlist [dataSource]="data_clavePedimento" [fields]='fields'
                                                formControlName="id_sat_aduana_pedimento">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Motivo Traslado:</label>
                                            <ejs-dropdownlist [dataSource]="data_motivoTraslado" [fields]='fields'
                                                formControlName="id_sat_aduana_motivotraslado">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class='form-group'>
                                            <label>Certificado Origen:</label>
                                            <ejs-dropdownlist [dataSource]="data_certificadoOrigen" [fields]='fields'
                                                formControlName="id_sat_aduana_certificado">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class='form-group'>
                                            <label>Incoterm:</label>
                                            <ejs-dropdownlist [dataSource]="data_incoterm" [fields]='fields'
                                                formControlName="id_sat_terminos_internacionales">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Num. Certificado Origen:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="num_certificado_origen">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Num. Exportador Confiable:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="num_exportador_confiable">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Puerto de Exportación:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="puertoexporta">

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Via de Embarque:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="viaembarque">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Transportista:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="transportista">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Terminos de Venta:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="termimoventa">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Puerto de Descarga:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="puertodescarga">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Terminos de Pago:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="terminopago">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Observaciones A:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="observacion_a">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class='form-group'>
                                            <label>Observaciones B:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="observacion_b">
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[2]'>
                            <ng-template #content>
                                <div class="row">
                                    <div class="card" style="height:200px; width:100%;">
                                        <div class="card-title mb-1 p-2">
                                            <h6>Documentos Relacionados</h6>
                                        </div>
                                        <div class="col-md-12">
                                            <div class='form-group'>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text ">Tipo de
                                                            Relación:</span>
                                                    </div>
                                                    <button class="btn btn-buscar "></button>
                                                    <input type="text" class="form-control" readonly='true'
                                                        hidden="true" formControlName="id_doc_venta_relacion">
                                                    <ejs-dropdownlist [dataSource]="data_tiporelacion" [fields]='fields'
                                                        formControlName="id_sat_tipo_relacion">
                                                    </ejs-dropdownlist>
                                                    <button id="btnAgregarRelación" class="btn btn-nuevo"
                                                        (click)="btn_guardarFiscal"> {{"Boton.Agregar" |
                                                        translate}}</button>

                                                </div>
                                            </div>
                                            <ejs-grid [dataSource]='data_documento_relacionado' allowSorting='true'
                                                allowPaging='true' [rowHeight]=5>
                                                <e-columns>
                                                    <e-column field='id' headerText='#' width='0'></e-column>
                                                    <!-- <e-column field='' headerText='' width='60' minWidth='60'>
                                                        <ng-template #template let-item>
                                                            <div style="display: inline-flex;">
                                                                <button class="btn btn-borrar-sm " (click)="MsjEliminarArchivo(item.id)" title="Eliminar Archivo"></button>
                                                                <button class="btn btn-descarga-sm " (click)="decarga(item.id, item.descripcion)" title="Descargar Archivo"></button>
                                                            </div>
                                                        </ng-template>
                                                    </e-column> -->
                                                    <e-column field='fecha' width='100' minWidth='100'
                                                        headerText='Fecha' type='date' [format]="formatoFecha">
                                                    </e-column>
                                                    <e-column field='folio' width='120' minWidth='120'
                                                        headerText='Folio'></e-column>
                                                    <e-column field='uuid' width='120' minWidth='120' headerText='Uuid'>
                                                    </e-column>
                                                    <e-column field='descripcion' width='150' minWidth='150'
                                                        headerText='Descripción'></e-column>
                                                    <e-column field='codigo' width='100' minWidth='100'
                                                        headerText='Codigo'></e-column>
                                                    <e-column field='total' width='100' minWidth='100'
                                                        headerText='Total' textAlign="right" format='N2'>
                                                    </e-column>
                                                </e-columns>
                                            </ejs-grid>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card" style="height:200px; width:100%;">
                                        <div class="card-title mb-1 p-2">
                                            <h6>Información de Pago del Documento a traves de Cobros</h6>
                                        </div>
                                        <div class="col-md-12">
                                            <ejs-grid allowSorting='true' allowPaging='true' [rowHeight]=5>
                                                <e-columns>
                                                    <e-column field='id' headerText='#' width='0'></e-column>
                                                    <!-- <e-column field='' headerText='' width='60' minWidth='60'>
                                                        <ng-template #template let-item>
                                                            <div style="display: inline-flex;">
                                                                <button class="btn btn-borrar-sm " (click)="MsjEliminarArchivo(item.id)" title="Eliminar Archivo"></button>
                                                                <button class="btn btn-descarga-sm " (click)="decarga(item.id, item.descripcion)" title="Descargar Archivo"></button>
                                                            </div>
                                                        </ng-template>
                                                    </e-column> -->
                                                    <e-column field='fecha' width='100' minWidth='100'
                                                        headerText='Fecha' type='date' [format]="formatoFecha">
                                                    </e-column>
                                                    <e-column field='folio' width='100' minWidth='100'
                                                        headerText='Folio'></e-column>
                                                    <e-column field='uuid' width='120' minWidth='120' headerText='Uuid'>
                                                    </e-column>
                                                    <e-column field='descripcion' width='120' minWidth='120'
                                                        headerText='Descripción'></e-column>
                                                    <e-column field='codigo' width='100' minWidth='100'
                                                        headerText='Codigo'></e-column>
                                                    <e-column field='total' width='100' minWidth='100'
                                                        headerText='Total' textAlign="right" format='N2'>
                                                    </e-column>
                                                </e-columns>
                                            </ejs-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[3]'>
                            <ng-template #content>
                                <div class="row">
                                    <div class="card" style="height:450px; width:100%;">
                                        <div class="card-title mb-1 p-2">
                                            <h6>Historial</h6>
                                        </div>
                                        <div class="col-md-12">
                                            <ejs-grid allowSorting='true' [dataSource]='data_historial'
                                                allowPaging='true' [rowHeight]=25>
                                                <e-columns>
                                                    <e-column field='id' headerText='#' width='0'></e-column>
                                                    <!-- <e-column field='' headerText='' width='60' minWidth='60'>
                                                        <ng-template #template let-item>
                                                            <div style="display: inline-flex;">
                                                                <button class="btn btn-borrar-sm " (click)="MsjEliminarArchivo(item.id)" title="Eliminar Archivo"></button>
                                                                <button class="btn btn-descarga-sm " (click)="decarga(item.id, item.descripcion)" title="Descargar Archivo"></button>
                                                            </div>
                                                        </ng-template>
                                                    </e-column> -->
                                                    <e-column field='fecha' width='120' minWidth='120'
                                                        headerText='Fecha' type='date' [format]="formatoFecha">
                                                    </e-column>
                                                    <e-column field='descripcion' width='150' minWidth='150'
                                                        headerText='Descripción'></e-column>
                                                    <e-column field='empleado' width='150' minWidth='150'
                                                        headerText='Empleado'></e-column>
                                                </e-columns>
                                            </ejs-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[4]'>
                            <ng-template #content>
                                <div class="row">
                                    <div class="card" style="height:450px; width:100%;">
                                        <div class="card-title mb-1 p-2">
                                            <h6>Programación</h6>
                                        </div>
                                        <div class="col-md-12">
                                            <ejs-grid allowSorting='true' allowPaging='true' [rowHeight]=25>
                                                <e-columns>
                                                    <e-column field='id' headerText='#' width='0'></e-column>
                                                    <!-- <e-column field='' headerText='' width='60' minWidth='60'>
                                                        <ng-template #template let-item>
                                                            <div style="display: inline-flex;">
                                                                <button class="btn btn-borrar-sm " (click)="MsjEliminarArchivo(item.id)" title="Eliminar Archivo"></button>
                                                                <button class="btn btn-descarga-sm " (click)="decarga(item.id, item.descripcion)" title="Descargar Archivo"></button>
                                                            </div>
                                                        </ng-template>
                                                    </e-column> -->
                                                    <e-column field='descripcion' width='180' minWidth='180'
                                                        headerText='Descripción'></e-column>
                                                    <e-column field='fecha' width='120' minWidth='120'
                                                        headerText='Fecha' type='date' [format]="formatoFecha">
                                                    </e-column>
                                                    <e-column field='descripcion' width='180' minWidth='180'
                                                        headerText='Descripción'></e-column>
                                                </e-columns>
                                            </ejs-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[5]'>
                            <ng-template #content>
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class='form-group'>
                                            <label>Tipo de Operación:</label>
                                            <ejs-dropdownlist [dataSource]="data_tipoOperacionINE" [fields]='fields'
                                                formControlName="id_sat_tipo_operacion">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class='form-group'>
                                            <label>Tipo de Comite:</label>
                                            <ejs-dropdownlist [dataSource]="data_comiteINE" [fields]='fields'
                                                formControlName="id_sat_tipo_comite">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class='form-group'>
                                            <label>Tipo de Ambito:</label>
                                            <ejs-dropdownlist [dataSource]="data_ambitoINE" [fields]='fields'
                                                formControlName="id_sat_tipo_ambito">
                                            </ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class='form-group'>
                                            <label>Contrato o ID Contabilidad:</label>
                                            <input type="text" class="form-control " placeholder=''
                                                formControlName="ine_contabilidad">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[6]'>
                            <ng-template #content>

                            </ng-template>
                        </e-tabitem>
                        <e-tabitem [header]='tbExpediente[7]'>
                            <ng-template #content>
                                <form autocomplete="off" [formGroup]="frm_doc_extra_complemento">
                                    <div class="row">
                                        <div class="card" style="height:450px; width:100%;">
                                            <div class="card-title mb-1 p-2">
                                                <h6>Complemento de Leyendas Fiscales</h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class='form-group'>
                                                    <label>Disposición Fiscal:</label>
                                                    <input type="text" class="form-control " placeholder=''
                                                        formControlName="fiscal_disposicion">
                                                </div>
                                                <div class='form-group'>
                                                    <label>Norma:</label>
                                                    <input type="text" class="form-control " placeholder=''
                                                        formControlName="fiscal_norma">
                                                </div>
                                                <div class='form-group'>
                                                    <label>Texto Leyenda:</label>
                                                    <textarea placeholder=''
                                                        style='width:100%; height:100px;resize: none;'
                                                        formControlName="fiscal_leyenda"></textarea>
                                                </div>
                                                <div class='form-group'>
                                                    <button class="btn btn-guardar" (click)="btn_guardarComplementos()"
                                                        [disabled]="!this.modoedicionGeneral"> {{"Boton.Guardar" |
                                                        translate}}</button>
                                                </div>
                                                <ejs-grid [dataSource]='tabla_complementosFiscales' allowSorting='true'
                                                    allowPaging='true' [rowHeight]=5>
                                                    <e-columns>
                                                        <e-column field='id' headerText='#' width='0'>
                                                        </e-column>
                                                        <e-column field='' headerText='' width='60' minWidth='60'>
                                                            <ng-template #template let-item>
                                                                <div style="display: inline-flex;">
                                                                    <button class="btn btn-editar-sm" title="Modificar"
                                                                        (click)="btn_EditarComplementoFiscal(item.id)"></button>
                                                                    <button class="btn btn-borrar-sm "
                                                                        (click)="btn_BorrarComplemento(item.id)"
                                                                        title="Eliminar"></button>
                                                                </div>
                                                            </ng-template>
                                                        </e-column>
                                                        <e-column field='fiscal_disposicion' width='200' minWidth='200'
                                                            headerText='Fiscal_Disposición'>
                                                        </e-column>
                                                        <e-column field='fiscal_norma' width='200' minWidth='200'
                                                            headerText='Fiscal_Norma'></e-column>
                                                        <e-column field='fiscal_leyenda' width='200' minWidth='200'
                                                            headerText='Fiscal_Leyenda'>
                                                        </e-column>
                                                    </e-columns>
                                                </ejs-grid>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </e-tabitem>
                    </e-tabitems>
                </ejs-tab>
            </ng-template>
        </form>
    </block-ui>
    <ng-template #footerTemplate>
        <button class="btn btn-guardar" (click)="btn_GuardarDocVentaExtra()"
            [disabled]="!this.modoedicionGeneral">Aceptar</button>
    </ng-template>
</ejs-dialog>

<!-- AGREGAR PRODUCTO EN DETALLES DEL DOCUMENTO -->
<ejs-dialog id="dlgVentaDetalle" #dlgVentaDetalle Height='500px' width='500px' isModal='true' [visible]='false'
    showCloseIcon='true'>
    <ng-template #header>
        Productos y Servicios
    </ng-template>
    <ng-template #content>
        <div class="row">
            <div class='form-group col-md-4'>
                <label>Cantidad:</label>
                <input type="number" id="inicioCantidad" class="form-control " #formP_cantidad [showSpinButton]='false'
                    format='n2' value='0.00' min='0' />
            </div>
            <div class='form-group col-md-8'>
                <label>Codigo:</label>
                <div class="form-group ">
                    <button class="btn feedback-form-control" (click)="dlgProducto.show()"
                        [disabled]="!this.modoedicionGeneral">
                        <i class="fa fa-search"></i>
                    </button>
                    <input type="text" class="form-control  feedback-input" #formP_codigo
                        (keydown.enter)="CargaProductoEnter()">
                </div>
            </div>
        </div>
        <div class="row">
            <div class='form-group col-md-12'>
                <label>Descripción:</label>
                <div class="input-group">
                    <input type="text" #formP_id_producto class="form-control " hidden="true">
                    <textarea #formP_descripcion_pro maxlength='1000' rows="8" floatLabelType='Auto'
                        (input)='inputHandler($event)' style='width:100%;'></textarea>
                    <span id='numbercount'></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class='form-group col-md-4'>
                <label>Precio:</label>
                <input type="text" class="form-control " style="text-align: right;" #formP_precio>
            </div>
            <div class='form-group col-md-4'>
                <label>Descuento:</label>
                <input type="number" class="form-control " style="text-align: right;" #formP_descuento format='n2'
                    value='0.00' min='0' />
            </div>
            <div class='form-group col-md-4'>
                <label>Importe:</label>
                <input type="text" class="form-control " style="text-align: right;" #formP_importe readonly='true'
                    value="0.00">
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-guardar" (click)='btnGuardarProducto()'> {{"Boton.Guardar" | translate}}</button>
    </ng-template>
</ejs-dialog>

<!-- dialogo de estatus de cancelaciones -->
<ejs-dialog id='dlgEstatusCancelaciones' #dlgEstatusCancelaciones header='Asignación De Estatus' width='600px'
    isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #header>
        <b>Seleciona la causa del cambio de Estatus </b><i class="fas fa-ban"> </i>
    </ng-template>
    <ng-template #content>
        <!-- INICIO TABLA DE BUSQUEA-->
        <div class="card">
            <div class="card-detail">
                <ejs-grid [dataSource]='TbCancelacion' allowSorting='true' (rowSelected)="onCancelacion($event)"
                    allowPaging='true' allowTextWrap='true' [toolbar]='toolbarOptions' height='400'>
                    <e-columns>
                        <e-column field='codigo' headerText='Codigo' width='20px' minWidth='20px'>
                        </e-column>
                        <e-column field='descripcion' headerText='Descripcion' width='100' minWidth='90'>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </ng-template>
</ejs-dialog>

<!-- dialogo de estatus de cancelaciones -->
<ejs-dialog id='dlgComentario' #dlgComentario header='Comentario' width='600px' isModal='true' [visible]='false'
    showCloseIcon='true'>
    <ng-template #header>
        <b>Agrega un comentario </b><i class="fas fa-ban"> </i>
    </ng-template>
    <ng-template #content>
        <block-ui>
            <label>Area de texto</label>
            <textarea #comentario class="form-control " rows="3"></textarea>
        </block-ui>
    </ng-template>
</ejs-dialog>

<!-- FIN MODALES -->
<dlgProducto #dlgProducto (producto)="cargaProducto($event)"></dlgProducto>
<!-- <dlgbusquedaventas #dlgbusquedaventas (numero)="cargaFolio($event)"></dlgbusquedaventas> -->