import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { FirmaComponent } from './components/firma/firma.component';
import { ImagenComponent } from './components/imagen/imagen.component';

@Component({
    selector: 'dlgEstatus',
    templateUrl: './dlgEstatus.component.html'
})

export class DlgEstatusComponent {

    @Input() tipo: number;
    @Input() id: number;
    @Input() formulario: number = -1;
    @Output('estatus') estatus = new EventEmitter<any>();
    @ViewChild(FirmaComponent) firmaComponent!: FirmaComponent;
    @ViewChild(ImagenComponent) imagenComponent!: ImagenComponent;
    public data: any[];
    public firmado: boolean = false;
    public imagen: boolean = false;

    @Input() carga() {
        this.getListado();
    };

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private http: HttpClient
    ) { }

    /**
     * Metodo para obtener listado de estatus disponibles para el usuario firmado
     * ! Los estatus dependerán de los permisos que tenga asignado dicho usuario a determinado
     * ! documento, aplicando las reglas de asignación de estatus a documento y posible
     * ! asignacion de manera repetida.
     */
    public getListado() {
        this.http.get<any>(`${environment.base_url}/sistema/lstEstatusAsigna/${this.id}/${this.tipo}/${this.formulario}`, {}).subscribe(
            resp => {
                if (resp.numero > 0) {
                    this.data = resp.respuesta;
                } else {
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
                }
            }
        );
    }

    /**
     * ! Metodo para asignar el ID del boton seleccionado y devolverlo por medio del
     * ! emit para ser valorado por algun metodo que lo llamo previamente
     * ? El metodo o proceso mas comun despues de devolver el ID es asignarlo a su tabla
     * ? de historial de cambios de estatus.
     * 
     * Cuando el estatus requiere firma o imagen, se activara su respectivo componente
     * para poder firmar o subir una imagen o obtenerla desde la camara
     * @param id
     */
    public setEstatus(item: any) {
        // Cuando firma = SI, imagen = NO
        if (item.firma && !item.imagen && this.firmado === false) {
            this.firmado = true;
        } else if (item.firma && !item.imagen && this.firmado === true) {
            if (this.firmaComponent.signaturePad.isEmpty()) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Estatus';
                modalRef.componentInstance.contenido = 'Necesito Firma';
            } else {
                this.modalService.dismissAll();
                this.estatus.emit({
                    id: item.id,
                    firma: this.firmaComponent.signaturePad.toDataURL(),
                    imagen: ''
                });
            }
        }

        // Cuando firma = NO, imagen = SI
        if (!item.firma && item.imagen && this.imagen === false) {
            this.imagen = true;
        } else if (!item.firma && item.imagen && this.imagen === true) {
            if (this.imagenComponent.selectedImage === undefined) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Estatus';
                modalRef.componentInstance.contenido = 'Necesito Imagen';
            } else {
                this.modalService.dismissAll();
                this.estatus.emit({
                    id: item.id,
                    firma: '',
                    imagen: this.imagenComponent.selectedImage
                });
            }
        }

        // Cuando firma = SI, imagen = SI
        if (item.firma && item.imagen && (this.firmado === false || this.imagen === false)) {
            this.firmado = true;
            this.imagen = true;
        } else if (item.firma && item.imagen && this.firmado === true && this.imagen === true) {
            if (this.firmaComponent.signaturePad.isEmpty()) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Estatus';
                modalRef.componentInstance.contenido = 'Necesito Firma';
                return;
            }

            if (this.imagenComponent.selectedImage === undefined) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Estatus';
                modalRef.componentInstance.contenido = 'Necesito Imagen';
                return;
            }
            this.modalService.dismissAll();
            this.estatus.emit({
                id: item.id,
                firma: this.firmaComponent.signaturePad.toDataURL(),
                imagen: this.imagenComponent.selectedImage
            });

        }

        // Cuando no pide imagen y tampoco firma
        if (!item.firma && !item.imagen) {
            this.modalService.dismissAll();
            this.estatus.emit({
                id: item.id,
                firma: '',
                imagen: ''
            });
        }
    }

    /**
     * Metodo para cerrar la ventana modal
     */
    public close() {
        this.modalService.dismissAll();
    }

}
