<div class="modal-header">
    <h5 class="modal-title">Actividad</h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body" style="padding: 10px 10px;">
    <div class="card">
        <div class="card-detail">
            <form autocomplete="off" [formGroup]="formulario">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="referencia">Titulo</label>
                        <input type="text" class="form-control" formControlName="descripcion" />
                    </div>
                    <div class="form-group  col-md-4">

                        <label class="form-label">
                            {{"General.Contacto" | translate}}
                            <i class="fa-solid fa-arrow-up-right-from-square" (click)="pnContacto()"></i>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="nombre_contacto" [readOnly]="true">
                            <div class="input-group-append">
                                <button class="btn btn-buscar-cuadrado" (click)="btnBuscarContacto()"></button>
                            </div>
                            <input type="hidden" class="form-control" formControlName="id_cliente_contacto">
                        </div>
                    </div>
                    <div class="form-group col-md-4" style="text-align: right; bottom: -15px">
                        <button class="btn btn-guardar" (click)="guardarActividad()" [disabled]="!editable">
                            Guardar
                        </button>
                    </div>

                    <!--Seccion en que colocamos la descripcion de la tarea y los comentarios del resto de los usuario-->
                    <div class="form-group">
                        <!-- <ejs-richtexteditor #tfTexto [toolbarSettings]='tools' [insertImageSettings]='imageSettings' formControlName="texto" height="200px"></ejs-richtexteditor> -->
                        <quill-editor #tfTexto style="width: 100%; max-height: 300px" [modules]="modules"
                            formControlName="texto"></quill-editor>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <div class="card-title">
            <h6>Archivos de la Actividad</h6>
        </div>
        <div class="card-detail" style="padding-left: 5px; padding-top: 0px; padding-right: 5px">
            <ngx-file-drop dropZoneLabel="Soltar aqui los archivos XML" (onFileDrop)="dropped($event)"
                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="false"
                [showBrowseBtn]="false" accept=".*" [directory]="false" dropZoneClassName="dropzone"
                contentClassName="dropzone-content">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    Soltar aqui los archivos o
                    <a (click)="openFileSelector()">Clic para seleccionar</a>
                </ng-template>
            </ngx-file-drop>

            <div class="table-responsive">
                <table *ngIf="this.dataArchivos" class="table table-hover table-bordered">
                    <thead>
                        <th style="width: 65px;"></th>
                        <th>Nombres</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.dataArchivos">
                            <td>
                                <div style="display: inline-flex">
                                    <button class="btn btn-borrar-sm" (click)="btnEliminarArchivo(item.id)"
                                        title="Eliminar Archivo"></button>
                                    <button class="btn btn-descarga-sm" (click)="decarga(item.id, item.nombre)"
                                        title="Descargar Archivo"></button>
                                </div>
                            </td>
                            <td>
                                {{item.nombre}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-title">
            <div class="row">
                <div class="col-8">
                    <h6>Subtareas</h6>
                </div>
                <div class="col-4" style="text-align: right">
                    <button class="btn btn-nuevo" (click)="abrirSubdialogo()">
                        Agregar
                    </button>
                </div>
            </div>
        </div>
        <div class="card-detail">
            <ul class="listas">
                <li class="listas" *ngFor="let subtarea of dataSubtareas">
                    <button class="btn btn-outline-danger btn-sm" (click)="btnSubtareaEliminar(subtarea)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                    <button class="btn btn-editar-sm" (click)="btnModificarSubtarea(subtarea)"
                        title="Modificar SubTarea">
                    </button>
                    <button class="btn btn-nuevo" (click)="btnAsignar(subtarea)" title="Asignar Empleado">
                    </button>
                    <ejs-checkbox [label]="subtarea.descripcion" [checked]="subtarea.terminado"
                        (change)="btnSubtareaTerminada($event, subtarea)">
                    </ejs-checkbox>
                    <ejs-chiplist *ngIf="editable" enableDelete="true" (delete)="btnDesasignar($event)">
                        <e-chips>
                            <e-chip *ngFor="let usuario of subtarea.usuarios" value="{{usuario.id}}"
                                text="{{usuario.nombre}}" cssClass="e-success"></e-chip>
                        </e-chips>
                    </ejs-chiplist>
                    <ejs-chiplist *ngIf="!editable" enableDelete="false">
                        <e-chips>
                            <e-chip *ngFor="let usuario of subtarea.usuarios" value="{{usuario.id}}"
                                text="{{usuario.nombre}}" cssClass="e-success"></e-chip>
                        </e-chips>
                    </ejs-chiplist>
                </li>
            </ul>
        </div>
    </div>

    <div>
        <div class="history-tl-container">
            <ul class="tl">
                <li *ngFor="let comentario of dataComentarios" class="tl-item" ng-repeat="item in retailer_history">
                    <div class="timestamp">
                        {{ comentario.fecha | date : "dd/MM/yyyy HH:mm" }}
                    </div>
                    <div class="item-title">{{ comentario.nombre }}</div>
                    <div class="item-detail" [innerHTML]="comentario.comentario | safeHtml"></div>
                </li>
            </ul>
        </div>
        <div class="card">
            <div class="card-title">
                <div class="row">
                    <div class="col-8">
                        <h6>Comentarios</h6>
                    </div>
                    <div class="col-4" style="text-align: right">
                        <button class="btn btn-guardar" style="float: right" (click)="guardarComentario()">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-detail">
                <!-- <ejs-richtexteditor #tfComentarios [toolbarSettings]='tools' [insertImageSettings]='imageSettings' height="150px"></ejs-richtexteditor> -->
                <quill-editor #tfComentarios id="tfComentaiios" style="width: 100%" [modules]="modules"></quill-editor>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-correo" (click)="enviarCorreo()">
        Enviar
    </button>
</div>

<!-- Ventana modal para la creacion de Subtareas -->
<ng-template #dlgSubtarea let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Subtarea de la Actividad</h5>
        <button class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="dialogContent">
            <form [formGroup]="frmSubtarea">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="referencia">Descripcion</label>
                        <input type="text" ngbAutoFocus class="form-control" formControlName="descripcion" required="true">
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-guardar" (click)="btnSubtareaGuardar()"> {{"Boton.Guardar" | translate}}</button>
    </div>
</ng-template>