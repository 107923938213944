import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ConversacionComponent } from './components/conversacion/conversacion.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { ChatBotService } from './services/chatbot.service';
import { IConversacionMessage } from './interfaces/conversacion-message';


@Component({
    selector: 'app-chatbot',
    imports: [CommonModule,
        HttpClientModule,
        ConversacionComponent,
        PromptComponent,
        MatIconModule],
    standalone: true,
    providers: [ChatBotService],
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {
    
    public messages: IConversacionMessage[] = [];
    chatboxOpen: boolean = false;

    constructor(private chatBotService: ChatBotService) { }

    ngOnInit() {
        this.messages = this.chatBotService.messages;
    }

    toggleChatbox() {
        this.chatboxOpen = !this.chatboxOpen;
    }

    handlePromptChange($event: any) {
        this.messages.push({
            from: 'user',
            text: $event
        });
        this.chatBotService.submitPrompt($event).subscribe({
            next: (res) => {
                setTimeout(() => {
                    this.messages.push({
                        from: 'bot',
                        text: res.choices[0].text.trim()
                    })
                }, 500);
            },
            error: (err) => {
                setTimeout(() => {
                    this.messages.push({
                        from: 'bot',
                        text: err.error?.error?.message
                    });
                }, 500);
            }
        });
    }
}
