<div class="modal-header">
    <h4 class="modal-title encabezado">Busqueda de cuentas contables</h4>
    <!-- <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body">
    <div class="col-md-12">
        <div class='form-group'>
            <label>{{"Documento.Referencia" | translate}}</label>
            <div class="input-group">
                <input type="text" #referencia autofocus class="form-control" aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm" (keydown.enter)="getCatalogoBuscar()">
                <div class="input-group-append">
                    <button class="btn btn-buscar-cuadrado" (click)="getCatalogoBuscar()"></button>
                </div>
            </div>
        </div>

        <div class="form-check form-switch">
            <label for="chkAfectable" class="form-check-label"
                style="margin-left: 10px; padding-top: 4px;">Afectable</label>
            <input id="chkAfectable" #chkAfectable class="form-check-input" style="font-size: 16px; margin-left: -32px"
                type="checkbox" role="switch" [checked]="true">
        </div>

    </div>
    <ejs-grid #tabla [dataSource]='data' (rowSelected)="onSelected($event)" height='400'>
        <e-columns>
            <e-column field='id' headerText='' width='0'></e-column>
            <e-column field='cuenta' headerText='Cuenta' width='120'></e-column>
            <e-column field='descripcion' headerText='Descripcion' min-Width='200'></e-column>
            <e-column field='moneda' headerText='Moneda' width='50'></e-column>
        </e-columns>
    </ejs-grid>
</div>
<div class="modal-footer piepagina">
    <button class="btn boton" autofocus aria-label="Close" (click)="activeModal.close()">
        Cerrar
    </button>
</div>
