import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-tablero',
	templateUrl: './tablero.component.html',
	styleUrls: ['./tablero.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class TableroComponent implements OnInit {
	public data: any;
	public breadcrumbs1: string = '';

	constructor(
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.route.data.subscribe(resp => {
			this.breadcrumbs1 = 'Dashboards';
		});
		this.route.data.subscribe((resp: { listado: any[], rutas: any }) => {
			this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
		});
	}

	
}