import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductoService {

    constructor(private http: HttpClient, private errores: ErroresService) { }

    //=========================>busqueda de mi producto<============================================================================//
    public buscar_Producto(referencia: string, id_clasificacion: any): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia).append('id_clasificacion', id_clasificacion);
        return this.http.get<any>(`${environment.base_url}/inventario/producto/buscar_producto`, { params: parametros, });
    }
    /**
     * Metodo para el listado de las clasificaciones con los cuales se va a filtrar
     * la busqueda de los productos.
     * @return Información en formato JSON
     **/
    public getClasificacion(): Observable<any> {
        const parametro: HttpParams = new HttpParams();
        return this.http
            .get(
                `${environment.base_url}/inventario/producto/lstclasificacion`
            )
            .pipe(
                catchError((error) => {
                    return throwError(this.errores.getErrores(error));
                })
            );
    }
}
