import {
    Component,
    OnInit,
    HostBinding,
    HostListener,
    Input,
} from '@angular/core';

@Component({
    selector: 'popupmenu',
    templateUrl: './popupmenu.component.html',
    styleUrls: ['./popupmenu.component.scss'],
})
export class Popupmenu {
    /**
     * We use @HostBinding to dynamically position, show and hide the host element.
     * A parent needs to call the open() method to show the menu.
     * This method takes as input a MouseEvent object.
     * We call stopPropagation() so that the event doesn’t bubble up.
     * Otherwise our document click handler will get called and the menu will be closed immediately.
     * We attach a document click handler and from there we close the menu.
     */

    @HostBinding('style.top') y = '0px';
    @HostBinding('style.left') x = '0px';
    @HostBinding('style.visibility') visibility = 'hidden';
    @Input() @HostBinding('style.width') width = '200px';

    constructor() {}

    ngOnInit() {}

    /**
     * Methos to open the popupmenu
     * @param e Event from mouse click
     */
    open(e: MouseEvent) {
        this.x = `${e.pageX}px`;
        this.y = `${e.pageY}px`;
        this.visibility = 'visible';
        e.stopPropagation();
    }

    /**
     * Method to close the popupmenu
     */
    close() {
        this.visibility = 'hidden';
    }

    /**
     * Method to control the event click, when click then close
     */
    @HostListener('document:click')
    public onDocumentClick() {
        if (this.visibility === 'visible') {
            this.close();
        }
    }

	/**
	 * Metodo para cerrar menu cuando se utiliza la rueda del mouse
	 */
	@HostListener('document:wheel')
    public onDocumentWheel() {
        if (this.visibility === 'visible') {
            this.close();
        }
    }
}
