import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { DlgBusquedaVentasComponent } from 'src/app/components/dlgbusquedaventas/dlgbusquedaventas.component';
import { DlgClienteComponent } from 'src/app/components/dlgCliente/dlgCliente.component';
import { DlgProductoComponent } from 'src/app/components/dlgProducto/dlgProducto.component';
import { MdlUsuario } from 'src/app/models/MdlUsuario';
import { environment } from 'src/environments/environment';
import { docVentaService } from '../services/docVenta.service';
import { EmitType } from '@syncfusion/ej2-base';
import { DlgEstatusComponent } from 'src/app/components/dlgEstatus/dlgEstatus.component';
import { EditSettingsModel, GridComponent, } from '@syncfusion/ej2-angular-grids';
import { DlgcorreoComponent } from 'src/app/components/dlgcorreo/dlgcorreo.component';
import { MdlDocVentaExtra } from '../models/MdlDocVentaExtra';
import { FileManagerService, HtmlEditorService, ImageService, LinkService, QuickToolbarService, } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarService } from '@syncfusion/ej2-angular-grids';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { MdlDocumento } from '../models/MdlDocumento';
import { DlgClienteDomicilio } from 'src/app/components/dlgClienteDomicilio/dlgClienteDomicilio.component';
import { DlgClienteContacto } from 'src/app/components/dlgClienteContacto/dlgClienteContacto.component';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { util } from 'src/app/shared/util';
import { dlgVentaArchivos } from '../archivos/archivos.component';
import { dlgDocumentos } from 'src/app/components/dlgDocumentos/dlgDocumentos.component';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';
import { ClienteComponent } from '../clientes/cliente/cliente.component';
import { Popupmenu } from 'src/app/components/popupmenu/popupmenu.component';
import { ContactoService } from '../../RMgestion/contactos/services/Contactos.service';
import { DlgcontactoComponent } from '../../RMgestion/contactos/components/dlgcontacto/dlgcontacto.component';
import { MensajeriaService } from '../../RMgestion/mensajeria/services/mensajeria.service';
import { MdlMensaje } from '../../RMgestion/mensajeria/models/MdlMensaje.model';
import { VariableComponent } from './components/variable/variable.component';

@Component({
	selector: 'app-documento',
	templateUrl: './documento.component.html',
	styleUrls: ['./documento.component.scss'],
	providers: [
		ToolbarService,
		LinkService,
		ImageService,
		HtmlEditorService,
		QuickToolbarService,
		FileManagerService,
	],
})
export class DocumentoComponent implements OnInit {

	@ViewChild('Grid') public Grid: GridComponent;
	@ViewChild('GridDetalles') public GridDetalles: GridComponent;
	@ViewChild('dglCorreo') public dglCorreo: DlgcorreoComponent;
	@ViewChild('comentario') comentario: ElementRef;
	@ViewChild('dlgBusquedaFolio') dlgBusquedaFolio: DialogComponent;
	@ViewChild('dlgEstatusCancelaciones') dlgEstatusCancelaciones: DialogComponent;
	@ViewChild('dlgComentario') dlgComentario: DialogComponent;
	@ViewChild('formP_descripcion_pro') formP_descripcion_pro: ElementRef;
	@ViewChild(Popupmenu) menuOpciones: Popupmenu;

	//VENTANAS MODALES
	@ViewChild('dlgEstatus') public dlgEstatus: DlgEstatusComponent;
	@ViewChild('dlgProducto') dlgProducto: DlgProductoComponent;
	@ViewChild('dlgbusquedaventas') dlgbusquedaventas: DlgBusquedaVentasComponent;
	@ViewChild('dlgBusquedaCliente') dlgBusquedaCliente: DlgClienteComponent;
	@ViewChild('dlgVentaDetalle') dlgVentaDetalle: DialogComponent;
	@ViewChild('dlgInformaciónExtra') dlgInformaciónExtra: DialogComponent;

	// Identificador principal del documento el cual sera enviado desde
	// el origen cuando se requiera abrir un documento de venta
	@Input() id: number = -1;
	public trabajando: boolean = true;
	public data: any;
	public editSettings: EditSettingsModel;
	public fields: Object = { text: 'descripcion', value: 'id' };
	public formatoFecha: string = 'dd/MM/yyyy';
	public TbCancelacion: any;
	public modoedicionCancelar: boolean = false;
	public timbradoCancelar: boolean = false;
	public timbra: boolean = false;
	public itemDocumento: any;

	//DOC_VENTA_ENCABEZADO
	public tbEncabezado: Object = [
		{ text: '<i class="fas fa-layer-group"></i> &nbsp;Detalles', iconCss: '' },
		{ text: '<i class="fas fa-window-restore"></i> &nbsp;Extra', iconCss: '' },
		{ text: '<i class="fas fa-hand-holding-usd"></i> &nbsp;Finanzas', iconCss: '' },
		{ text: '<i class="fas fa-folder"></i> &nbsp;Logistica', iconCss: '' }
	];

	//FORMULARIOS
	public usuario: MdlUsuario;
	public formulario: UntypedFormGroup;
	public frm_prueba: UntypedFormGroup;
	public frm_doc_venta_extra: UntypedFormGroup;
	public frm_doc_extra_complemento: UntypedFormGroup;
	public buttonDisabled: boolean = false;
	public data_encabezado: any;
	public data_detalle: any;
	public totales: any = { subtotal: 0.00, impuesto: 0.00, total: 0.00 };
	public data_historial: any;


	//VARIABLES DOC_VENTA PRINCIPAL
	public data_vendedor: any;
	public data_doc_tipo: any;
	public data_formapago: any;
	public data_metodopago: any;
	public data_usoCFDI: any;
	public data_fin_moneda: any;
	public data_folios: any;
	public modoedicionGeneral: boolean = true;

	//VARIABLES DETALLE DE VENTA
	public data_producto_doc: any;
	public id_documento_detalle: number = -1;
	public dateValue: Date = new Date();

	//FORMULARIO DE PRODUCTOS
	@ViewChild('formP_cantidad') formP_cantidad: ElementRef;
	@ViewChild('formP_codigo') formP_codigo: ElementRef;
	@ViewChild('formP_id_producto') formP_id_producto: ElementRef;
	@ViewChild('formP_precio') formP_precio: ElementRef;
	@ViewChild('formP_descuento') formP_descuento: ElementRef;
	@ViewChild('formP_importe') formP_importe: ElementRef;

	//VARIABLES INFORMACIÓN EXTRA
	public tabla_complementosFiscales: any;
	public data_frm_doc_venta_extra: any;
	public data_tipoOperacionINE: any;
	public data_comiteINE: any;
	public data_ambitoINE: any;
	public data_tipoOperacion: any;
	public data_clavePedimento: any;
	public data_certificadoOrigen: any;
	public data_incoterm: any;
	public data_tiporelacion: any;
	public data_prioridadExtra: any;
	public data_subdivision: any;
	public data_motivoTraslado: any;
	public data_documento_relacionado: any;
	public id_doc_info_extra: number;
	public id_complemento_fiscal: number = -1;
	public tbExpediente: Object = [
		{ text: '<i class="fas fa-layer-group"></i> &nbsp;Extra', iconCss: '' },
		{ text: '<i class="fas fa-plane-arrival"></i> &nbsp;Aduanera', iconCss: '', },
		{ text: '<i class="fas fa-coins"></i> &nbsp;Fiscal', iconCss: '' },
		{ text: '<i class="fas fa-history"></i> &nbsp;Historial', iconCss: '' },
		{ text: '<i class="far fa-calendar-alt"></i> &nbsp;Programación', iconCss: '', },
		{ text: '<i class="far fa-id-card"></i> &nbsp;INE', iconCss: '' },
		{ text: '<i class="far fa-file-alt"></i> &nbsp;Textos', iconCss: '' },
		{ text: '<i class="fas fa-archive"></i> &nbsp;Complementos', iconCss: '' },
	];

	UtilComponent: any;
	public contacto_bandera: any;

	constructor(
		private fb: UntypedFormBuilder,
		private http: HttpClient,
		private servicio: docVentaService,
		private servicioContacto: ContactoService,
		public datepipe: DatePipe,
		private modalService: NgbModal,
		private offcanvasService: NgbOffcanvas,
		private utilerias: util,
		private servicioMensajeria: MensajeriaService
	) {
		this.formulario = this.fb.group(new MdlDocumento);
		this.usuario = JSON.parse(localStorage.getItem('usuario'));
		this.servicio.getEmpleado(-1).subscribe((resp) => {
			this.data_vendedor = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.getDoc_tipo().subscribe((resp) => {
			this.data_doc_tipo = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.formapago().subscribe((resp) => {
			this.data_formapago = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.metodopago().subscribe((resp) => {
			this.data_metodopago = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.usoCFDI().subscribe((resp) => {
			this.data_usoCFDI = JSON.parse(JSON.stringify(resp)).respuesta;
		});
	}

	ngOnInit(): void {
		this.frm_doc_venta_extra = this.fb.group(new MdlDocVentaExtra());
		this.frm_doc_extra_complemento = this.fb.group({
			fiscal_disposicion: [''],
			fiscal_norma: [''],
			fiscal_leyenda: [''],
		});
	}

	ngAfterViewInit() {
		//Cuando carge el componente obtendra el id del documento de venta
		//si es que se abre externamente y lo asigna a la variable local.
		if (this.id < 0) {
			this.modoedicionGeneral = true;
			this.trabajando = false;
		} else {
			//Se carga el metodo que cargará la data del documento de venta.
			this.getDocumento(this.id);
		}

		/*
		this.servicio.prioridadExtra().subscribe((resp) => {
		  this.data_prioridadExtra = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.tipoOperacion().subscribe((resp) => {
		  this.data_tipoOperacion = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.clavePedimento().subscribe((resp) => {
		  this.data_clavePedimento = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.certificadoOrigen().subscribe((resp) => {
		  this.data_certificadoOrigen = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.incoterm().subscribe((resp) => {
		  this.data_incoterm = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.tiporelacionextra().subscribe((resp) => {
		  this.data_tiporelacion = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.subdivisión().subscribe((resp) => {
		  this.data_subdivision = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.motivoTraslado().subscribe((resp) => {
		  this.data_motivoTraslado = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.tipoOperacionINE().subscribe((resp) => {
		  this.data_tipoOperacionINE = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.comiteINE().subscribe((resp) => {
		  this.data_comiteINE = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.ambitoINE().subscribe((resp) => {
		  this.data_ambitoINE = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		this.servicio.cargaHistorial(params.id).subscribe((resp) => {
		  this.data_historial = JSON.parse(JSON.stringify(resp)).respuesta;
		});
		*/
	}

	/**
	 * Metodo para identificar si el documento permite timbrar, de ser asi los componentes propios de timbrados seran visibles
	 * de lo contrario se ocultaran
	 * @param args Item seleccionado
	 */
	public onchange(args: any) {
		let item = this.data_doc_tipo.filter(data => data.id == args.target.value);
		this.timbra = item[0].timbra;
	}

	/**
	 * ESTE METODO DE HERRAMIENTAS ES PARA DEJAR VACIO EL RICHEDITOR.
	 */
	public tools: object = {
		items: [''],
	};

	public inputHandler(args): void {
		let word, addresscountRem, addressCount;
		word = this.formP_descripcion_pro.nativeElement.value;
		addressCount = word.length;
		addresscountRem = document.getElementById('numbercount');
		addresscountRem.textContent = addressCount + '/1000';
	}

	/**
	 *
	 * @returns Metodo para validar que el documento se haya creado o cargado de manera correcta
	 */
	public existeEncabezado(): boolean {
		//==========================================================
		// No tenemos un ID aun asignado
		//==========================================================
		if (this.formulario.controls['id'].value < 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Ventas';
			modalRef.componentInstance.error = 'El documento aun no esta creado!';
			modalRef.componentInstance.contenido = 'Se debe seleccionar la informacion del encabezado y dar clic en Guardar';
			return false;
		}
		return true;
	}

	/**
	 * Metodo para crear un nuevo documento, en el se limpan todos los
	 * input necesarios para el registro.
	 */
	btnNuevoDocumento() {
		this.formulario = this.fb.group(new MdlDocumento);
		this.data_producto_doc = [];
		this.data_detalle = [];
		this.modoedicionGeneral = true;
		this.modoedicionCancelar = false;
		this.timbradoCancelar = false;
		this.totales.subtotal = 0.0;
		this.totales.impuesto = 0.0;
		this.totales.total = 0.0;
	}

	/**
	 * Metodo para abrir la ventana modal para importar archivos al documento
	 */
	public btnArchivos() {
		if (this.existeEncabezado()) {
			const modalRef = this.modalService.open(dlgVentaArchivos);
			modalRef.componentInstance.encabezado = "Archivos";
			modalRef.componentInstance.contenido = "Archivos relacionados al documento";
			modalRef.componentInstance.modoEdicion = this.modoedicionGeneral;
			modalRef.componentInstance.id = this.formulario.controls['id'].value
			modalRef.componentInstance.carga();
		}
	}

	btnNuevoProducto() {
		let word, addresscountRem, addressCount;
		word = this.formP_descripcion_pro.nativeElement.value;
		addressCount = word.length;
		addresscountRem = document.getElementById('numbercount');
		addresscountRem.textContent = addressCount + '/1000';
		this.formP_codigo.nativeElement.value = '';
		this.formP_id_producto.nativeElement.value = '';
		this.formP_cantidad.nativeElement.value = 1;
		this.formP_precio.nativeElement.value = '';
		this.formP_descripcion_pro.nativeElement.value = '';
		this.formP_descuento.nativeElement.value = 0.0;
		this.dlgVentaDetalle.show();
	}

	/**
	 * Metodo para cargar cuando el componente contiene un id en la url. En caso de que si,
	 * se ejecuta con el ngOnInit.
	 * @param id : ID del documento que se carga en la url.
	 */
	//@todo
	public getDocumento(id: number) {
		this.servicio.cargaEncabezado(id).subscribe(resp => {
			let documento: MdlDocumento = JSON.parse(JSON.stringify(resp.respuesta.documento), this.utilerias.parseFecha) as MdlDocumento;
			this.modoedicionGeneral = documento.modoedicion;
			this.servicio.getMoneda(documento.id_cliente).subscribe(resp => {
				if (resp.numero > 0) {
					this.data_fin_moneda = resp.respuesta;
					setTimeout(() => {
						this.formulario = this.fb.group(documento as MdlDocumento);
						if (this.formulario.controls['id_cliente_contacto'].value > 0) {
							this.contacto_bandera = true;
						} else {
							this.contacto_bandera = false;
						}
						this.servicio.cargaDetalle(this.formulario.controls['id'].value).subscribe((resp) => {
							this.data_detalle = JSON.parse(JSON.stringify(resp)).respuesta.detalle;
							this.totales = JSON.parse(JSON.stringify(resp)).respuesta.totales;
						});
						this.trabajando = false;
					});


				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = resp.titulo;
					modalRef.componentInstance.contenido = resp.mensaje;
				}
			});
		});
	}

	/**
	 * Se carga la información validando en que input se va a sustituir
	 *  @param args Es la data del producto que se selecciona
	 */
	public cargaProducto(args) {
		if (args === null) {
			return;
		}
		if (args.precio <= 0 || args.precio == '') {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'El Producto no tiene Precio para el almacen utilizado';
			return;
		} else {
			this.formP_codigo.nativeElement.value = args.codigo;
			this.formP_id_producto.nativeElement.value = args.id;
			this.formP_descripcion_pro.nativeElement.value = args.nombre;
			this.formP_precio.nativeElement.value = args.precio;
		}
		this.dlgProducto.hide();
	}

	/**
	 * Se carga la información validando en que input se va a sustituir
	 *  @param args Es la data del cliente que se selecciono
	 */
	public btnCliente() {
		const modalRef = this.modalService.open(DlgClienteDomicilio);
		modalRef.componentInstance.cliente.subscribe(resp => {
			this.contacto_bandera = false;
			this.formulario.controls['id_cliente'].setValue(resp.id);
			this.formulario.controls['id_cliente_contacto'].setValue(-1);
			this.formulario.controls['id_cliente_domicilio'].setValue(resp.id_domicilio);
			this.formulario.controls['cliente'].setValue(resp.nombre);
			this.formulario.controls['contacto'].setValue('');
			this.formulario.controls['domicilio'].setValue(resp.domicilio);
			this.servicio.getMoneda(resp.id).subscribe((resp) => {
				this.data_fin_moneda = JSON.parse(JSON.stringify(resp)).respuesta;
			});
		});
	}

	public btnContacto() {
		const modalRef = this.modalService.open(DlgClienteContacto);
		modalRef.componentInstance.contacto.subscribe(resp => {
			if (resp.id_cliente > 0) {
				this.contacto_bandera = true;
				this.formulario.controls['id_cliente'].setValue(resp.id_cliente);
				this.formulario.controls['id_cliente_contacto'].setValue(resp.id);
				this.formulario.controls['id_cliente_domicilio'].setValue(resp.id_cliente_domicilio);
				this.formulario.controls['contacto'].setValue(resp.nombres);
				this.formulario.controls['domicilio'].setValue('');
				this.servicio.getMoneda(resp.id_cliente).subscribe(resp => {
					this.data_fin_moneda = resp.respuesta;
				});
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = resp.titulo;
				modalRef.componentInstance.contenido = resp.mensaje;
			}
		});
	}


	get doc_tipoNoValida() {
		return (
			this.formulario.get('id_doc_tipo').invalid &&
			this.formulario.get('id_doc_tipo').touched
		);
	}
	get tipo_cambioNoValida() {
		return (
			this.formulario.get('id_fin_moneda').invalid &&
			this.formulario.get('id_fin_moneda').touched
		);
	}
	get fechaNoValida() {
		return (
			this.formulario.get('fecha').invalid &&
			this.formulario.get('fecha').touched
		);
	}
	get formaPagoNoValida() {
		return (
			this.formulario.get('id_doc_cobro').invalid &&
			this.formulario.get('id_doc_cobro').touched
		);
	}
	get metodoPagoNoValida() {
		return (
			this.formulario.get('id_doc_venta_metodopago').invalid &&
			this.formulario.get('id_doc_venta_metodopago').touched
		);
	}
	get usoCFDINoValida() {
		return (
			this.formulario.get('id_sat_usocfdi').invalid &&
			this.formulario.get('id_sat_usocfdi').touched
		);
	}

	public revisa() {
		if (this.formulario.invalid) {
			return Object.values(this.formulario.controls).forEach((control) => {
				control.markAsTouched();
			});
		}
	}

	/**
	 * Metodo para guardar documento
	 */
	public btnGuardar() {
		this.revisa();
		if (!this.formulario.invalid) {
			if (this.formulario.controls['id_fin_moneda'].value < 0) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = 'Seleccionar Moneda';
				return;
			}
			if (this.formulario.controls['tc_general'].value <= 0) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = 'Tipo de Cambio debe ser 1.0 o mayor';
				return;
			}
			this.servicio.guardarDocumento(this.formulario.value).subscribe(resp => {
				if (resp.numero > 0) {
					// Debemos cargar el documento que se ha creado
					this.getDocumento(resp.numero);
					const modalRef = this.modalService.open(dlgMensaje);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
			});
		}
	}

	/**
	 * Metodo para identificar si trabajamos con Contactos o Clientes
	 */
	selectOption() {
		const modalRef = this.modalService.open(dlgMensajeConfirma);
		modalRef.componentInstance.encabezado = 'Cotización';
		modalRef.componentInstance.contenido = '¿Es para contacto?';
		modalRef.result.then((resultado) => {
			if (resultado == 'Si') {
				this.contacto_bandera = true;
				this.btnContacto();
			} else {
				this.contacto_bandera = false;
				this.btnCliente();
			}
		});
	}

	/**
	 * Metodo para asignar estatus al documento
	 * @returns
	 */
	public btnEstatus() {
		if (this.formulario.controls['id'].value <= 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'No ha seleccionado un documento';
			return;
		}
		const modalRef = this.modalService.open(DlgEstatusComponent);
		modalRef.componentInstance.tipo = this.formulario.controls['id_doc_tipo'].value;
		modalRef.componentInstance.id = this.formulario.controls['id'].value
		modalRef.componentInstance.carga();
		modalRef.componentInstance.estatus.subscribe(($event) => {
			this.servicio.setEstatus(this.formulario.controls['id'].value, $event.id).subscribe((resp) => {
				if (resp.numero > 0) {
					this.getDocumento(this.formulario.controls['id'].value);
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
			});
		});
	}

	/**
	 * Metodo para generar el pdf, abrirlo y dar la posibilidad al usuario de imprimir
	 */
	public btnImprimir() {
		this.servicio.setImprimir(this.formulario.controls['id'].value).subscribe(response => {
			let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
			window.open(url, '_blank');
		},
			err => {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = err;
			}
		)
	}

	/**
	 * Metodo para generar el reporte correspondiente al documento y posteriormente mandarlo por whatsapp
	 * al contacto que tenemos seleccionado.
	 * Hay que confirmar que tengamos un contacto valido
	 */
	public btnMensaje() {
		if (this.formulario.controls['id_cliente_contacto'].value < 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Ventas';
			modalRef.componentInstance.contenido = 'No se ha seleccionado un contacto.';
			return;
		}
		if (this.formulario.controls['folio'].value === '') {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Ventas';
			modalRef.componentInstance.contenido = 'Aun no tenemos folio que procesar.';
			return;
		}
		if (this.formulario.controls['telefono'].value === '') {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Ventas';
			modalRef.componentInstance.contenido = 'Contacto sin telefono asignado.';
			return;
		}
		this.servicio.setImprimir(this.formulario.controls['id'].value).subscribe(response => {
			let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
			let formData = new FormData();
			formData.append('messaging_product', 'whatsapp');
			formData.append("file", blob, 'cotizacion-x');
			this.http.post<any>('https://graph.facebook.com/v18.0/166295516577495/media', formData).subscribe(resp => {
				if (resp.id > 0) {
					let mensajeWA: any = {
						"messaging_product": "whatsapp",
						"recipient_type": "individual",
						"to": this.formulario.controls['telefono'].value,
						"type": "document",
						"document": {
							"id": resp.id,
							"caption": this.formulario.controls['tipo'].value,
							"filename": this.formulario.controls['folio'].value + '.pdf'
						}
					};

					this.http.post('https://graph.facebook.com/v18.0/166295516577495/messages', mensajeWA).subscribe(resp => {
						let modelo = new MdlMensaje();
						modelo.id_cliente_contacto = this.formulario.controls['id_cliente_contacto'].value;
						modelo.mensaje = JSON.stringify(mensajeWA);
						modelo.telefono = this.formulario.controls['telefono'].value;
						this.servicioMensajeria.set_wa_mensaje(modelo).subscribe(respuesta => {
							if (respuesta.numero > 0) {
								const modalRef = this.modalService.open(dlgMensaje);
								modalRef.componentInstance.encabezado = respuesta.titulo;
								modalRef.componentInstance.contenido = respuesta.mensaje;
							} else {
								const modalRef = this.modalService.open(dlgMensajeError);
								modalRef.componentInstance.encabezado = respuesta.titulo;
								modalRef.componentInstance.contenido = respuesta.mensaje;
							}
						});
					});
				}
			});
		},
			err => {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = err;
			}
		)
	}

	/**
	 * Metodo para enviar correo con referencia al documento selecionado
	 */
	public btnCorreo() {
		this.servicio.getCorreo(this.formulario.controls['id'].value).subscribe(resp => {
			if (resp.numero > 0) {
				let informacion = resp.respuesta;
				const modalRef = this.modalService.open(DlgcorreoComponent);
				modalRef.componentInstance.id = this.formulario.controls['id'].value;
				modalRef.componentInstance.modulo = 'ventas';
				modalRef.componentInstance.informacion = informacion;
				modalRef.componentInstance.respuesta.subscribe(resp => { });
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
			}
		});
	}


	public btnBorrarProductoDocVenta: EmitType<object> = (id: number) => {
		this.http.post(`${environment.base_url}/venta/documentosventa/eliminarProductoDocVenta/${id}`, {}).subscribe((respuesta) => {
			if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
				let dataDoc;
				this.servicio.cargaEncabezado(Number(this.formulario.controls['id'].value)).subscribe((resp) => {
					dataDoc = JSON.parse(JSON.stringify(resp)).respuesta[0];

				});
				this.servicio.cargaDetalle(Number(this.formulario.controls['id'].value)).subscribe((resp) => {
					this.data_detalle = JSON.parse(JSON.stringify(resp)).respuesta.detalle;
					this.totales = JSON.parse(JSON.stringify(resp)).respuesta.totales;
				});
			} else {
				DialogUtility.alert({
					title: 'Informacion Arcarius [Problemas]',
					content: JSON.parse(JSON.stringify(respuesta)).mensaje,
					showCloseIcon: true,
					closeOnEscape: true,
					animationSettings: { effect: 'Zoom' },
				});
			}
		});
	};

	CargaProductoEnter() {
		this.servicio.bus_producto_cod(this.formP_codigo.nativeElement.value.trim()).subscribe((resp) => {
			let data = JSON.parse(JSON.stringify(resp)).respuesta[0];
			this.formP_codigo.nativeElement.value = data.codigo;
			this.formP_id_producto.nativeElement.value = data.id;
			this.formP_descripcion_pro.nativeElement.value = data.descripcion;
			this.formP_precio.nativeElement.value = data.precio;
		});
	}

	btnCargaProducto(id: number, descripcion: any) {
		this.servicio.cargaDetalleSolo(id).subscribe((resp) => {
			let data = JSON.parse(JSON.stringify(resp)).respuesta[0];
			this.id_documento_detalle = Number(id);
			this.formP_codigo.nativeElement.value = data.codigo;
			this.formP_id_producto.nativeElement.value = data.id_inv_producto;
			this.formP_cantidad.nativeElement.value = data.cantidad;
			this.formP_precio.nativeElement.value = data.precio;
			this.formP_descripcion_pro.nativeElement.value = descripcion;
			this.formP_descuento.nativeElement.value = data.descuento;
		});
		this.dlgVentaDetalle.show();
	}

	btnGuardarProducto() {
		if (this.formulario.controls['id'].value < 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'No has seleccionado un documento';
			return;
		}
		if (this.formP_cantidad.nativeElement.value <= 0) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'No has agregado la cantidad';
			return;
		}
		if (
			this.formP_id_producto.nativeElement.value == '' ||
			this.formP_id_producto.nativeElement.value < 0
		) {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'Selecciona un producto';
			return;
		}
		this.servicio.guardarProducto(
			this.id_documento_detalle,
			this.formulario.controls['id'].value,
			Number(this.formP_id_producto.nativeElement.value),
			Number(this.formP_cantidad.nativeElement.value),
			Number(this.formP_descuento.nativeElement.value),
			Number(this.formP_precio.nativeElement.value),
			String(this.formP_descripcion_pro.nativeElement.value)
		)
			.subscribe((resp) => {
				if (resp.numero < 0) {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				} else {
					this.servicio.cargaDetalle(Number(this.formulario.controls['id'].value)).subscribe((resp) => {
						this.data_detalle = JSON.parse(JSON.stringify(resp)).respuesta.detalle;
						this.totales = JSON.parse(JSON.stringify(resp)).respuesta.totales;
					});
					this.id_documento_detalle = -1;
					this.formP_cantidad.nativeElement.value = 1;
					this.formP_codigo.nativeElement.value = '';
					this.formP_id_producto.nativeElement.value = '';
					this.formP_descripcion_pro.nativeElement.value = '';
					this.formP_precio.nativeElement.value = '';
					this.formP_descuento.nativeElement.value = 0.0;
					this.formP_importe.nativeElement.value = '';
					document.getElementById('inicioCantidad').focus();
				}
			});
	}

	/**
	 * Metodo para enviar a timbrar el documento de venta
	 */
	public Timbrado() {
		if (this.formulario.controls['id'].value > 0) {
			this.servicio.getTimbrado(this.formulario.controls['id'].value).subscribe((resp) => {
				if (resp.numero > 0) {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
					this.getDocumento(this.formulario.controls['id'].value);
				} else {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
					modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
				}
			});
		}
	}


	/**
	 * Metodo para abrir la ventana de informacion extra
	 */
	btnInfoExtra() {
		this.frm_doc_venta_extra.reset();
		if (this.formulario.controls['id'].value > 0) {
			this.dlgInformaciónExtra.show();
			this.servicio.cargaDocVentaExtra(this.formulario.controls['id'].value).subscribe((resp) => {
				this.data_frm_doc_venta_extra = JSON.parse(
					JSON.stringify(resp)
				).respuesta[0];
				this.id_doc_info_extra = this.data_frm_doc_venta_extra.id;
				this.frm_doc_venta_extra = this.fb.group({
					id: [this.data_frm_doc_venta_extra.id],
					id_doc_venta_encabezado: [this.formulario.controls['id'].value],
					texto01: [this.data_frm_doc_venta_extra.texto01],
					numero01: [this.data_frm_doc_venta_extra.numero01],
					fecha_entrega: [this.data_frm_doc_venta_extra.fecha_entrega],
					fecha_devolucion: [this.data_frm_doc_venta_extra.fecha_devolucion],
					anticipo: [this.data_frm_doc_venta_extra.anticipo],
					id_sat_tipo_comite: [
						this.data_frm_doc_venta_extra.id_sat_tipo_comite,
					],
					id_sat_tipo_ambito: [
						this.data_frm_doc_venta_extra.id_sat_tipo_ambito,
					],
					ine_contabilidad: [this.data_frm_doc_venta_extra.ine_contabilidad],
					id_sat_tipo_relacion: [
						this.data_frm_doc_venta_extra.id_sat_tipo_relacion,
					],
					id_doc_venta_relacion: [
						this.data_frm_doc_venta_extra.id_doc_venta_relacion,
					],
					id_sat_aduana_tipo_operacion: [
						this.data_frm_doc_venta_extra.id_sat_aduana_tipo_operacion,
					],
					id_sat_aduana_pedimento: [
						this.data_frm_doc_venta_extra.id_sat_aduana_pedimento,
					],
					id_sat_aduana_certificado: [
						this.data_frm_doc_venta_extra.id_sat_aduana_certificado,
					],
					id_sat_aduana_motivotraslado: [
						this.data_frm_doc_venta_extra.id_sat_aduana_motivotraslado,
					],
					num_certificado_origen: [
						this.data_frm_doc_venta_extra.num_certificado_origen,
					],
					num_exportador_confiable: [
						this.data_frm_doc_venta_extra.num_exportador_confiable,
					],
					id_sat_terminos_internacionales: [
						this.data_frm_doc_venta_extra.id_sat_terminos_internacionales,
					],
					id_sat_aduana_subdivision: [
						this.data_frm_doc_venta_extra.id_sat_aduana_subdivision,
					],
					id_crm_prioridad: [this.data_frm_doc_venta_extra.id_crm_prioridad],
					informacion1: [this.data_frm_doc_venta_extra.informacion1],
					id_doc_venta_metodopago: [
						this.data_frm_doc_venta_extra.id_doc_venta_metodopago,
					],
					id_sat_usocfdi: [this.data_frm_doc_venta_extra.id_sat_usocfdi],
					id_sat_tipo_operacion: [
						this.data_frm_doc_venta_extra.id_sat_tipo_operacion,
					],
					observaciones: [this.data_frm_doc_venta_extra.observaciones],
					puertoexporta: [this.data_frm_doc_venta_extra.puertoexporta],
					puertodescarga: [this.data_frm_doc_venta_extra.puertodescarga],
					viaembarque: [this.data_frm_doc_venta_extra.viaembarque],
					transportista: [this.data_frm_doc_venta_extra.transportista],
					termimoventa: [this.data_frm_doc_venta_extra.termimoventa],
					terminopago: [this.data_frm_doc_venta_extra.terminopago],
					observacion_a: [this.data_frm_doc_venta_extra.observacion_a],
					observacion_b: [this.data_frm_doc_venta_extra.observacion_b],
					deducible: [this.data_frm_doc_venta_extra.deducible],
					coaseguro: [this.data_frm_doc_venta_extra.coaseguro],
					otros: [this.data_frm_doc_venta_extra.otros],
					numerico1: [this.data_frm_doc_venta_extra.numerico1],
					numerico2: [this.data_frm_doc_venta_extra.numerico2],
					numerico3: [this.data_frm_doc_venta_extra.numerico3],
					numerico4: [this.data_frm_doc_venta_extra.numerico4],
				});
			});
			this.servicio.cargaComplementosFiscales(this.formulario.controls['id'].value).subscribe((resp) => {
				this.tabla_complementosFiscales = JSON.parse(
					JSON.stringify(resp)
				).respuesta;
			});
			this.cargaDocumentoRelacionado(this.formulario.controls['id'].value);
		} else {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'No has agregado un documento, selecciona o crea uno.';
		}
	}

	public revisaDocVentaExtra() {
		if (this.frm_doc_venta_extra.invalid) {
			return Object.values(this.frm_doc_venta_extra.controls).forEach(
				(control) => {
					control.markAsTouched();
				}
			);
		}
	}

	/**
	 * Metodo para guardar la información extra.
	 */
	public btn_GuardarDocVentaExtra(): void {
		this.revisaDocVentaExtra();
		if (!this.frm_doc_venta_extra.invalid) {
			if (this.formulario.controls['id'].value > 0) {
				if (this.id_doc_info_extra <= 0) {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = 'Documento Venta';
					modalRef.componentInstance.contenido = 'No hay registro para añadir información extra';
				} else {
					this.servicio.GuardarDocVentaExtra(
						this.frm_doc_venta_extra.value,
						this.frm_doc_venta_extra.get('observaciones').value,
						this.frm_doc_venta_extra.get('puertoexporta').value,
						this.frm_doc_venta_extra.get('puertodescarga').value,
						this.frm_doc_venta_extra.get('viaembarque').value,
						this.frm_doc_venta_extra.get('transportista').value,
						this.frm_doc_venta_extra.get('termimoventa').value,
						this.frm_doc_venta_extra.get('terminopago').value,
						this.frm_doc_venta_extra.get('observacion_a').value,
						this.frm_doc_venta_extra.get('observacion_b').value,
						this.frm_doc_venta_extra.get('deducible').value,
						this.frm_doc_venta_extra.get('coaseguro').value,
						this.frm_doc_venta_extra.get('otros').value,
						this.frm_doc_venta_extra.get('numerico1').value,
						this.frm_doc_venta_extra.get('numerico2').value,
						this.frm_doc_venta_extra.get('numerico3').value,
						this.frm_doc_venta_extra.get('numerico4').value
					).subscribe(resp => {
						if (resp.numero > 0) {
							const modalRef = this.modalService.open(dlgMensaje);
							modalRef.componentInstance.encabezado = resp.titulo;
							modalRef.componentInstance.contenido = resp.mensaje;
						} else {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = resp.titulo;
							modalRef.componentInstance.contenido = resp.mensaje;
						}
					});
				}
			} else {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = 'No hay un documento al que agregarle información';
			}
		} else {
			const modalRef = this.modalService.open(dlgMensajeError);
			modalRef.componentInstance.encabezado = 'Documento Venta';
			modalRef.componentInstance.contenido = 'Error en el formulario, revisar que los campos concuerden';
		}
	}

	/**
	 * Metodo para cargar complemento fiscal a editar.
	 * @param id ID del doc encabezado.
	 */
	public btn_EditarComplementoFiscal(id: number): void {
		this.servicio.cargaComplementoEditar(id).subscribe((resp) => {
			let data = resp.respuesta;
			this.id_complemento_fiscal = data.id;
			this.frm_doc_extra_complemento = this.fb.group({
				fiscal_disposicion: [data.fiscal_disposicion],
				fiscal_norma: [data.fiscal_norma],
				fiscal_leyenda: [data.fiscal_leyenda],
			});
		});
	}

	/**
	 * Metodo que permite elegir al usuario eliminar el registro, cuando
	 * selecciona si ejecuta un segundo metodo que hace la consulta en el API.
	 * @param id ID de la etiqueta a eliminar.
	 */
	public btn_BorrarComplemento(id) {
		this.id_complemento_fiscal = id;
		this.UtilComponent = DialogUtility.confirm({
			title:
				'Información Arcarius' +
				'[Alerta]' +
				' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
			content: '<h6><b>¿Estas seguro de eliminar este registro?</b></h6>',
			okButton: { text: 'Si', click: this.btn_BorrarComplemento2.bind(this) },
			cancelButton: { text: 'No' },
			showCloseIcon: true,
			closeOnEscape: true,
			animationSettings: { effect: 'Zoom' },
		});
	}

	/**
	 * Metodo que va de la mano con el de "btn_BorrarComplemento(id)", este metodo
	 *  hace una consulta al API con el cual va a eliminar el registro
	 * con la id que se esta seleccionando.
	 */
	public btn_BorrarComplemento2: EmitType<object> = () => {
		this.http.post(`${environment.base_url}/venta/documentosventa/eliminarComplementoFiscal/${this.id_complemento_fiscal}`, {}).subscribe((respuesta) => {
			if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
				DialogUtility.alert({
					title: 'Informacion Arcarius',
					content: JSON.parse(JSON.stringify(respuesta)).mensaje,
					showCloseIcon: true,
					closeOnEscape: true,
					animationSettings: { effect: 'Zoom' },
				});
				this.servicio.cargaComplementosFiscales(this.formulario.controls['id'].value).subscribe((resp) => {
					this.tabla_complementosFiscales = JSON.parse(
						JSON.stringify(resp)
					).respuesta;
				});
			} else {
				DialogUtility.alert({
					title: 'Informacion Arcarius [Problemas]',
					content: JSON.parse(JSON.stringify(respuesta)).mensaje,
					showCloseIcon: true,
					closeOnEscape: true,
					animationSettings: { effect: 'Zoom' },
				});
			}
			this.UtilComponent.hide();
			this.id_complemento_fiscal = -1;
		});
	};

	btn_guardarComplementos() {
		if (this.frm_doc_extra_complemento.get('fiscal_disposicion').value == '') {
			DialogUtility.alert({
				title:
					'<span class="dialogo-titulo-error">' +
					'Documento Extra Complemento' +
					' <i style="color: #fff" class="fas fa-ban"></i></span>',
				content: '¡Disposición fiscal no puede quedar vacio!',
				showCloseIcon: true,
				closeOnEscape: true,
				animationSettings: { effect: 'Zoom' },
			});
			return;
		}
		if (this.frm_doc_extra_complemento.get('fiscal_norma').value == '') {
			DialogUtility.alert({
				title:
					'<span class="dialogo-titulo-error">' +
					'Documento Extra Complemento' +
					' <i style="color: #fff" class="fas fa-ban"></i></span>',
				content: '¡Norma fiscal no puede quedar vacio!',
				showCloseIcon: true,
				closeOnEscape: true,
				animationSettings: { effect: 'Zoom' },
			});
			return;
		}
		if (this.frm_doc_extra_complemento.get('fiscal_leyenda').value == '') {
			DialogUtility.alert({
				title:
					'<span class="dialogo-titulo-error">' +
					'Documento Extra Complemento' +
					' <i style="color: #fff" class="fas fa-ban"></i></span>',
				content: '¡Leyenda fiscal no puede quedar vacio!',
				showCloseIcon: true,
				closeOnEscape: true,
				animationSettings: { effect: 'Zoom' },
			});
			return;
		}
		this.servicio.GuardarDocVentaExtraComplemento(
			this.id_complemento_fiscal,
			this.formulario.controls['id'].value,
			this.frm_doc_extra_complemento.get('fiscal_disposicion').value,
			this.frm_doc_extra_complemento.get('fiscal_norma').value,
			this.frm_doc_extra_complemento.get('fiscal_leyenda').value
		)
			.subscribe(resp => {
				if (resp.numero < 0) {
					const modalRef = this.modalService.open(dlgMensajeError);
					modalRef.componentInstance.encabezado = resp.titulo;

				} else {
					const modalRef = this.modalService.open(dlgMensaje);
					modalRef.componentInstance.encabezado = resp.titulo;
					modalRef.componentInstance.contenido = resp.mensaje;
					this.servicio.cargaComplementosFiscales(this.formulario.controls['id'].value).subscribe(resp => {
						this.tabla_complementosFiscales = resp.respuesta;
					});
					this.id_complemento_fiscal = -1;
				}
			});
	}

	//========================================================================================
	//seleccionamos datos y hacemos la validadcion del formulario contiene los campos nesesarios
	//========================================================================================
	public btn_guardarFiscal: EmitType<object> = () => {
		if (this.formulario.controls['id'].value <= 0) {
			DialogUtility.alert({
				title: 'Informacion Arcarius',
				content: 'No has seleccionado un documento',
				showCloseIcon: true,
				closeOnEscape: true,
				animationSettings: { effect: 'Zoom' },
			});
			return;
		}
		const base_url = environment.base_url;
		const body = {
			id_doc_venta_encabezado: this.formulario.controls['id'].value,
			id_sat_tipo_relacion: this.frm_doc_venta_extra.get('id_sat_tipo_relacion')
				.value,
			id_doc_venta_relacion: this.frm_doc_venta_extra.get(
				'id_doc_venta_relacion'
			).value,
		};
		this.http.post(`${base_url}/venta/documentosventa/guardarDocVentaExtraFiscal`, body).subscribe((respuesta) => {
			if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
				DialogUtility.alert({
					title: 'Informacion Arcarius',
					content: JSON.parse(JSON.stringify(respuesta)).mensaje,
					showCloseIcon: true,
					closeOnEscape: true,
					animationSettings: { effect: 'Zoom' },
				});
				this.cargaDocumentoRelacionado(this.formulario.controls['id'].value);
			} else {
				DialogUtility.alert({
					title: 'Informacion Arcarius [Problemas]',
					content: JSON.parse(JSON.stringify(respuesta)).mensaje,
					showCloseIcon: true,
					closeOnEscape: true,
					animationSettings: { effect: 'Zoom' },
				});
			}
		});
	};

	/**
	 * Metodo para cargar grid de los domicilos enlazados al cliente.
	 *  @param id Es la varibale local con la cual se obtiene el cliente actual en el componente.
	 */
	cargaDocumentoRelacionado(id: number) {
		this.servicio.getDocumentoRelacionado(id).subscribe((resp) => {
			if (id <= 0) {
				this.data_documento_relacionado = [];
				return;
			} else {
				this.data_documento_relacionado = JSON.parse(
					JSON.stringify(resp)
				).respuesta;
			}
		});
	}

	/**
	 * Metodo para abrir panel y mostrar informacion del cliente
	 * @param id Identificador del cliente
	 */
	public pnCliente() {
		if (this.formulario.controls['id_cliente'].value > 0) {
			const modalRef = this.offcanvasService.open(ClienteComponent, { position: 'end' });
			modalRef.componentInstance.id_cliente = this.formulario.controls['id_cliente'].value;
		}
	}

	/**
	 * Metodo para abrir panel y mostrar informacion del cliente
	 * @param id Identificador del cliente
	 */
	public pnContacto() {
		if (this.formulario.controls['id_cliente_contacto'].value > 0) {
			this.servicioContacto.get_contacto(this.formulario.controls['id_cliente_contacto'].value).subscribe(resp => {
				const modalRef = this.offcanvasService.open(DlgcontactoComponent, { position: 'end', });
				modalRef.componentInstance.datos = resp.respuesta;
			});
		}
	}

	/**
	 * Metodo para cerrar la ventana modal
	 */
	public close() {
		this.offcanvasService.dismiss();
	}

	/**
	 * Metodo para abrir menu popup
	 * @param $event Eveno del menu
	 */
	public btnOpciones($event, args: any) {
		this.id = args.Id;
		this.menuOpciones.open($event);
	}

    /**
     * Metodo para abrir la ventana de variables
     */
    public mnVariable(){
        const modalRef = this.modalService.open(VariableComponent);
		modalRef.componentInstance.id = this.formulario.controls['id'].value;
    }

	/**
	 * Metodo para elegir el documento al cual deseamos convertir el documento actual
	 */
	public mnConvertir() {
		if (this.existeEncabezado()) {
			const modalRef = this.modalService.open(dlgDocumentos);
			modalRef.componentInstance.encabezado = "Documentos";
			modalRef.componentInstance.contenido = "Seleccionar documento";
			modalRef.componentInstance.id = this.formulario.controls['id_doc_tipo'].value;
			modalRef.componentInstance.carga();
			modalRef.componentInstance.documento.subscribe(($event) => {
				if ($event > 0) {
					this.servicio.setConvertir(this.formulario.controls['id'].value, $event).subscribe(resp => {
						if (resp.numero > 0) {
							const modalRef = this.modalService.open(dlgMensaje);
							modalRef.componentInstance.encabezado = resp.titulo;
							modalRef.componentInstance.contenido = resp.mensaje + '<br/>ID: ' + resp.numero;
						} else {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = resp.titulo;
							modalRef.componentInstance.contenido = resp.mensaje;
						}
					},
						(reason) => {
							const modalRef = this.modalService.open(dlgMensajeError);
							modalRef.componentInstance.encabezado = 'Documento Venta';
							modalRef.componentInstance.contenido = reason.error.mensaje;
						});
				}
			}, (reason) => {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = 'Documento Venta';
				modalRef.componentInstance.contenido = reason;
			});
		}
	}

}
