import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RegistroslogsService {

	/*
	   * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
	   * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
	   */
	private ruta: string = `${environment.base_url}/logs/registros`;

	constructor(
		private http: HttpClient,
		private errores: ErroresService
	) { }


	/* Obtenemos la datos de la API
	* @param referencia Informacion adicional para filtrar la busqueda
	* @returns data en estructura JSON proporcionada de manera directa por la API
	*/
	public getDatos(tabla: string, id: number): Observable<any> {
		return this.http.get<any>(`${this.ruta}/getDatos/${tabla}/${id}`, { });
	}


}
