<block-ui>
</block-ui>
<div class="modal-header">
    <h4 class="modal-title encabezado">{{encabezado}}</h4>
</div>
<div class="modal-body">
    <p class="contenido">{{contenido}}</p>
    <table class="table table-bordered table-hover table-inverse table-responsive">
        <thead class="thead-inverse">
            <tr>
                <th></th>
                <th>Nombre</th>
                <th>Observaciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td>
                    <button class="btn btn-descarga-sm" style="margin-left: 10px;" (click)="btnDescarga(item.id, item.nombre)"></button>
                    <button *ngIf="modoEdicion" class="btn btn-borrar-sm " style="margin-left: 10px;" (click)="MsjEliminarArchivo(item.id)" title="Eliminar Archivo"></button>
                </td>
                <td scope="row">{{item.nombre}}</td>
                <td scope="row">{{item.Observaciones}}</td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="modoEdicion" id='droparea' class="area">
        Arrastrar y Soltar aqui tu archivo o dar click
        <a href="" id="browse" (click)='browseClick()'><u>Aquí</u></a> para buscar el archivo.
        <ejs-uploader id="archivoCarga" [autoUpload]='false' [dropArea]='archivoArea' multiple='false' (selected)="selected($event)">
            <ng-template #template let-data>
                <span class="fileListwrapper">
                    <span class="icon template-icons sf-icon-{{data.type}}"></span>
                <span class="name file-name">{{data.name}}
                        ({{data.size}}bytes)</span>
                <span class="upload-status">{{data.status}}</span>
                </span>
                <span class="e-icons e-file-remove-btn" title="Remove"></span>
            </ng-template>
        </ejs-uploader>
    </div>

</div>
<div class="modal-footer piepagina">
    <button class="btn boton" aria-label="Close" (click)="activeModal.close('-1')">
        Cerrar
    </button>
</div>
