import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OneSignal } from 'onesignal-ngx';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Arcarius ERP 10.0';

	constructor(
		translate: TranslateService,
		private oneSignal: OneSignal
	) {
		this.oneSignal.init({
			appId: "5973d2ac-ac4e-4d5b-888f-c0c8a2c6f714",
			safari_web_id: "web.onesignal.auto.4ddec2dc-5c48-40c7-bde8-da7159bee241",
			notifyButton: {
				enable: true,
			},
		}).then(() => {
			console.log('Conectado a notificaciones Push');
		});

		this.oneSignal.Notifications.addEventListener('click', (event) => {
			console.log("The notification was clicked!", event);
		});

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.addLangs(['en', 'es']);
		translate.setDefaultLang('es');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
	}

	ngOnInit() { }

}