<div class="modal-header">
    <h5 class="modal-title">{{"Titulo.CorreoPlantillas" | translate}}</h5>
    <button class="btn-close" aria-label="Close" (click)="close(-1)"></button>
</div>
<div class="modal-body">
    <img src="/assets/img/spinner.gif" style="display: block; margin-left: auto; margin-right: auto;"
        *ngIf="this.trabajando">
    <div class="table-responsive" *ngIf="!this.trabajando">
        <table *ngIf="this.data" class="table table-hover table-bordered">
            <thead>
                <th style="width: 75px;"></th>
                <th>{{"General.Descripcion" | translate}}</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.data" (click)="btnSeleccion(item.id)">
                    <td>
                        <div>
                            <button class="btn btn-aceptar" (click)="btnSeleccion(item.id)"></button>
                        </div>
                    </td>
                    <td>
                        {{item.descripcion}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-cerrar" aria-label="Close" (click)="close(-1)">
        {{"Boton.Cerrar" | translate}}
    </button>
</div>