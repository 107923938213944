import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class catalogoContableService {

  constructor(private http: HttpClient) { }


  /**
   * Metodo para obtener catalogo contable de manera completa
   * @returns
   */
  public getCatalogo(id_padre: number): Observable<any> {
    let parametros: HttpParams = new HttpParams().append("id_padre", String(id_padre));
    return this.http.get<any>(`${environment.base_url}/contabilidad/catalogo/getCatalogo`, { params: parametros });
  }

  /**
   * Metodo para buscar cuenta del catalogo contable
   * @returns
   */
  public getCatalogoBuscar(referencia: string, afectable: boolean): Observable<any> {
    let parametros: HttpParams = new HttpParams()
      .append("referencia", referencia)
      .append("afectable", afectable);
    return this.http.get<any>(`${environment.base_url}/contabilidad/catalogo/getCatalogoBuscar`, { params: parametros });
  }

  /**
   * Metodo para ajustar cuenta contable
   * @param parametros
   * @returns
   */
  public setCuenta(parametros: any): any {
    return this.http.post(`${environment.base_url}/contabilidad/catalogo/setCuenta`, parametros);
  }


}
