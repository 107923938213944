<div class="modal-header">
    <h5 class="modal-title"> {{"General.Variable" | translate}} </h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

    <div class="table-responsive">
        <form [formGroup]="this.formulario">
            <div formArrayName="detalles">
                <div *ngFor="let detalle of detalles.controls; let i = index" [formGroupName]="i"
                    class="product-item">
                    <div class="row">
                        <div class="col-md-4" style="padding-left: 15px; padding-right: 0px;">
                            <input formControlName="id" type="hidden" />
                            <input formControlName="descripcion" type="text" style="width: 100%;" />
                        </div>
                        <div class="col-md-8" style="padding: 0;">
                            <input class="form-control" formControlName="valor" type="text" style="width: 100%; text-align: left;"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>
<div class="modal-footer">
    <button class="btn btn-guardar" (click)="btnGuardar()">
        {{"Boton.Guardar" | translate}}
    </button>
    <button class="btn btn-cerrar" aria-label="Close" (click)="close()">
        {{"Boton.Crrar" | translate}}
    </button>
</div>