import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MensajeriaService {

    /**
     * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
     * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
     */
    private ruta: string = `${environment.base_url}/rh_gestion/mensajeriawa`;

    constructor(
        private http: HttpClient
    ) { }


    /**
     * Obtenemos la datos de la API
     * @param referencia Informacion adicional para filtrar la busqueda
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_wa_mensajes(id_cliente_contacto: number, id: number): Observable<any> {
        let parametros = new HttpParams().append('id_cliente_contacto', id_cliente_contacto).append('id', id);
        return this.http.get<any>(`${this.ruta}/get_wa_mensajes`, { params: parametros });
    }

    /**
     * Obtenemos listado de contactos de clientes
     * @param referencia Informacion adicional para filtrar la busqueda
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_cliente_contactos(referencia: string): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/get_cliente_contactos`, { params: parametros });
    }

    /**
     * Obtenemos listado de canales para WA
     * @param referencia Informacion adicional para filtrar la busqueda
     * @returns data en estructura JSON proporcionada de manera directa por la API
     */
    public get_wa_canales(referencia: string): Observable<any> {
        let parametros = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${this.ruta}/get_wa_canales`, { params: parametros });
    }



    /**
     * Metodo para mandar data a la API para almacenarla en la DB
     * @param modelo Objeto el cual contiene estructura e informacion para ser almacenada en la DB
     * es importante que la estructura del objeto (modelo) sea identica dentro de Angular y de la API
     * @returns
     */
    public set_wa_mensaje(modelo: any): Observable<any> {
        return this.http.post(`${this.ruta}/set_wa_mensaje`, modelo);
    }

    public getDescarga(id: number): Observable<any> {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.get<any>(`${this.ruta}/descarga/${id}`, { responseType: resonseType, headers: headers });
    }

    /**
     * Metodo para guardar los archivos que se envian por mensaje
     * @param formulario 
     * @returns 
     */
    public setArchivo(id: number, formulario: any): Observable<any> {
        return this.http.post(`${this.ruta}/setArchivo/${id}`, formulario);
    }

}
