import { Component, inject, Input, OnInit } from '@angular/core';
import { VentaService } from '../../services/venta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { var_encabezado } from '../../models/var_encabezado.model';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';

@Component({
    selector: 'app-variable',
    templateUrl: './variable.component.html',
    styleUrls: ['./variable.component.scss']
})
export class VariableComponent implements OnInit {

    private servicio = inject(VentaService);
    private modalService = inject(NgbModal);
    private fb = inject(FormBuilder);
    public data: any;
    public formulario: FormGroup;
    @Input() id: number = -1;

    constructor() {
        this.formulario = this.fb.group(new var_encabezado());
        this.formulario.addControl('detalles', this.fb.array([]));
    }


    ngOnInit(): void {
        this.formulario = this.fb.group(new var_encabezado());
        this.formulario.addControl('detalles', this.fb.array([]));
        this.servicio.getVariables(this.id).subscribe(resp => {
            if (resp.numero > 0) {
                this.data = resp.respuesta;
                let registros: FormArray = this.formulario.get('detalles') as FormArray;
                this.data.forEach((element: { id: any; descripcion: any; valor: string }) => {
                    const group = this.fb.group({
                        id: element.id,
                        descripcion: element.descripcion,
                        valor: element.valor
                    });
                    group.get('descripcion')?.disable();
                    registros.push(group);
                });
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }
        });
    }

    // Getter para acceder al FormArray desde la plantilla
    get detalles(): FormArray {
        return this.formulario.get('detalles') as FormArray;
    }

    /**
     * Metodo para guardar los cambios realizados en las variables
     */
    public btnGuardar() {
        this.servicio.setVariables(this.id, this.formulario.value).subscribe(resp => {
            if (resp.numero > 0) {
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }
        });

    }


    public close() {
        this.modalService.dismissAll();
    }
}
