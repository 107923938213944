export class MdlContacto
{
	public id: number = -1 ;
    public nombre: string = "";
    public cliente: string = "";
    public id_cliente: number = -1 ;
    public cargo: string = "";
    public departamento: string = "";
    public cumple: Date = new Date();
    public tel_movil: string = "";
    public tel_oficina: string = "";
    public tel_fax: string = "";cli
    public tel_casa: string = "";
    public tel_radio: string = "";
    public correo: string = "";
    public correo_alterno: string = "";
    public calle: string = "";
    public num_ext: string = "";
    public num_int: string = "";
    public colonia: string = "";
    public codigo: string = "";
    public id_municipio: number = -1 ;
    public observaciones: string = "";
    public clave: string = "";
    public id_estado: number = -1 ;
    public id_pais: number = -1 ;
    public id_crm_contacto_tipo: number = -1 ;
    public id_rh_empleado: number = -1 ;
    public twitter: string = "";
    public whatsapp: string = "";
    public id_crm_relacion: number = -1 ;
    public fecha: Date = new Date();
    public id_crm_contacto_clasificacion: number = -1 ;
    public id_cliente_origen: number = -1 ;
    public id_cliente_potencial: number = -1 ;
    public id_crm_contacto_nivel: number = -1 ;
    public usuario: string = "";
    public password: string = "";
    public sitioweb: string = "";
    public id_rh_estado_civil: number = -1 ;
    //public : ]byte imagen;
    public id_sys_idioma: number = 153 ;
    public id_crm_campana: number = -1 ;
}
