<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-body">
                    <h4 style="padding: 0px 10px">{{"Tutilo.ContactoInformacion" | translate}}</h4>
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="formulario">
                                <div class="row">
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Nombre" | translate}}</label>
                                        <input type="text" required class="form-control" id='nombre'
                                            formControlName="nombre">
                                        <small *ngIf="nombrenNoValido" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
                                    </div>
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Telefono" | translate}}</label>
                                        <input type="text" required class="form-control" id='tel_movil'
                                            formControlName="tel_movil">
                                        <small *ngIf="TelefonoNoValido" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="correo">{{"General.Correo" | translate}}</label>
                                        <input type="email" required class="form-control" id="correo"
                                            formControlName='correo'>
                                        <small *ngIf="CorreoNoValido" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="footer">
                        <button type="button" class="btn btn-guardar" (click)="Guardar()"> {{"Boton.Guardar" | translate}}</button>
                    </div>
                </div>

                <div class="card-body">
                    <h4 style="padding: 0px 10px">{{"Tutilo.ContactoInformacionExtra" | translate}}</h4>
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="formulario">
                                <div class="row">
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Cliente" | translate}}</label>
                                        <div class="input-group">
                                            <button class="btn btn-buscar-interno feedback-form-control"
                                                (click)="btnBuscarCliente()">
                                            </button>
                                            <input type="hidden" class="form-control" formControlName="id_cliente">
                                            <input type="text" class="form-control feedback-input"
                                                formControlName="cliente">
                                        </div>
                                    </div>

                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Departamento" | translate}}</label>
                                        <input type="text" class="form-control" id='departamento'
                                            formControlName="departamento">
                                    </div>
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Password" | translate}}</label>
                                        <input type="text" class="form-control" id='clave' formControlName="clave">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Puesto" | translate}}</label>
                                        <input type="text" class="form-control" id='cargo' formControlName="cargo">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="correo_alterno">{{"General.CoreoSecundario" | translate}}</label>
                                        <input type="email" class="form-control " id="correo_alterno"
                                            formControlName='correo_alterno'>
                                    </div>
                                    <div class='form-group col-md-4'>
                                        <label for="cumple">{{"General.FechaNacimiento" | translate}}</label>
                                        <ejs-datepicker format='dd/MM/yyyy' formControlName="cumple"></ejs-datepicker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='form-group col-md-4'>
                                        <label>{{"General.Telefono" | translate}}</label>
                                        <input type="text" class="form-control" id='tel_oficina'
                                            formControlName="tel_oficina">
                                    </div>
                                    <div class='form-group col-md-4'>
                                        <label>WhatsApp:</label>
                                        <input type="text" class="form-control" id='whatsapp'
                                            formControlName="whatsapp">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='form-group  col-md-4'>
                                        <label>{{"General.Colonia" | translate}}</label>
                                        <input type="text" class="form-control" id='colonia' formControlName="colonia">
                                    </div>
                                    <div class='form-group  col-md-4'>
                                        <label>{{"General.Calle" | translate}}</label>
                                        <input type="text" class="form-control" id='calle' formControlName="calle">
                                    </div>
                                    <div class='form-group  col-md-2'>
                                        <label>{{"General.NumExt" | translate}}</label>
                                        <input type="text" class="form-control" id='num_ext' formControlName="num_ext">
                                    </div>
                                    <div class='form-group  col-md-2'>
                                        <label>{{"General.NumInt" | translate}}</label>
                                        <input type="text" class="form-control" id='num_int' formControlName="num_int">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='form-group col-md-2'>
                                        <label>{{"General.CodigoPostal" | translate}}</label>
                                        <input type="text" class="form-control" id='codigo' formControlName="codigo">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label>{{"General.Pais" | translate}}</label>
                                        <select class="form-select" (blur)="getEstados()" formControlName="id_pais">
                                            <option *ngFor="let item of data_pais" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>{{"General.Estado" | translate}}</label>
                                        <select class="form-select" (blur)="getMunicipios()"
                                            formControlName="id_estado">
                                            <option *ngFor="let item of data_estado" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>{{"General.Municipio" | translate}}</label>
                                        <select class="form-select" formControlName="id_municipio">
                                            <option *ngFor="let item of data_municipio" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label>{{"General.Clasificacion" | translate}}</label>
                                        <select class="form-select" formControlName="id_crm_contacto_clasificacion">
                                            <option *ngFor="let item of data_clasificacion" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-md-2">
                                        <label>{{"General.Idioma" | translate}}</label>
                                        <select class="form-select" formControlName="id_sys_idioma">
                                            <option *ngFor="let item of data_idioma" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>{{"General.Origen" | translate}}</label>
                                        <select class="form-select" formControlName="id_cliente_origen">
                                            <option *ngFor="let item of data_origen" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>{{"General.Nivel" | translate}}</label>
                                        <select class="form-select" formControlName="id_crm_contacto_nivel">
                                            <option *ngFor="let item of data_nivel" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>{{"General.Campaña" | translate}}</label>
                                        <select class="form-select" formControlName="id_crm_campana">
                                            <option *ngFor="let item of data_campana" value="{{item.id}}">
                                                {{item.descripcion}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="tftfsitioweb" class="form-label">{{"General.SitioWeb" | translate}}</label>
                                        <input type="text" class="form-control" id="tfsitioweb"
                                            formControlName="sitioweb">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="footer">
                        <button type="button" class="btn btn-guardar" (click)="Guardar()"> {{"Boton.Guardar" |
                            translate}}</button>
                        <button type="button" class="btn btn-correo" (click)="btnCorreo()"> {{"Boton.Correo" |
                            translate}}</button>
                        <button type="button" class="btn btn-correo" (click)="btnActividad()"> Actividad</button>
                        <button type="button" class="btn btn-guardar" (click)="btnWhatsapp()" disabled>Whatsapp</button>
                        <button type="button" class="btn btn-guardar" (click)="btnSMS()" disabled> SMS</button>
                    </div>
                </div>
                <pnTimeLine [id]="this.datos.contacto.id"></pnTimeLine>
            </div>
        </div>
        <div class="col-md-3">
            <div class="history-tl-container" style="height: calc(100vh - 255px); overflow: auto;">
                <ul class="tl" *ngIf="dataComentarios">
                    <li *ngFor="let comentario of dataComentarios" class="tl-item" ng-repeat="item in retailer_history">
                        <div class="timestamp">
                            {{ comentario.fecha | date : "dd/MM/yyyy HH:mm" }}
                        </div>
                        <div class="item-title">{{ comentario.nombre }}</div>
                        <div class="item-detail" [innerHTML]="comentario.comentario | safeHtml"></div>
                    </li>
                </ul>
            </div>
            <div class="card" style="margin-bottom: 0 !important;">
                <div class="card-detail">
                    <!-- <ejs-richtexteditor #tfComentarios [toolbarSettings]='tools' [insertImageSettings]='imageSettings' height="150px"></ejs-richtexteditor> -->
                    <quill-editor #tfComentarios id="tfComentaiios" style="width: 100%"
                        [modules]="modules"></quill-editor>
                </div>
                <div class="footer">
                    <button class="btn btn-guardar" style="float: right" (click)="btnComentarios()"> {{"Boton.Guardar" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</section>