import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { DlgClienteComponent } from 'src/app/components/dlgCliente/dlgCliente.component';
import { DlgCuentasComponent } from 'src/app/components/dlgcuentas/dlgcuentas.component';
import { environment } from 'src/environments/environment';
import { EmitType } from '@syncfusion/ej2-base';
import { RegistroClienteService } from '../../services/registroCliente.service';
import { MdlRegistroClientes } from '../models/MdlRegistroClientes';
import { MdlRegistroSucursal } from '../models/MdlRegistroSucursal';
import { MdlRegistroCorreo } from '../models/MdlRegistroCorreo';
import { util } from 'src/app/shared/util';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { dlgCuentaContableComponent } from 'src/app/components/dlgCuentaContable/dlgCuentaContable.component';
import { DlgArchivoComponent } from '../components/dlgarchivo/dlgArchivo.component';

@Component({
    selector: 'app-cliente',
    templateUrl: './cliente.component.html',
    styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit {

    @Input() id_cliente: number = -1;
    public data: any;

    @ViewChild('estatus') estatus: DropDownList;
    @ViewChild('clasificacion') clasificacion: DropDownList;
    @ViewChild('origen') origen: DropDownList;
    @ViewChild('fecha_ingreso') fecha_ingreso: DatePicker;
    @ViewChild('dlgClientePotencial') dlgClientePotencial: DialogComponent;
    @ViewChild('dlgSucursalPadre') dlgSucursalPadre: DialogComponent;
    @ViewChild('dlgRegistroSucursal') dlgRegistroSucursal: DialogComponent;
    @ViewChild('dlgRegistroCorrreo') dlgRegistroCorrreo: DialogComponent;
    @ViewChild('referencia') referencia: ElementRef;

    // VARIABLES PARA CUENTAS CONTABLES
    @ViewChild('dlgCuentas') dlgCuentas: DlgCuentasComponent;
    @ViewChild('btnCuentas') btnCuentas: ElementRef;
    @ViewChild('id_con_catalogo') id_con_catalogo: ElementRef;
    @ViewChild('numcuentaCatalogo') numcuentaCatalogo: ElementRef;
    @ViewChild('cuentaCatalogo') cuentaCatalogo: ElementRef;
    @ViewChild('id_con_catalogo_anticipo') id_con_catalogo_anticipo: ElementRef;
    @ViewChild('numcuentaAnticipo') numcuentaAnticipo: ElementRef;
    @ViewChild('cuentaAnticipo') cuentaAnticipo: ElementRef;

    // VARIABLES PARA CLIENTES
    @ViewChild('dlgCliente') dlgCliente: DlgClienteComponent;
    @ViewChild('id_cliente_potencial') id_cliente_potencial: ElementRef;
    @ViewChild('clientePotencial') clientePotencial: ElementRef;
    @ViewChild('id_cliente_Relacion') id_cliente_Relacion: ElementRef;
    @ViewChild('clienteRelacion') clienteRelacion: ElementRef;

    // VARIABLES PARA SUCURSAL
    public id_domicilio: number = -1;
    public id_domicilio_delete: number = -1;
    public data_pais: any;
    public data_estado: any;
    public data_localidad: any;
    public data_colonia: any;
    public data_municipio: any;
    public data_ejecutivo: any;
    @ViewChild('codigopostal') codigopostal: ElementRef;
    @ViewChild('pais') pais: DropDownList;
    @ViewChild('estado') estado: DropDownList;
    @ViewChild('colonia') colonia: DropDownList;
    @ViewChild('localidad') localidad: DropDownList;
    @ViewChild('municipio') municipio: DropDownList;
    @ViewChild('ejecutivo') ejecutivo: DropDownList;

    // VARIABLES PARA CORREO
    public id_correo: number = -1;
    // mi sucursal filtrado para listbox
    public InfoData: any;

    public formulario: UntypedFormGroup;
    public frm_sucursal: UntypedFormGroup;
    public frm_correo: UntypedFormGroup;
    public data_sucursal: any;
    public data__suc_correo: any;
    public id_area: number = 0;
    public data_estatus: any;
    public data_clasificacion: any;
    public data_origen: any;
    public data_regimen: any;
    public data_clienteP: any;
    public fields: Object = { text: 'descripcion', value: 'id' };
    public formatoFecha: string = 'dd/MM/yyyy';
    UtilComponent: any;

    constructor(
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private registra_service: RegistroClienteService,
        private modalService: NgbModal,
        public offcanvasService: NgbOffcanvas,
        private router: Router,
        private utilerias: util
    ) {
        this.registra_service.Registra_Estatus().subscribe((resp) => {
            this.data_estatus = resp.respuesta;
        });
        this.registra_service.Registra_Clasificacion().subscribe((resp) => {
            this.data_clasificacion = resp.respuesta;
        });
        this.registra_service.Registra_Origen().subscribe((resp) => {
            this.data_origen = resp.respuesta;
        });
    }

    ngOnInit(): void {
        this.formulario = this.fb.group(new MdlRegistroClientes());
        this.frm_sucursal = this.fb.group(new MdlRegistroSucursal());
        this.frm_correo = this.fb.group(new MdlRegistroCorreo());
        if (this.id_cliente < 0) {
            this.btnNuevo();
        } else {
            this.registra_service.getClienteIndividual(this.id_cliente).subscribe((resp) => {
                this.formulario = this.fb.group(JSON.parse(JSON.stringify(resp.respuesta), this.utilerias.parseFecha) as MdlRegistroClientes);
                this.BuscarRegimenFiscal(resp.respuesta.rfc);
                /**
                 * Con base al ID que tiene el input para mostrar las cuentas
                 * le asignamos los valores para que sustituya el valor vacio de esos elementos INPUT
                 *
                 */
                (<HTMLInputElement>(
                    document.getElementById('numcuentaCatalogo')
                )).value = resp.respuesta.descripcion_contable;
                (<HTMLInputElement>document.getElementById('cuentaCatalogo')).value =
                    resp.respuesta.con_contable;
                (<HTMLInputElement>(
                    document.getElementById('numcuentaAnticipo')
                )).value = resp.respuesta.descripcion_anticipo;
                (<HTMLInputElement>document.getElementById('cuentaAnticipo')).value =
                    resp.respuesta.con_anticipo;
                (<HTMLInputElement>(
                    document.getElementById('clientePotencial')
                )).value = resp.respuesta.cliente_potencial;
                (<HTMLInputElement>document.getElementById('clienteRelacion')).value =
                    resp.respuesta.cliente_relacion;
            });
        }
    }

    /**
     * Metodo para regresar al listado de clientes
     */
    btnListado(): void {
        this.router.navigate(['/sdventas/clientes/listado']);
    }

    /**
     * Metodo para crear el formulario vacio para el registro del cliente, en el
     * se requieren 7 datos que son necesarios para el guardado correcto de la
     * información
     *
     */
    btnNuevo() {
        this.id_cliente = -1;
        this.formulario = this.fb.group({
            id: [-1],
            codigo: [''],
            nombre: ['', Validators.required],
            paterno: [''],
            materno: [''],
            correo: [''],
            tel_movil: [''],
            tel_oficina: [''],
            tel_fax: [''],
            estatus: ['', Validators.required],
            id_cliente_clasifica: ['', Validators.required],
            id_sat_regimenfiscal: [-1, Validators.required],
            fecha_ingreso: [new Date(), Validators.required],
            rfc: ['', Validators.required],
            id_con_catalogo: ['', Validators.required],
            id_con_catalogo_anticipo: [''],
            id_cliente_origen: ['', Validators.required],
            referencia_bancaria: [''],
            twitter: [''],
            whatsapp: [''],
            id_fiscal: [''],
            id_cliente_potencial: [-1],
            id_cliente: [-1],
        });
        this.formulario.controls['estatus'].setValue(null);
        this.formulario.controls['id_cliente_clasifica'].setValue(null);
        this.formulario.controls['id_cliente_origen'].setValue(null);
        // this.formulario.controls['id_sat_regimenfiscal'].setValue(null);
        this.numcuentaCatalogo.nativeElement.value = '';
        this.cuentaCatalogo.nativeElement.value = '';
        this.id_con_catalogo_anticipo.nativeElement.value = '';
        this.numcuentaAnticipo.nativeElement.value = '';
        this.cuentaAnticipo.nativeElement.value = '';
        this.id_cliente_potencial.nativeElement.value = '';
        this.clientePotencial.nativeElement.value = '';
        this.id_cliente_Relacion.nativeElement.value = '';
        this.clienteRelacion.nativeElement.value = '';
    }
    /**
     * Muestra el listado de regimenes fiscales con base al rfc que se esta
     * registrando en el formulario.
     *  @param referencia RFC del cliente
     */
    BuscarRegimenFiscal(referencia: string) {
        this.registra_service.buscarRegimenFiscal(referencia).subscribe((resp) => {
            this.data_regimen = JSON.parse(JSON.stringify(resp)).respuesta;
        });
    }

    /**
     * Metodo para cargar el dropdownlist de regimen fiscal.
     * @param rfc -> Es la información actual del input.
     */
    rfcCambios(rfc: string): void {
        this.registra_service.buscarRegimenFiscal(rfc).subscribe((resp) => {
            this.data_regimen = resp.respuesta;
        });
    }

    /**
     * Metodos para asignar los valores de los DropDownList al
     * momento que hay un cambio en ellos.
     *
     */
    onChangeEstatus(args) {
        this.formulario.controls['estatus'].setValue(this.estatus.value);
    }
    onChangeClasifica(args) {
        this.formulario.controls['id_cliente_clasifica'].setValue(
            this.clasificacion.value
        );
    }
    onChangeOrigen(args) {
        this.formulario.controls['id_cliente_origen'].setValue(this.origen.value);
    }

    /**
     * Con este metodo se manda un numero al dar click que se le asigna
     * a una variable que nos servirá para el metodo "cargaCuentaContable()"
     * para validar en que input colocar la información
     *
     */
    public btnCuentaContable(area: number) {
        const modalRef = this.modalService.open(dlgCuentaContableComponent);
        modalRef.componentInstance.cuenta.subscribe(($event) => {
            console.log($event);
            //AQUI SE OBTIENEN LOS VALORES DE LA BASE DE DATOS Y LOS SUSTITUIMOS EN LOS INPUTS
            if (area == 1) {
                this.numcuentaCatalogo.nativeElement.value = [$event.descripcion];
                this.cuentaCatalogo.nativeElement.value = [$event.cuenta];
                this.id_con_catalogo.nativeElement.value = $event.id;
                this.formulario.controls['id_con_catalogo'].setValue($event.id);
            } else if (area == 2) {
                this.numcuentaAnticipo.nativeElement.value = [$event.descripcion];
                this.cuentaAnticipo.nativeElement.value = [$event.cuenta];
                this.id_con_catalogo_anticipo.nativeElement.value = $event.id;
                this.formulario.controls['id_con_catalogo_anticipo'].setValue($event.id);
            }
        });
    }

    /**
     * Se carga el cliente con base al que se selecciono en la busqueda y
     * se sustituyen los valores vacios por los que contiene la data.
     *  @param args Es la data que contiene la id y nombre del cliente
     */
    public cargaCliente(args) {
        if (args === null) {
            return;
        }
        this.id_cliente_Relacion.nativeElement.value = args.id;
        this.formulario.controls['id_cliente'].setValue(args.id);
        this.clienteRelacion.nativeElement.value = args.descripcion;
        this.dlgCliente.hide();
    }

    /**
     * Vacia el input del referencia para busqueda del cliente potencial
     * y abre el dialogo.
     *  @param this.referencia Nombre con cual filtrar la busqueda.
     */
    btnAbrirBuscador() {
        this.referencia.nativeElement.value = '';
        this.dlgClientePotencial.show();
    }

    /**
     * Muestra una busqueda de un cliente potencial.
     *  @param this.referencia Nombre con cual filtrar la busqueda.
     */
    btnBuscarClientePotencial() {
        this.registra_service
            .buscarClientePotencial(this.referencia.nativeElement.value)
            .subscribe((resp) => {
                this.data_clienteP = JSON.parse(JSON.stringify(resp)).respuesta;
            });
    }

    /**
     * Metodo para sustituir los input con base al cliente potencial
     * seleccionado.
     *  @param args Data del cliente que contiene la id y el nombre.
     */
    public onRowSelected(args: any) {
        this.id_cliente_potencial.nativeElement.value = args.data.id;
        this.formulario.controls['id_cliente_potencial'].setValue(args.data.id);
        this.clientePotencial.nativeElement.value = args.data.nombre;
        this.dlgClientePotencial.hide();
    }
    /**
     * Metodo para validar que ningun formControlName sea invalido.
     */
    public revisa() {
        if (this.formulario.invalid) {
            return Object.values(this.formulario.controls).forEach((control) => {
                control.markAsTouched();
            });
        }
    }
    public revisaSucursal() {
        if (this.frm_sucursal.invalid) {
            return Object.values(this.frm_sucursal.controls).forEach((control) => {
                control.markAsTouched();
            });
        }
    }

    /**
     * Metodos de validaciones en el cual valida que los datos del formulario (REGISTRO CLIENTE)
     * esten completos o no esten vacios.
     */
    get nombreNoValida() {
        return (
            this.formulario.get('nombre').invalid &&
            this.formulario.get('nombre').touched
        );
    }
    get rfcNoValida() {
        return (
            this.formulario.get('rfc').invalid && this.formulario.get('rfc').touched
        );
    }
    get cuentaContNoValida() {
        return (
            this.formulario.get('id_con_catalogo').invalid &&
            this.formulario.get('id_con_catalogo').touched
        );
    }
    get fechaNoValida() {
        return (
            this.formulario.get('fecha_ingreso').invalid &&
            this.formulario.get('fecha_ingreso').touched
        );
    }
    get estatusNoValida() {
        return (
            this.formulario.get('estatus').invalid &&
            this.formulario.get('estatus').touched
        );
    }
    get clasificacionNoValida() {
        return (
            this.formulario.get('id_cliente_clasifica').invalid &&
            this.formulario.get('id_cliente_clasifica').touched
        );
    }
    get origenNoValida() {
        return (
            this.formulario.get('id_cliente_origen').invalid &&
            this.formulario.get('id_cliente_origen').touched
        );
    }
    get regimenNoValida() {
        return (
            this.formulario.get('id_sat_regimenfiscal').invalid &&
            this.formulario.get('id_sat_regimenfiscal').touched
        );
    }

    /**
     * Metodos de validaciones en el cual valida que los datos del formulario (REGISTRO SUCURSAL)
     * esten completos o no esten vacios.
     */
    get sucursaloNoValida() {
        return (
            this.frm_sucursal.get('nombre').invalid &&
            this.frm_sucursal.get('nombre').touched
        );
    }
    get calleNoValida() {
        return (
            this.frm_sucursal.get('calle').invalid &&
            this.frm_sucursal.get('calle').touched
        );
    }
    get localidadNoValida() {
        return (
            this.frm_sucursal.get('id_localidad').invalid &&
            this.frm_sucursal.get('id_localidad').touched
        );
    }
    get coloniaNoValida() {
        return (
            this.frm_sucursal.get('id_colonia').invalid &&
            this.frm_sucursal.get('id_colonia').touched
        );
    }
    get ejecutivoNoValida() {
        return (
            this.frm_sucursal.get('id_rh_empleado').invalid &&
            this.frm_sucursal.get('id_rh_empleado').touched
        );
    }
    get paisNoValida() {
        return (
            this.frm_sucursal.get('pais').invalid &&
            this.frm_sucursal.get('pais').touched
        );
    }
    get estadoNoValida() {
        return (
            this.frm_sucursal.get('id_estado').invalid &&
            this.frm_sucursal.get('id_estado').touched
        );
    }
    get municipioNoValida() {
        return (
            this.frm_sucursal.get('id_municipio').invalid &&
            this.frm_sucursal.get('id_municipio').touched
        );
    }

    /**
     * Metodo para guardar la información del formulario de registro del cliente
     */
    public btnGuardar(): void {
        this.revisa();
        if (!this.formulario.invalid) {
            this.registra_service
                .guardarCliente(this.formulario.value)
                .subscribe((resp) => {
                    let respuesta = JSON.parse(JSON.stringify(resp));
                    if (respuesta.numero < 0) {
                        DialogUtility.alert({
                            title:
                                '<span class="dialogo-titulo-error">' +
                                respuesta.titulo +
                                ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                            content: JSON.parse(JSON.stringify(resp)).mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    } else {
                        DialogUtility.alert({
                            title:
                                '<span style="color: #28a745">' +
                                respuesta.titulo +
                                ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                            content: JSON.parse(JSON.stringify(resp)).mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                        if (respuesta.mensaje == 'Cliente Agregado') {
                            this.id_cliente = respuesta.numero;
                            this.formulario.controls['id'].setValue(respuesta.numero);
                        }
                        if (respuesta.mensaje == 'Cliente Modificado') {
                            this.id_cliente = respuesta.numero;
                        }
                    }
                });
        }
    }

    btnAbrirSucursalPadre() {
        if (this.id_cliente <= 0) {
            DialogUtility.alert({
                title:
                    '<span class="dialogo-titulo-error">' +
                    'Cliente no valido' +
                    ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content:
                    'No ha seleccionado o creado un cliente correctamente, vuelva a cargar el cliente o cree uno.',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
            return;
        } else {
            this.registra_service.getPaises().subscribe((resp) => {
                this.data_pais = resp.respuesta;
            });
            this.registra_service.getEjecutivo().subscribe((resp) => {
                this.data_ejecutivo = JSON.parse(JSON.stringify(resp)).respuesta;
            });
            this.id_domicilio = -1;
            this.data__suc_correo = [];
            this.cargaDataSucursal();
            this.NuevoFrmDomicilio();
            this.dlgSucursalPadre.show();
        }
    }

    /**
     * Metodo para cargar grid de los domicilos enlazados al cliente.
     *  @param this.id_cliente Es la varibale local con la cual se obtiene el cliente actual en la pagina.
     */
    cargaDataSucursal() {
        this.registra_service
            .getSucursalCliente(this.id_cliente)
            .subscribe((resp) => {
                this.data_sucursal = JSON.parse(JSON.stringify(resp)).respuesta;
            });
    }

    /**
     * Metodo para carga del listado de correos.
     *  @param id Se requerira de un id que es la del domicilio que se selecciona.
     */
    cargaDataSucursalCorreo(id: number) {
        this.registra_service.getSucursalClienteCorreo(id).subscribe((resp) => {
            this.data__suc_correo = JSON.parse(JSON.stringify(resp)).respuesta;
        });
    }

    /**
     * Metodo para asignar valor del id del domicilio seleccionado a una variable
     *  @param args Data del cliente que contiene la id y el nombre.
     */
    public onRowSelectedSucursal(args: any) {
        this.id_domicilio = args.data.id;
        this.cargaDataSucursalCorreo(this.id_domicilio);
    }

    /**
     * Metodo para crear el formulario vacio para el registro del domicilio del cliente.
     *
     */
    btnNuevaSucursal() {
        this.id_domicilio = -1;
        this.dlgRegistroSucursal.show();
        this.NuevoFrmDomicilio();
        this.registra_service.getPaises().subscribe((resp) => {
            this.data_pais = resp.respuesta;
        });
        this.registra_service.getEjecutivo().subscribe((resp) => {
            this.data_ejecutivo = JSON.parse(JSON.stringify(resp)).respuesta;
        });
    }

    NuevoFrmDomicilio() {
        this.frm_sucursal = this.fb.group({
            id: [-1],
            id_cliente: [this.id_cliente],
            calle: ['', Validators.required],
            num_ext: [''],
            num_int: [''],
            id_municipio: ['', Validators.required],
            id_estado: ['', Validators.required],
            cp: [''],
            nombre: ['', Validators.required],
            id_rh_empleado: [''],
            pais: ['', Validators.required],
            id_localidad: ['', Validators.required],
            id_colonia: ['', Validators.required],
        });
        this.codigopostal.nativeElement.value = '';
        this.frm_sucursal.controls['id_rh_empleado'].setValue(null);
        this.frm_sucursal.controls['pais'].setValue(null);
        this.data_colonia = [];
        this.data_estado = [];
        this.data_municipio = [];
        this.data_localidad = [];
    }

    CambioPais(args) {
        this.registra_service.getEstado(args.value).subscribe((resp) => {
            this.data_estado = resp.respuesta;
            if (this.data_estado == '' || this.data_estado == undefined) {
                this.data_estado = [];
                this.data_municipio = [];
                this.data_localidad = [];
            }
        });
    }

    CambioEstado(args) {
        this.registra_service.getMunicipio(Number(args.value)).subscribe((resp) => {
            this.data_municipio = resp.respuesta;
            if (this.data_municipio == '' || this.data_municipio == undefined) {
                this.data_municipio = [];
                this.data_localidad = [];
            }
        });
        this.registra_service.getLocalidad(Number(args.value)).subscribe((resp) => {
            this.data_localidad = resp.respuesta;
            if (this.data_localidad == '' || this.data_localidad == undefined) {
                this.data_municipio = [];
                this.data_localidad = [];
            }
        });
    }

    /**
     * Metodo para cargar el dropdownlist de la colonia.
     * @param codigo -> Es la información actual del input.
     */
    cpCambios(codigo: string): void {
        if (codigo == '') {
            this.data_colonia = [];
        } else {
            this.registra_service.getCP(codigo).subscribe((resp) => {
                this.data_colonia = resp.respuesta;
                //EN CASO DE QUE NO TENGA DATA, MUESTRE VACIO EL DROPDOWNLIST
                if (this.data_colonia == '' || this.data_colonia == undefined) {
                    this.data_colonia = [];
                }
            });
        }
    }

    /**
    * Metodo para cargar el formulario a partir del Numero o identificador de la
    * sucursal seleccionada
    */
    public btnEditarSucursal(id: number): void {
        this.registra_service.getSucursalIndividual(id).subscribe((resp) => {
            this.InfoData = resp.respuesta;
            this.cpCambios(resp.respuesta.cp);
            this.registra_service.getEstado(Number(this.InfoData.id_pais)).subscribe((resp) => {
                if (resp.respuesta == '' || resp.respuesta == undefined) {
                    this.data_estado = [];
                }
                else {
                    this.data_estado = resp.respuesta;
                }
            });
            this.registra_service.getMunicipio(Number(this.InfoData.id_estado)).subscribe((resp) => {
                if (resp.respuesta == '' || resp.respuesta == undefined) {
                    this.data_municipio = [];
                    this.data_localidad = [];
                }
                else { this.data_municipio = resp.respuesta; }
            });
            this.registra_service.getLocalidad(Number(this.InfoData.id_estado)).subscribe((resp) => {
                if (resp.respuesta == '' || resp.respuesta == undefined) {
                    this.data_municipio = [];
                    this.data_localidad = [];
                }
                else { this.data_localidad = resp.respuesta; }
            });
            this.registra_service.getPaises().subscribe((resp) => {
                if (resp.respuesta == '' || resp.respuesta == undefined) {
                    this.data_pais = [];
                }
                else {
                    this.data_pais = resp.respuesta;
                }
            });
            this.registra_service.getEjecutivo().subscribe((resp) => {
                this.data_ejecutivo = JSON.parse(JSON.stringify(resp)).respuesta;
            });
            this.codigopostal.nativeElement.value = resp.respuesta.cp;
            setTimeout(() =>
                this.frm_sucursal = this.fb.group({
                    id: [resp.respuesta.id],
                    id_cliente: [resp.respuesta.id_cliente],
                    calle: [resp.respuesta.calle],
                    colonia: [resp.respuesta.colonia],
                    num_ext: [resp.respuesta.num_ext],
                    num_int: [resp.respuesta.num_int],
                    id_municipio: [resp.respuesta.id_municipio],
                    id_estado: [resp.respuesta.id_estado],
                    nombre: [resp.respuesta.nombre],
                    id_rh_empleado: [resp.respuesta.id_rh_empleado],
                    pais: [resp.respuesta.id_pais],
                    id_localidad: [resp.respuesta.id_localidad],
                    id_colonia: [resp.respuesta.id_colonia]
                })
                , 3000);
            this.dlgRegistroSucursal.show();
        });

    }
    /**
     * Metodo para cargar el formulario a partir del Numero o identificador de la
     * sucursal seleccionada
     */
    public btnEditarCorreo(id: number): void {
        this.registra_service.getCorreoIndividual(id).subscribe((resp) => {
            let frm_correo: MdlRegistroCorreo = resp.respuesta;
            this.frm_correo = this.fb.group(frm_correo);
        });
        this.dlgRegistroCorrreo.show();
    }
    public btnBorrarSucursal(id) {
        this.id_domicilio_delete = id;
        this.UtilComponent = DialogUtility.confirm({
            title:
                'Información Arcarius' +
                '[Alerta]' +
                ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
            content: '<h6><b>¿Estas seguro de eliminar este registro?</b></h6>',
            okButton: { text: 'Si', click: this.btnBorrarSucursal2.bind(this) },
            cancelButton: { text: 'No' },
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
        });
    }

    public btnBorrarSucursal2: EmitType<object> = () => {
        this.http
            .post(
                `${environment.base_url}/venta/cliente/eliminarSucursal/${this.id_domicilio_delete}`,
                {}
            )
            .subscribe((respuesta) => {
                if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
                    DialogUtility.alert({
                        title: 'Informacion Arcarius',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                    this.cargaDataSucursal();
                } else {
                    DialogUtility.alert({
                        title: 'Informacion Arcarius [Problemas]',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                }
                this.UtilComponent.hide();
                this.id_domicilio_delete = -1;
            });
    };

    /**
     * Metodo para guardar la información del formulario de registro del domicilio del cliente
     */
    public btnGuardarSucursal(): void {
        if (this.id_cliente <= 0) {
            DialogUtility.alert({
                title:
                    '<span class="dialogo-titulo-error">' +
                    'Sucursal' +
                    ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content:
                    'Hay un error con el cliente, favor de cargar el cliente nuevamente',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
            return;
        }
        this.revisaSucursal();
        if (!this.frm_sucursal.invalid) {
            this.registra_service
                .guardarSucursal(this.frm_sucursal.value)
                .subscribe((resp) => {
                    let respuesta = JSON.parse(JSON.stringify(resp));
                    if (respuesta.numero < 0) {
                        DialogUtility.alert({
                            title:
                                '<span class="dialogo-titulo-error">' +
                                respuesta.titulo +
                                ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                            content: JSON.parse(JSON.stringify(resp)).mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    } else {
                        DialogUtility.alert({
                            title:
                                '<span style="color: #28a745">' +
                                respuesta.titulo +
                                ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                            content: JSON.parse(JSON.stringify(resp)).mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                        this.cargaDataSucursal();
                        this.NuevoFrmDomicilio();
                        this.dlgRegistroSucursal.hide();
                    }
                });
        }
    }

    /**
     * Metodo para crear el formulario de correos, para hacer el registro del mismo.
     */
    NuevoFrmCorreo() {
        this.frm_correo = this.fb.group({
            id: [-1],
            id_cliente_domicilio: [this.id_domicilio],
            correo: [''],
            correo_copia: [''],
            correo_oculta: [''],
        });
    }

    /**
     * Metodo para crear el formulario vacio para el registro del correo del domicilio.
     *
     */
    btnNuevoCorreo() {
        if (this.id_domicilio <= 0) {
            DialogUtility.alert({
                title:
                    '<span class="dialogo-titulo-error">' +
                    'Correo' +
                    ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content:
                    'Selecciona un domicilio para realizar el registro de un correo',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
            return;
        } else {
            this.dlgRegistroCorrreo.show();
            this.NuevoFrmCorreo();
        }
    }

    public btnBorrarCorreo(id) {
        this.id_correo = id;
        this.UtilComponent = DialogUtility.confirm({
            title:
                'Información Arcarius' +
                '[Alerta]' +
                ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
            content: '<h6><b>¿Estas seguro de eliminar este registro?</b></h6>',
            okButton: { text: 'Si', click: this.btnBorrarCorreo2.bind(this) },
            cancelButton: { text: 'No' },
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
        });
    }

    public btnBorrarCorreo2: EmitType<object> = () => {
        this.http
            .post(
                `${environment.base_url}/venta/cliente/eliminarCorreo/${this.id_correo}`,
                {}
            )
            .subscribe((respuesta) => {
                if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
                    DialogUtility.alert({
                        title: 'Informacion Arcarius',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                    this.cargaDataSucursalCorreo(this.id_domicilio);
                } else {
                    DialogUtility.alert({
                        title: 'Informacion Arcarius [Problemas]',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                }
                this.UtilComponent.hide();
                this.id_correo = -1;
            });
    };

    /**
     * Metodo para guardar la información del formulario de registro del correo  del domicilio seleccionado
     */

    public btnGuardarCorreo(): void {
        if (this.id_domicilio <= 0) {
            DialogUtility.alert({
                title:
                    '<span class="dialogo-titulo-error">' +
                    'Sucursal' +
                    ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content:
                    'Hay un error con el domicilio, favor de seleccionar el domicilio nuevamente',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
            return;
        }
        this.registra_service
            .guardarCorreo(this.frm_correo.value)
            .subscribe((resp) => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero < 0) {
                    DialogUtility.alert({
                        title:
                            '<span class="dialogo-titulo-error">' +
                            respuesta.titulo +
                            ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                        content: JSON.parse(JSON.stringify(resp)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                } else {
                    DialogUtility.alert({
                        title:
                            '<span style="color: #28a745">' +
                            respuesta.titulo +
                            ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: JSON.parse(JSON.stringify(resp)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                    this.cargaDataSucursal();
                    this.NuevoFrmCorreo();
                    this.dlgRegistroCorrreo.hide();
                }
            });
    }

    /**
     * Metodo para mostrar modal y gestionar archivos del proveedor
     */
    public btnArchivos() {
        if (this.formulario.controls['id'].value > 0) {
            const modalRef = this.modalService.open(DlgArchivoComponent);
            modalRef.componentInstance.TITULO = "Archivos relacionados al cliente";
            modalRef.componentInstance.id_cliente = this.formulario.controls['id'].value
            modalRef.componentInstance.carga();
            modalRef.result.then((resultado) => {
                if (resultado > 0) {
                    //this.onEstatus(resultado);
                }
            }, (reason) => {
                console.log(reason);
            });
        }
    }

    /**
     * Metodo para cerrar la ventana modal
     */
    public close() {
        this.offcanvasService.dismiss();
    }
}
