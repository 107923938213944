import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catalogoContableService } from 'src/app/services/FIcontabilidad/catalogoContable.service';

/**
 * Modelo de datos para gestionar la salida de la cuenta seleccionada
 */
export class cuentaContable {
  id: number;
  cuenta: string;
  descripcion: string
  constructor(id: number, cuenta: string, descripcion: string) {
    this.id = id;
    this.cuenta = cuenta;
    this.descripcion = descripcion;
  }
}

@Component({
  selector: 'dlgCuentaContable',
  templateUrl: './dlgCuentaContable.component.html',
  styleUrls: ['./dlgCuentaContable.component.scss']
})
export class dlgCuentaContableComponent {
  @ViewChild('referencia') referencia: ElementRef;
  @ViewChild('chkAfectable') chkAfectable: ElementRef;
  @Output('cuenta') cuenta = new EventEmitter<cuentaContable>();
  public data: any;

  constructor(
    public activeModal: NgbActiveModal,
    private servicio: catalogoContableService
  ) { }

  public getCatalogoBuscar() {
    this.servicio.getCatalogoBuscar(this.referencia.nativeElement.value, this.chkAfectable.nativeElement.checked).subscribe(resp => {
      this.data = resp.respuesta;
    });
  }


  /**
   * Metodo para retornar la cuenta seleccionar al componente que la solicita
   * @param args
   */
  public onSelected(args: any) {
    this.cuenta.emit(new cuentaContable(args.data.id, args.data.cuenta, args.data.descripcion));
    this.activeModal.close();
  }

}
