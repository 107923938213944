<div class="chatbot-icon" (click)="toggleChatbox()" style="left: 70px;">
    <mat-icon aria-hidden="false" fontIcon="chat"></mat-icon>
</div>

<div class="chatbox" [ngClass]="{ open: chatboxOpen }">
    <div class="header">
        <h2>Joss Bot AI</h2>
        <h6 class="right-align">by Jose Angeles</h6>
    </div>
    <app-conversacion class="chatbox-conversation" [messages]="messages"></app-conversacion>
    <div class="footerAI">
        <app-prompt (textChange)="handlePromptChange($event)"></app-prompt>
    </div>
</div>