<div class="modal-header">
    <h5 class="modal-title">{{"General.Cliente" | translate}}</h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body" style="padding: 10px 10px;">
    <div class="card">
        <div class="footer">
            <button class="btn btn-nuevo" (click)="btnNuevo()"> {{"Boton.Nuevo" | translate}}</button>
        </div>
        <div class="card-detail">
            <form [formGroup]="formulario" autocomplete="off">
                <div class="row">
                    <div class="col-4">
                        <div class='form-group'>
                            <label>Nombre:</label>
                            <input type="text" class="form-control" formControlName="nombre">
                            <small *ngIf="nombreNoValida" class="text-danger">Se requiere un
                                nombre</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class='form-group'>
                            <label for='id_rh_puesto'>Paterno:</label>
                            <input type="text" class="form-control" formControlName="paterno">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class='form-group'>
                            <label for='id_rh_grado'>Materno:</label>
                            <input type="text" class="form-control" formControlName="materno">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Codigo:</label>
                            <input type="text" class="form-control" formControlName="codigo">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>RFC:</label>
                            <input type="text" class="form-control" formControlName="rfc"
                                (input)="rfcCambios($event.target.value)">
                            <small *ngIf="rfcNoValida" class="text-danger">Se requiere un RFC</small>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Id Fiscal:</label>
                            <input type="text" class="form-control" formControlName="id_fiscal">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Whatsapp:</label>
                            <input type="text" class="form-control" formControlName="whatsapp">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Movil:</label>
                            <input type="text" class="form-control" formControlName="tel_movil">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label for='id_rh_puesto'>Oficina:</label>
                            <input type="text" class="form-control" formControlName="tel_oficina">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label for='id_rh_grado'>Fax:</label>
                            <input type="text" class="form-control" formControlName="tel_fax">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Fecha Ingreso:</label>
                            <ejs-datepicker #fecha_ingreso [format]='formatoFecha' formControlName="fecha_ingreso">
                            </ejs-datepicker>
                            <small *ngIf="fechaNoValida" class="text-danger">Se requiere una
                                fecha</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class='form-group'>
                            <label>{{"Documento.Estatus" | translate}}</label>
                            <ejs-dropdownlist #estatus [dataSource]='data_estatus' [fields]='fields'
                                formControlName="estatus" (change)='onChangeEstatus($event)'>
                            </ejs-dropdownlist>
                            <small *ngIf="estatusNoValida" class="text-danger">Se requiere un
                                estatus</small>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Clasificación:</label>
                            <ejs-dropdownlist #clasificacion [dataSource]='data_clasificacion' [fields]='fields'
                                formControlName="id_cliente_clasifica" (change)='onChangeClasifica($event)'>
                            </ejs-dropdownlist>
                            <small *ngIf="clasificacionNoValida" class="text-danger">Se requiere una
                                clasificación</small>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Regimen:</label>
                            <ejs-dropdownlist [dataSource]='data_regimen' [fields]='fields'
                                formControlName="id_sat_regimenfiscal">
                            </ejs-dropdownlist>
                            <small *ngIf="regimenNoValida" class="text-danger">Se requiere regimen
                                fiscal</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <div class='form-group'>
                            <label>Origen {{"General.Cliente" | translate}}</label>
                            <ejs-dropdownlist #origen [dataSource]='data_origen' [fields]='fields'
                                formControlName="id_cliente_origen" (change)='onChangeOrigen($event)'>
                            </ejs-dropdownlist>
                            <small *ngIf="origenNoValida" class="text-danger">Se requiere un
                                origen</small>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class='form-group'>
                            <label>Twitter:</label>
                            <input type="text" class="form-control" formControlName="twitter">
                        </div>
                    </div>
                    <div class="col-2">
                        <div class='form-group'>
                            <label>Ref. Bancaria:</label>
                            <input type="text" class="form-control" formControlName="referencia_bancaria">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Correo:</label>
                            <input type="text" class="form-control" formControlName="correo">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Cliente Potencial:</label>
                            <div class="input-group">
                                <button class="btn btn-buscar-interno feedback-form-control"
                                    (click)="btnAbrirBuscador()"></button>
                                <input hidden="true" type="text" #id_cliente_potencial class="form-control"
                                    readonly='true' formControlName="id_cliente_potencial">
                                <input id="clientePotencial" type="text" #clientePotencial
                                    class="form-control feedback-input" readonly='true'>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Cliente Relación:</label>
                            <div class="input-group">
                                <button class="btn btn-buscar-interno feedback-form-control"
                                    (click)="dlgCliente.show()"></button>
                                <input hidden="true" type="text" #id_cliente_Relacion class="form-control"
                                    readonly='true' formControlName="id_cliente">
                                <input id="clienteRelacion" type="text" #clienteRelacion
                                    class="form-control feedback-input" readonly='true'>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class='form-group'>
                            <div class="input-group">
                                <label>Cuenta Contable</label>
                                <div class="input-group">
                                    <button class="btn btn-buscar-interno feedback-form-control"
                                        (click)="btnCuentaContable(1)">
                                    </button>
                                    <input hidden="true" type="text" #id_con_catalogo class="form-control"
                                        readonly='true' formControlName="id_con_catalogo">
                                    <input id="cuentaCatalogo" type="text" #cuentaCatalogo
                                        class="form-control feedback-input" readonly='true'>
                                    <input id="numcuentaCatalogo" type="text" #numcuentaCatalogo class="form-control"
                                        readonly='true'>
                                    <small *ngIf="cuentaContNoValida" class="text-danger">Se requieres una
                                        cuenta
                                        contable</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Cuenta Contable Anticipo</label>
                            <div class="input-group">
                                <button class="btn btn-buscar-interno feedback-form-control"
                                    (click)="btnCuentaContable(2)"></button>
                                <input hidden="true" type="text" #id_con_catalogo_anticipo class="form-control"
                                    readonly='true' formControlName="id_con_catalogo_anticipo">
                                <input id="cuentaAnticipo" type="text" #cuentaAnticipo
                                    class="form-control feedback-input" readonly='true'>
                                <input id="numcuentaAnticipo" type="text" #numcuentaAnticipo class="form-control"
                                    readonly='true'>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="footer">
            <button class="btn btn-guardar" (click)="btnGuardar()"> {{"Boton.Guardar" | translate}}</button>
            <button class="btn btn-nuevo btn-derecha" (click)="btnAbrirSucursalPadre()"> Sucursal</button>
            <button class="btn btn-folder" (click)="btnArchivos()"> Archivos</button>
        </div>
    </div>
</div>


<!-- INICIA MODAL DE SUCURSAL-->
<ejs-dialog id="dlgSucursalPadre" #dlgSucursalPadre header='Sucursal' maxHeight='800px' width='800px' isModal='true'
    [visible]='false' showCloseIcon='true'>
    <div class="card">
        <div class="card-detail">
            <ejs-grid #tabla [dataSource]='data_sucursal' (rowSelected)="onRowSelectedSucursal($event)">
                <e-columns>
                    <e-column field='id' headerText='ID' width='0'>
                    </e-column>
                    <e-column headerText='' width='110' minWidth='110'>
                        <ng-template #template let-item>
                            <div style="display: inline-flex;">
                                <button class="btn btn-editar-sm" title="Modificar"
                                    (click)="btnEditarSucursal(item.id)"></button>
                                <button class="btn btn-borrar-sm" title="Eliminar"
                                    (click)="btnBorrarSucursal(item.id)"></button>
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column field='nombre' headerText='Sucursal'>
                    </e-column>
                    <e-column field='calle' headerText='Calle'>
                    </e-column>
                    <e-column field='colonia' headerText='Colonia'>
                    </e-column>
                    <e-column field='municipio' headerText='Municipio'>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <div class="footer">
            <button class="btn btn-nuevo" (click)="btnNuevaSucursal()">Nuevo
                Domicilio</button>
        </div>
    </div>
    <div class="card">
        <div class="card-detail">
            <div class="card-title mb-1 p-2">
                <h5>Correo</h5>
            </div>
            <ejs-grid #tabla [dataSource]='data__suc_correo' minWidth='600'>
                <e-columns>
                    <e-column field='id' headerText='ID' width='0'>
                    </e-column>
                    <e-column headerText='' width='110' minWidth='110'>
                        <ng-template #template let-item>
                            <div style="display: inline-flex;">
                                <button class="btn btn-editar-sm" title="Modificar"
                                    (click)="btnEditarCorreo(item.id)"></button>
                                <button class="btn btn-borrar-sm" title="Eliminar"
                                    (click)="btnBorrarCorreo(item.id)"></button>
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column field='correo' headerText='Correo' minWidth='200'>
                    </e-column>
                    <e-column field='correo_copia' headerText='Correo copia' minWidth='200'>
                    </e-column>
                    <e-column field='correo_oculta' headerText='Correo oculta' minWidth='200'>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <div class="footer">
            <button class="btn btn-nuevo" (click)="btnNuevoCorreo()">Nuevo
                {{"Boton.Correo" | translate}}</button>
        </div>
    </div>
</ejs-dialog>

<!-- FINAL MODAL DE SUCURSAL-->

<!-- INICIA MODAL REGISTRO SUCURSAL-->
<ejs-dialog id="dlgRegistroSucursal" #dlgRegistroSucursal header='Domicilio' maxHeight='80%' width='90%' isModal='true'
    [visible]='false' showCloseIcon='true'>
    <form [formGroup]="frm_sucursal" autocomplete="off">
        <div class="card">
            <div class="card-detail">
                <div class="row">
                    <div class="col-6">
                        <div class='form-group'>
                            <label>{{"General.Sucursal" | translate}}</label>
                            <input type="text" class="form-control" formControlName="nombre">
                            <small *ngIf="sucursaloNoValida" class="text-danger">Se requiere una
                                sucursal</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Calle:</label>
                            <input type="text" class="form-control" formControlName="calle">
                            <small *ngIf="calleNoValida" class="text-danger">Se requiere una
                                calle</small>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Num. Ext:</label>
                            <input type="text" class="form-control" formControlName="num_ext">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class='form-group'>
                            <label>Num. Int:</label>
                            <input type="text" class="form-control" formControlName="num_int">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Codigo Postal:</label>
                            <input type="text" #codigopostal class="form-control"
                                (input)="cpCambios($event.target.value)">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class='form-group'>
                            <label>Colonia:</label>
                            <ejs-dropdownlist #colonia [dataSource]='data_colonia' [fields]='fields'
                                formControlName="id_colonia">
                            </ejs-dropdownlist>
                            <small *ngIf="coloniaNoValida" class="text-danger">Se requiere una
                                colonia
                                <p>Si no aparece la colonia revise el codigo postal.</p>
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <div class='form-group'>
                            <label>País:</label>
                            <ejs-dropdownlist [dataSource]='data_pais' [fields]='fields' formControlName="pais"
                                (change)="CambioPais($event)">
                            </ejs-dropdownlist>
                            <small *ngIf="paisNoValida" class="text-danger">Se requiere un
                                pais</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class='form-group'>
                            <label>Estado:</label>
                            <ejs-dropdownlist [dataSource]='data_estado' [fields]='fields' formControlName="id_estado"
                                (change)="CambioEstado($event)">
                            </ejs-dropdownlist>
                            <small *ngIf="estadoNoValida" class="text-danger">Se requiere un
                                estado</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class='form-group'>
                            <label>Localidad:</label>
                            <ejs-dropdownlist [dataSource]='data_localidad' [fields]='fields'
                                formControlName="id_localidad">
                            </ejs-dropdownlist>
                            <small *ngIf="localidadNoValida" class="text-danger">Se requiere una
                                localidad</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class='form-group'>
                            <label>Municipio:</label>
                            <ejs-dropdownlist [dataSource]='data_municipio' [fields]='fields'
                                formControlName="id_municipio">
                            </ejs-dropdownlist>
                            <small *ngIf="municipioNoValida" class="text-danger">Se requiere un
                                municipio</small>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class='form-group'>
                            <label>Ejecutivo:</label>
                            <ejs-dropdownlist #ejecutivo [dataSource]='data_ejecutivo' [fields]='fields'
                                formControlName="id_rh_empleado">
                            </ejs-dropdownlist>
                            <small *ngIf="ejecutivoNoValida" class="text-danger">Se requiere un
                                empleado</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <button class="btn btn-guardar" (click)="btnGuardarSucursal()"> {{"Boton.Guardar" | translate}}</button>
            </div>
        </div>
    </form>
</ejs-dialog>

<!-- FINAL MODAL REGISTRO SUCURSAL-->

<!-- INICIA MODAL REGISTRO CORREO-->
<ejs-dialog id="dlgRegistroCorrreo" #dlgRegistroCorrreo header='Correos' maxHeight='50%' width='50%' isModal='true'
    [visible]='false' showCloseIcon='true'>
    <form [formGroup]="frm_correo" autocomplete="off">
        <div class="card">
            <div class="card-detail">
                <div class="row">
                    <div class="col-8">
                        <div class='form-group'>
                            <label>Correo:</label>
                            <input type="text" class="form-control" formControlName="correo">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <div class='form-group'>
                            <label>Correo Copia:</label>
                            <input type="text" class="form-control" formControlName="correo_copia">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <div class='form-group'>
                            <label>Correo:</label>
                            <input type="text" class="form-control" formControlName="correo_oculta">
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <button class="btn btn-guardar" (click)="btnGuardarCorreo()"> {{"Boton.Guardar" | translate}}</button>
            </div>
        </div>
    </form>
</ejs-dialog>
<!-- FINAL MODAL REGISTRO CORREO-->


<!-- INICIA MODAL DEL CLIENTE POTENCIAL-->
<ejs-dialog id="dlgClientePotencial" #dlgClientePotencial header='BUSCAR CLIENTE POTENCIAL' maxHeight='500px'
    width='500px' isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i> Buscar</div>
    </ng-template>
    <ng-template #content>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Buscar</span>
            </div>
            <input type="text" #referencia class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                (keydown.enter)="btnClientePotencial()">
            <div class="input-group-append">
                <button class="btn btn-outline-success" type="button" (click)="btnBuscarClientePotencial()">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    &nbsp; Buscar
                </button>
            </div>
        </div>
        <ejs-grid #tabla [dataSource]='data_clienteP' (rowSelected)="onRowSelected($event)">
            <e-columns>
                <e-column field='id' headerText='No.' width='0'></e-column>
                <e-column field='nombre' headerText='Nombre' width='50%'></e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>
