<!--
<aside class="sidebar shadow-sm sidebar1">
    <div id="btnBarraOcultar" class="iconoOcultar">
        <a (click)="toggleSidebarPin()">
            <i *ngIf="!barraOculta" class="fa-solid fa-chevron-left" style="margin-left: 5px;"></i>
            <i *ngIf="barraOculta" class="fa-solid fa-chevron-right" style="margin-left: 5px;"></i>
        </a>
    </div>

    <div *ngIf="cargando" id="cargador" class="align-items-center" style="padding: 3px 10px; display: flex;">
        <strong>Cargando...</strong>
        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
    </div>


    <nav class="sidebar-menu pt-3 pb-3">
        <div class="menu-header mt-3 mb-2">
            Accesos
        </div>
        <ul class="menu-items">
            <li>
                <a href="#" routerLink="/tablero/tablero2" routerLinkActive="active">
                    <i class="fa fa-tv"></i>
                    <span>Inicio</span>
                </a>
            </li>
            <li>
                <a href="#" routerLink="/tablero/tablero1" routerLinkActive="active">
                    <i class="fa fa-tv"></i>
                    <span>Tablero</span>
                </a>
            </li>
            <li *ngFor="let itemMenu of data">
                <a href="#" [routerLink]='[itemMenu.action]' routerLinkActive="active">
                    <img [src]="'assets\\iconos\\' + itemMenu.archivo + '.png'" alt="">
                    <span>{{itemMenu.descripcion}}</span>
                </a>
            </li>
        </ul>
    </nav>
</aside> -->

<nav class="main-menu" [ngClass]="{'menu-activo' : menuActivo}">
    <div style="text-align: center;margin-top: 20px;">
        <img *ngIf="menuActivo" [src]="imagen" [ngClass]="{'menu-activo' : menuActivo}" style="border-radius: 50%;" width="150" height="150">
        <img *ngIf="!menuActivo" src="assets/img/avatar.png" style="border-radius: 50%;" width="40" height="40">
        <br>
        <span *ngIf="menuActivo" style="font-weight: bold;">{{this.data.nombre}}</span>
    </div>
    <div class="scrollbar" id="style-1" *ngIf="this.data">
        <ul>
            <li>
                <div title="Abrir Menu" (click)="this.menuActivo = !this.menuActivo">
                    <i class="fa-solid fa-angles-right" [ngClass]="{'fa-angles-left' : menuActivo}"
                        style="width: 50px; height: 30px; text-align: center; padding-top: 10px;"></i>
                </div>
            </li>
            <!-- Acceso para pagina inicial o tablero -->
            <li>
                <a href="#" routerLink="/tablero/tablero2" title="Home">
                    <i class="fa fa-solid fa-house-user"></i>
                    <span class="nav-text">Inicio</span>
                </a>
            </li>
            <li>
                <a href="#" routerLink="/tablero/tablero1" title="Dashboard">
                    <i class="fa fa-solid fa-chart-pie"></i>
                    <span class="nav-text">Tablero</span>
                </a>
            </li>
            <!-- Procesamiento de atajos o shortcut de los menus -->
            <li class="darkerlishadow" *ngFor="let itemMenu of data.menu">
                <a href="#" [routerLink]='[itemMenu.action]' title="{{itemMenu.descripcion}}">
                    <img [src]="'assets\\iconos\\' + itemMenu.archivo + '.png'">
                    <span class="nav-text">{{itemMenu.descripcion}}</span>
                </a>
            </li>
            <!-- Menu de salida de la App -->
            <li class="logout">
                <a href="#" (click)="logout()" title="Salir">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    <span class="nav-text">Salida</span>
                </a>
            </li>

        </ul>
    </div>
</nav>