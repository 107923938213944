import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-firma',
    templateUrl: './firma.component.html',
    styleUrls: ['./firma.component.scss']
})
export class FirmaComponent {
    public signaturePad!: SignaturePad;
    @ViewChild('canvas') canvasEl!: ElementRef;
    @Output('firma') firma = new EventEmitter<string>();

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    }

    startDrawing(event: Event) {
        // works in device not in browser
    }

    moved(event: Event) {
        // works in device not in browser
    }

    /**
     * Metodo para limpiar el espacion de la firma
     */
    clearPad() {
        this.signaturePad.clear();
    }

}
