import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
    selector: 'app-imagen',
    templateUrl: './imagen.component.html',
    styleUrls: ['./imagen.component.scss']
})
export class ImagenComponent implements OnInit {

    @ViewChild('fileInput') fileInput!: ElementRef;
    public selectedImage: any;
    public webcamImage: WebcamImage | undefined;
    public isWebcamActive: boolean = false;
    public allowWebcam: boolean = false;
    private trigger: Subject<void> = new Subject<void>();

    ngOnInit() {
        WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
            this.allowWebcam = mediaDevices && mediaDevices.length > 0;
        });
        document.addEventListener('paste', this.handlePaste.bind(this));
    }

    /**
     * Abrir archivo desde el dispositivo
     * @param event 
     */
    selectFile(event: any): void {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.selectedImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    /**
     * Metodo para activar la cámara
     */
    triggerSnapshot(): void {
        this.trigger.next();
    }

    handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
        this.selectedImage = webcamImage.imageAsDataUrl;
        this.isWebcamActive = false;
    }

    handlePaste(event: ClipboardEvent): void {
        const items = event.clipboardData?.items;
        if (items) {
            const itemsArray = Array.from(items); // Convertimos a un array
            for (const item of itemsArray) {
                if (item.type.indexOf('image') !== -1) {
                    const file = item.getAsFile();
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e: any) => {
                            this.selectedImage = e.target.result;
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        }
    }

    toggleWebcam(): void {
        this.isWebcamActive = !this.isWebcamActive;
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }


    public openFileSelector() {
        document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
        return false;
    }


    // Método para manejar el evento de arrastrar archivo sobre la zona de soltar
    onDragOver(event: DragEvent): void {
        event.preventDefault();  // Evita el comportamiento por defecto
        event.stopPropagation();
        event.dataTransfer!.dropEffect = 'copy'; // Cambia el ícono a "copiar"
    }

    // Método para manejar cuando el archivo se suelta
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer?.files[0];
        if (file && file.type.startsWith('image/')) {
            this.loadFile(file);  // Carga la imagen seleccionada
        }
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    // Método para cargar el archivo
    loadFile(file: File): void {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.selectedImage = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}
