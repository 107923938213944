import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from '../../../shared/errores.service';
import { DialogUtility } from '@syncfusion/ej2-popups';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {
  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * Metodo para obtener los archivos de un determinado documento
   * @param id 
   * @returns 
   */
  public getArchivos(id: number) {
    return this.http.get<any>(`${environment.base_url}/venta/documentosventa/archivos/${id}`);
  }

  /**
   * Metodo para gestionar la descarga del archivo seleccionado
   * @param id Identificador del archivo
   * @returns 
   */
  public getDescarga(id: number): Observable<any> {
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get<any>(`${environment.base_url}/venta/documentosventa/descarga/${id}`, { responseType: resonseType, headers: headers })
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Elimnadmos el archivo con base en el identificador
   * @param id Numero de identificacion del archivo
   * @returns Observable para su ejecución
   */
  public borrarArchivo(id: number): Observable<any> {
    return this.http.post<any>(`${environment.base_url}/venta/documentosventa/borrarArchivo/${id}`, {})
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Blob) {
            err.error.text().then(text => {
              DialogUtility.alert({
                title: 'Informacion Arcarius',
                content: JSON.parse(text).mensaje,
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
              });
            });
          } else {
            DialogUtility.alert({
              title: 'Informacion Arcarius',
              content: JSON.parse(JSON.stringify(err.error)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
          }
          return throwError(this.errores.getErrores(err));
        })
      );
  }


}
