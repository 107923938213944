import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { PagesModule } from './pages/pages.module';
import { PagesComponent } from './pages/pages.component';
import { PerfilComponent } from './pages/SIusuario/perfil/perfil.component';
import { CorreoComponent } from './pages/SIusuario/correo/correo.component';
import { AuthModule } from './auth/auth.module';
import { OrganizacionModule } from './pages/SIorganizacion/organizacion.module';
import { SitableroRoutingModule } from './pages/SItablero/sitablero.routing';
import { NotificacionesComponent } from './pages/SIusuario/notificaciones/notificaciones.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChatbotComponent } from './chatbot/chatbot.component';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		SidebarComponent,
		FooterComponent,
		PagesComponent,
		CorreoComponent,
		PerfilComponent,
		NotificacionesComponent
	],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		CommonModule,
		BrowserModule,
		AuthModule,
		HttpClientModule,
		SharedModule,
		AppRoutingModule,
		SitableroRoutingModule,
		BrowserAnimationsModule,
		OrganizacionModule,
		PagesModule,
		NgbModule,
		DragDropModule,
		ChatbotComponent
	],
	providers: [
		//===============================================================================================
		// DatePipe se configura en esta seccion par afectar toda la aplicación
		{ provide: DatePipe },
		//===============================================================================================
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
		//{ provide: LocationStrategy, useClass: HashLocationStrategy }
	],
	bootstrap: [AppComponent],
	//===============================================================================================
	// La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
	// html de cada componente. Esto para los componentes importados de terceros SyncFusion.
	//===============================================================================================
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
	//===============================================================================================
})
export class AppModule { }
