import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DlgBusquedaComponent } from 'src/app/components/dlgBusqueda/dlgBusqueda.component';
import { environment } from 'src/environments/environment';
import { GccalidadService } from '../services/gccalidad.service';
import { EmitType } from '@syncfusion/ej2-base';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { SelectEventArgs } from '@syncfusion/ej2-navigations';
import { DlgEstatusComponent } from '../../../components/dlgEstatus/dlgEstatus.component';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MdlComentarios } from '../models/MdlComentarios';
import { UntypedFormBuilder } from '@angular/forms';
import { dlgMensajeError } from '../../../components/dlgMensajeError/dlgMensajeError.component';
import { DlgComentarioComponent } from '../../../components/dlgComentario/dlgComentario.component';
import { DomSanitizer } from '@angular/platform-browser';
import { dlgMensajeConfirma } from '../../../components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { dlgMensaje } from '../../../components/dlgMensaje/dlgMensaje.component';
import { util } from 'src/app/shared/util';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { DlgEmpleadoComponent } from 'src/app/components/dlgEmpleado/dlgEmpleado.component';
import { ImportarArchivos } from '../importar-archivos/importar-archivos';

class Itemvariable {
    key: any;
    value: any;
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }
}

@Component({
    selector: 'dlgFormulario',
    templateUrl: './dlgFormulario.component.html',
    styleUrls: ['./dlgFormulario.component.scss'],
})
export class dlgFormulario implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('dlgComentario') dlgComentario: DlgComentarioComponent;
    @ViewChild('frmParametros') frmParametros: ElementRef;
    @ViewChild('id_generico') id_generico: ElementRef;
    @ViewChild('tfBusqueda') tfBusqueda: ElementRef;
    @ViewChild('dlgBusqueda') dlgBusqueda: DlgBusquedaComponent;
    @ViewChild('dlgEstatus') dlgEstatus: DialogComponent;
    @ViewChild('dlgArchivos') dlgArchivos: DialogComponent;
    @ViewChild('archivo') archivo: ElementRef;
    @ViewChild('dlgimagenPregunta') dlgimagenPregunta: DialogComponent;
    @ViewChild('imgRenderer') imgRenderer: ElementRef;

    @Input('id_frm_informacion') public id_frm_informacion: number = -1;
    @Input('id_frm_encabezado') public id_frm_encabezado: number = -1;

    public modoEdicion: boolean = false;
    public estatus: string = '';
    public usuario: string = '';
    public descripcion: string = '';
    public dataComentarios: any;
    public dataHistorial: any;
    public frmComentario: UntypedFormGroup = new UntypedFormGroup({});
    public imagen_tmp: string = '';
    public imagen_id: number = -1;
    public imageFile: { link: string; file: any; name: string };
    public data: any;
    public fields: Object = { value: 'id', text: 'descripcion' };
    public formulario: UntypedFormGroup = new UntypedFormGroup({});
    public format: string = 'dd-MM-yyyy';
    public habilitado = false;
    public dataEstatus: any;
    public intervalo: any;
    public texto: Object[];
    public valor: any;
    UtilComponent: any;
    public dataArchivos: any;
    public id_documento: number = -1;
    private archivos: UploaderComponent;
    public frmArchivo: any;
    public archivoArea: HTMLElement;
    public dropEle: HTMLElement;
    public dropEleImg: HTMLElement;
    public newImage: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private gc_calidad_Service: GccalidadService,
        private modalService: NgbModal,
        public offcanvasService: NgbOffcanvas,
        private fb: UntypedFormBuilder,
        public domSanitizer: DomSanitizer,
        private utilerias: util,
        public datepipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.dropEle = document.getElementById('droparea');
        this.getComentarios();
        this.getHistorial();
    }

    ngAfterViewInit(): void {
        this.cargaFormulario();
    }

    /**
     * Metodo para carga de datos del formulario
     */
    private async cargaFormulario() {
        this.blockUI.start('Procesando...');
        console.log(this.id_frm_informacion);
        console.log(this.id_frm_encabezado);
        this.gc_calidad_Service.lstparametros(this.id_frm_informacion, this.id_frm_encabezado).subscribe(
            (resp) => {
                this.data = JSON.parse(JSON.stringify(resp)).respuesta.data;
                let informacion = JSON.parse(JSON.stringify(resp)).respuesta;
                //this.id_frm_informacion = informacion.id;
                this.descripcion = informacion.descripcion + (informacion.folio == undefined ? "" : " Folio: " + informacion.folio);
                this.modoEdicion = informacion.modoedicion;
                this.estatus = informacion.estatus;
                this.usuario = informacion.usuario;
                if (this.data === '') {
                    // =================================================================
                    // Si no tenemos "data" quiere decir que no hay "parametros"
                    // Terminamos este metodo
                    // =================================================================
                    return;
                }
                this.data.forEach((elemento) => {
                    switch (elemento.tipo) {
                        case 1:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.numerico, Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.numerico));
                            }
                            break;
                        case 2:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.texto, Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.texto));
                            }
                            break;
                        case 3:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(new Date(elemento.fecha + 'T00:00:00'), Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(new Date(elemento.fecha + 'T00:00:00')));
                            }
                            break;
                        case 4:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.id_frm_opciones, Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.id_frm_opciones));
                            }
                            break;
                        case 5:
                            // El tipo 5 es busqueda, entonces necesitamos el ID y la descripcion del ID seleccionado
                            //(<HTMLInputElement>document.getElementById('id_' + elemento.id_frm_detalle)).value = elemento.texto;
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.numerico, Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(elemento.numerico));
                            }
                            break;
                        case 6:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(JSON.parse(elemento.texto), Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(JSON.parse(elemento.texto)));
                            }
                            break;
                        // case 7:
                        //   this.formulario.addControl(
                        //     elemento.id_frm_detalle,
                        //     new UntypedFormControl('', Validators.required)
                        //   );
                        //   break;
                        case 8:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl(''));
                            }
                            break;
                        case 9:
                            if (elemento.obligatorio) {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('data:image/png;base64,' + elemento.imagen, Validators.required));
                            } else {
                                this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('data:image/png;base64,' + elemento.imagen));
                            }
                            break;
                    }
                });
            },
            (err) => {
                console.log(err);
                //closeLoadingBar();
            },
            () => {
                setTimeout(() => {
                    this.data.forEach((elemento) => {
                        switch (elemento.tipo) {
                            case 5:
                                // El tipo 5 es busqueda, entonces necesitamos el ID y la descripcion del ID seleccionado
                                (<HTMLInputElement>(
                                    document.getElementById('id_' + elemento.id_frm_detalle)
                                )).value = elemento.texto;
                                break;
                        }
                    });
                    this.blockUI.stop();
                }, 1000);
            }
        );
    }



    /**
     * Metodo para ser ejecutado en cada ocasión que un componente cambio su valor.
     * Esto pasa para componentes de lista donde seleccionamos un valor
     * También se consideran los componentes que tiene una formula relacionada
     * @param event
     */
    public onFocusOutEvent(event: any) {
        //===========================================================================================
        // Constante para recuperar las variables que estan entre corchetes, por ejempo [C1]
        //===========================================================================================
        //const regex = /\[([^\]\[\r\n]*)\]/gm;
        const regex = /\{([^\}\{\r\n]*)\}/gm;
        //===========================================================================================
        // Listado con los valores de las variable a partir de los controles del formulario
        //===========================================================================================
        var valores: any = [];
        //var valores: any = [{"key":"C1", "value": "10"},{"key":"C2", "value": "20"},{"key":"C3", "value": "30"},{"key":"C4", "value": "40"}];
        let controles = JSON.parse(JSON.stringify(this.formulario.value));
        for (let control in controles) {
            //=========================================================================================
            // Agregamos el compomente con C como valor principal
            //=========================================================================================
            valores.push(new Itemvariable('C' + control, controles[control]));
            //=========================================================================================
            // Si elemento es de tipo SELECT entonces agregamos otro dato mas a valores para tener
            // el "value" de C que corresponde al ID, y el el "value" de T que corresponde a la descripcion
            // con component SELECT
            // Como se utiliza el componente sync, el valor seleccionado siempre es Cero options[0]
            //=========================================================================================
            if (document.getElementById('C_' + control + '_hidden') != null || document.getElementById('C_' + control + '_hidden') != undefined) {
                valores.push(
                    new Itemvariable(
                        'T' + control,
                        (document.getElementById('C_' + control + '_hidden') as HTMLSelectElement).options[0].text
                    )
                );
            }
        }
        //===========================================================================================
        // Obtenecion de todos los componentes que identificamos a traves de las class = "formula"
        // ya que estos conponentes tiene asiganda una formula
        //===========================================================================================
        let elementos = document.querySelectorAll(".formula");
        //===========================================================================================
        // Recorrido por los componentes para identificar las formulas, evaluarlas y ejecutarlas
        //===========================================================================================
        let items: string[] = [];
        elementos.forEach(async elemento => {
            let ele = elemento as HTMLElement;
            //===========================================================================================
            // Ejecutar formula
            //===========================================================================================
            let formula = ele.getAttribute('aria-label');
            let m;
            let resultado;
            let respuesta = formula;
            while ((m = regex.exec(formula)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                resultado = valores.find(registro => registro.key === m[1]);
                respuesta = respuesta.replace(m[0], (resultado.value instanceof Number ? resultado.value : "'" + resultado.value + "'"));
            }

            //===========================================================================================
            // Buscamos las formulas, las ejecutamos y posteriormente sustituimos sus valores en
            // la formula inicial para que se consideren los valores obtenidos
            //===========================================================================================

            const regexFuciones = /fx_\w*\([^\[*]*(\d+)[^\d]*\)/gm;
            let x = 0;
            let apiResultado = respuesta;
            while ((m = regexFuciones.exec(respuesta)) !== null) {
                if (m.index === regexFuciones.lastIndex) {
                    regexFuciones.lastIndex++;
                }
                if (m[0] !== null || m[0] != undefined) {
                    items.push(m[0]);
                }
                x++;
                if (x > 10) {
                    break;
                }
            }

            let obs$: Observable<any>;
            let counter: number = 0;

            obs$ = Observable.create(observer => {
                let intervalID = setInterval(() => {
                    observer.next(items[counter++]);
                    if (counter >= items.length) {
                        clearInterval(intervalID);
                        observer.complete();
                    }
                }, 1000);
            });

            obs$.subscribe(res => {
                this.gc_calidad_Service.getFuncion(res).subscribe(respuesta => {
                    console.log(res);
                    console.log(apiResultado);
                    apiResultado = apiResultado.replace(res, respuesta.respuesta);
                    let valor = this.utilerias.evaluar(apiResultado);
                    //======================================================================================================
                    // Colocamos el resultado de la evaluacon dentro del control el cual ajusta el componente HTML
                    // (<HTMLInputElement>ele).value = valor.toString(); <= Esta opcion no funciona porque no cambio el control
                    // del formulario
                    //======================================================================================================
                    this.formulario.controls[(<HTMLInputElement>ele).id.replace('C_', '')].setValue(valor);
                    //======================================================================================================
                });
            },
                err => {
                    console.log(`Error: ${err}`);
                },
                () => {
                    console.log("complete");
                }
            );
        });
    }

    async getFunciones(funcion: string) {
        await this.gc_calidad_Service.getFuncion(funcion).subscribe(resp => {
            return resp.respuesta;
        });
    }

    /**
     * Metodo para limpiar el componente e iniciar con otra captura
     */
    public btnNuevo() {
        this.offcanvasService.dismiss();
        this.id_frm_informacion = -1;
        this.router.navigate(['/calidad/formulario', -1, this.id_frm_encabezado]);
        this.formulario.reset();
        this.getComentarios();
        this.getHistorial();
    }


    /**
     * Metodo para lanzar ventana de busqueda de empleado y procesar la seleccion
     */
    public btnBuscar() {
        const modalRef = this.modalService.open(DlgEmpleadoComponent);
        modalRef.componentInstance.empleado.subscribe(($event) => {
            this.gc_calidad_Service.usuarioFormulario(this.id_frm_informacion, $event.id).subscribe((resp) => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero > 0) {
                    this.usuario = $event.nombre + ' ' + $event.paterno + ' ' + $event.materno;
                    const modalRef = this.modalService.open(dlgMensaje);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                } else {
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                }
            });
        });
    }



    /**
     * Metodo para mostar la ventana de comentarios con respecto a la
     * incidencia seleccionada, para poder ver o agregar nuevos comentarios
     */
    public btnComentario(): void {
        this.frmComentario = this.fb.group({
            comentario: ['', Validators.required],
        });
        let comentario = new MdlComentarios();
        comentario.id_frm_informacion = this.id_frm_informacion;
        this.frmComentario = this.fb.group(comentario);
        this.dlgComentario.Mostrar();
    }

    /**
     * Metodo para obetener listado de comentarios
     */
    public getComentarios() {
        this.gc_calidad_Service
            .getListadoComentarios(this.id_frm_informacion)
            .subscribe((resp) => {
                this.dataComentarios = resp.respuesta;
            });
    }

    /**
     * Metodo para obetener listado de comentarios
     */
    public getHistorial() {
        this.gc_calidad_Service
            .getListadoHistorial(this.id_frm_informacion)
            .subscribe((resp) => {
                this.dataHistorial = resp.respuesta;
            });
    }

    public onGuardarComentario(descripcion: any) {
        if (descripcion == null || descripcion == '') {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Formularios';
            modalRef.componentInstance.contenido = 'No se ha escrito comentario!';
            return;
        }
        this.frmComentario.get('comentario').setValue(descripcion);
        this.gc_calidad_Service.guardarComentario(this.frmComentario.value).subscribe((resp) => {
            let respuesta = JSON.parse(JSON.stringify(resp));
            if (respuesta.numero < 0) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = JSON.parse(
                    JSON.stringify(respuesta)
                ).titulo;
                modalRef.componentInstance.contenido = JSON.parse(
                    JSON.stringify(respuesta)
                ).mensaje;
            } else {
                this.getComentarios();
                this.dlgComentario.Ocultar();
            }
        });
    }

    /**
     * Metodo para validar un control en especifico
     * @param descripcion
     * @returns
     */
    public validaControl(descripcion: string) {
        return this.formulario.get(descripcion).invalid && this.formulario.get(descripcion).touched;
    }

    /**
     * Metodo para visitar todos los controles del formulario y activar las validaciones
     * @returns
     */
    public validaFormulario() {
        if (this.formulario.invalid) {
            return Object.values(this.formulario.controls).forEach((control) => {
                control.markAsTouched();
            });
        }
    }


    /**
     * Metodo para guardarla informacion del fomulario
     */
    public btnGuardar() {
        this.validaFormulario();
        if (this.formulario.invalid) {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Formularios';
            modalRef.componentInstance.contenido = 'Faltan Campos por llenar...';
        } else {
            //=============================================================================================
            // Creamos una variable de tipo Array y generamos un ciclo FOR, en dicho ciclo
            // usamos el tamaño de los datos que se obtienen de la base de datos,
            // la variable "data2" le vamos a concatenar "data2" con la propiedad "concat" que se utiliza para
            // concatenar datos dentro de un Array.
            //
            // Utilizamos datepipe para transformar la fecha y retirar la zona horaria, la cual utiliza
            // por default GMT+6 y coloca la fecha con 18:00:00 hrs, la cual al ser procesado por JSON
            // la convierte en fecha con cero horas T00:00:00 pero le suma horas, así que puede pasar
            // de una fecha del dia 1 de febrero a ser 2 de febrero.
            //
            //=============================================================================================
            let data2 = [];
            for (let i = 0; i < this.data.length; i++) {
                data2 = data2.concat({
                    id: String(this.data[i].id_frm_detalle),
                    tipo: this.data[i].tipo,
                    datos:
                        this.data[i].tipo == 7
                            ? 'x' // Tipo es Titulo
                            : this.data[i].tipo == 3 // Tipo es Fecha
                                ? this.datepipe.transform(this.formulario.controls[this.data[i].id_frm_detalle].value, 'yyyy-MM-ddT00:00:00')
                                : this.data[i].tipo == 6 // Tipo es MULTICONSULTA
                                    ? JSON.stringify(this.formulario.controls[this.data[i].id_frm_detalle].value)
                                    : this.formulario.controls[this.data[i].id_frm_detalle].value
                });
            }
            this.blockUI.start('Procesando...');
            this.http.post(`${environment.base_url}/gccalidad/formulario/insertarFormulario/${this.id_frm_encabezado}/${this.id_frm_informacion}`, data2).subscribe((respuesta) => {
                if (JSON.parse(JSON.stringify(respuesta)).numero < 0) {
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title:
                            '<span class="dialogo-titulo-error"> Documentos <i style="color: #fff" class="fas fa-ban"></i></span>',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                } else {
                    this.id_frm_informacion = JSON.parse(
                        JSON.stringify(respuesta)
                    ).numero;
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title:
                            '<span style="color: #28a745"> Formulario <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: JSON.parse(JSON.stringify(respuesta)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                }
            });
        }
    }

    // ==================================================================================
    // Al seleccionar un registro de la tabla de Busqueda
    // ==================================================================================
    public onBusquedaSelect(args, id_frm_detalle: number) {
        if (args === undefined) {
            this.dlgBusqueda.hide();
            return;
        } else {
            this.formulario.controls[id_frm_detalle].setValue(args.id);
            (<HTMLInputElement>(
                document.getElementById('id_' + id_frm_detalle)
            )).value = args.descripcion;
        }
    }

    btnEstatus() {
        const modalRef = this.modalService.open(DlgEstatusComponent);
        modalRef.componentInstance.tipo = 1106;
        modalRef.componentInstance.id = this.id_frm_informacion;
        modalRef.componentInstance.formulario = this.id_frm_encabezado;
        modalRef.componentInstance.carga();
        modalRef.componentInstance.estatus.subscribe(($event) => {
            this.blockUI.start('Procesando...');
            this.http.post(`${environment.base_url}/gccalidad/formulario/guardarEstatus/${this.id_frm_informacion}/${$event.id}`, {}).subscribe((respuesta) => {
                if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
                    this.gc_calidad_Service.lstparametros(this.id_frm_informacion, this.id_frm_encabezado).subscribe((resp) => {
                        this.modoEdicion = JSON.parse(JSON.stringify(resp)).respuesta.modoedicion;
                        this.estatus = JSON.parse(JSON.stringify(resp)).respuesta.estatus;
                        this.getHistorial();
                    });
                    this.blockUI.stop();
                } else {
                    this.blockUI.stop();
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                }
            });
        });
    }

    /**
     * Metodo para imprimir el formulario
     * Este reporte se configura dentro del formulario
     * Importante poner los corchetas {} vacios, ya que puede ser que no recuperamos de manera correcta el PDF
     */
    public btnImprimir() {
        // ================================================================================
        // Ejecutamos el reporte con los parametros proporcionados
        // ================================================================================
        this.habilitado = true;
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        this.http.post<any>(`${environment.base_url}/reporte/formulario/${this.id_frm_informacion}`, {}, { responseType: resonseType, headers: headers }).subscribe((response) => {
            let blob: any = new Blob([response], {
                type: 'application/pdf; charset=utf-8',
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
            this.habilitado = false;
        },
            (err) => {
                alert('Problemas al generar reporte');
                this.habilitado = false;
            }
        );
    }

    /**
     * Método para importar archivo de excel
     */
    public btnImportArchivos() {
        const modalRef = this.modalService.open(ImportarArchivos);
        modalRef.componentInstance.encabezado = "Importar Archivos";
        modalRef.componentInstance.contenido = "Importar archivo XLS o XLSX";
        modalRef.componentInstance.id = this.id_frm_informacion;
        modalRef.result.then((resultado) => {
            if (resultado > 0) {
                this.btnBuscar();
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = 'Formularios';
                modalRef.componentInstance.contenido = 'Importacion Procesada.';
            }
        }, (reason) => {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Formularios';
            modalRef.componentInstance.contenido = reason;
        });
    }


    /**
     * Metodo para el dialogo de archivos
     *
     * Se usa para abrir el dialogo de los archivos al darle click al botón
     * @returns
     */
    btnArchivos() {
        this.listadoArchivos();
        this.dlgArchivos.show();
    }

    /**
     * Metodo para el listado de los archivos
     *
     * Se usa para mostrar el listado de los archivos que tiene asignado el formulario
     * @id_frm_informacion Numero o identificador de la tabla frm_informacion
     * @returns
     */
    listadoArchivos() {
        this.gc_calidad_Service
            .listadoArchivo(this.id_frm_informacion)
            .subscribe((resp) => {
                this.dataArchivos = JSON.parse(JSON.stringify(resp)).respuesta;
            });
    }

    /**
     * Metodo para la descarga del archivo desde la API
     *
     * Se utiliza un elemento <a> para gestionar la url de descarga, así como el nombre del archivo
     *
     * @id Numero o identificador del archivo
     * @nombre Nombre del archivo con el cual se descargara dicho archivo
     * @returns
     */
    public descarga(id: number, nombre: string, imagen: any) {
        this.gc_calidad_Service.getDescargaArchivoFrm(id).subscribe((resp) => {
            const element = document.createElement('a');
            const blob = new Blob([resp], { type: 'application/octet-stream' });
            element.href = window.URL.createObjectURL(blob);
            element.download = nombre;
            element.click();
        });
    }

    /**
     * Metodo para eliminar registro de formulario
     * @param id
     */
    public btnBorrarFormulario() {
        const modalRef = this.modalService.open(dlgMensajeConfirma);
        modalRef.componentInstance.encabezado = 'Formulario';
        modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
        modalRef.result.then((resultado) => {
            if (resultado == 'Si') {
                this.gc_calidad_Service
                    .borrar(this.id_frm_informacion)
                    .subscribe((resp) => {
                        let respuesta = JSON.parse(JSON.stringify(resp));
                        if (respuesta.numero < 0) {
                            const modalRef = this.modalService.open(dlgMensajeError);
                            modalRef.componentInstance.encabezado = JSON.parse(
                                JSON.stringify(respuesta)
                            ).titulo;
                            modalRef.componentInstance.contenido = JSON.parse(
                                JSON.stringify(respuesta)
                            ).mensaje;
                        } else {
                            this.router.navigate(['/calidad/listado']);
                        }
                    });
            }
        });
    }

    public btnBorrar(id) {
        this.id_documento = id;
        this.UtilComponent = DialogUtility.confirm({
            title: ' Información Arcarius',
            content: '¿Estas seguro de eliminar este archivo?',
            okButton: { text: 'Si', click: this.btnBorrar2.bind(this) },
            cancelButton: { text: 'No' },
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
        });
    }

    public btnBorrar2: EmitType<object> = () => {
        this.blockUI.start('Procesando...');
        this.http.post<any>(`${environment.base_url}/gccalidad/formulario/eliminarArchivo/${this.id_documento}`, {}).subscribe((respuesta) => {
            if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
                this.listadoArchivos();
                this.blockUI.stop();
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = respuesta.titulo;
                modalRef.componentInstance.contenido = respuesta.mensaje;
                this.blockUI.stop();
                this.listadoArchivos();
            }
            this.UtilComponent.hide();
            this.id_documento = -1;
        });
    };

    /**
     * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
     * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
     * es utilizada para guardar el archivo con el metodo "guardarArchivo()"
     *
     * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
     * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
     */
    public selected(args: UploaderComponent) {
        this.archivos = args;
        this.guardarArchivo();
    }

    /**
     * Identificamos el componente de area de soltar, que será utilizada para arrastrar los archivos
     * y soltarlos.
     * Se tiene que haces este truco, ya que el componente de uploader se renderiza hasta que el dialog
     * se carga o visualiza la primera vez.
     * Asi mismo ocultamos el componente con el boton de "browser" y su área, de esta manera
     * dejamos solo el div personalizado donde se arrastra y el link paa abrir el buscador de archivos
     *
     * Esta se dispara cuando seleccionamos la pestña numero 1 (comenzamos a contar desde el Cero)
     *
     * @param e No utilizado por el momento
     */
    public handleSelectEvent(e: SelectEventArgs): void {
        if (e.selectedIndex === 1) {
            this.archivoArea = document.getElementById('droparea');
        }
    }

    /**
     * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
     * @returns
     */
    public browseClick() {
        document
            .getElementsByClassName('e-file-select-wrap')[0]
            .querySelector('button')
            .click();
        return false;
    }

    public guardarArchivo() {
        if (this.archivos == undefined) {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Formulario';
            modalRef.componentInstance.contenido = 'No has seleccionado un archivo';
            return;
        }
        this.blockUI.start('Procesando...');
        this.frmArchivo = new FormData();
        this.frmArchivo.append(
            'archivo',
            this.archivos.filesData[0].rawFile,
            this.archivos.filesData[0].name
        );
        this.frmArchivo.append('Content-Type', 'application/json');
        this.frmArchivo.append('Accept', `application/json`);
        this.http.post(`${environment.base_url}/gccalidad/formulario/subirArchivo/${this.id_frm_informacion}`, this.frmArchivo).subscribe((resp) => {
            let respuesta = JSON.parse(JSON.stringify(resp));
            if (respuesta.numero > 0) {
                //==============================================================================
                // Cargamos los nuevos valores de los archivos procesados
                //==============================================================================
                this.listadoArchivos();
                //==============================================================================
                // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
                // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
                //==============================================================================
                (
                    document.getElementById('archivoCarga') as any
                ).ej2_instances[0].clearAll();
                //==============================================================================
                this.archivos = undefined;
                this.blockUI.stop();
            } else {
                this.blockUI.stop();
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Formulario';
                modalRef.componentInstance.contenido = 'Hay error en el archivo';
            }
        });
    }

    public openImagen() {
        this.dropEleImg = document.getElementById('dropareaImg');
    }

    public btnImagen(id) {
        this.imagen_id = id;
        this.dlgimagenPregunta.show();
    }

    public imagesPreview(event: any, id: string) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event: any) => {
                this.imageFile = {
                    link: _event.target.result,
                    file: event.srcElement.files[0],
                    name: event.srcElement.files[0].name,
                };
                this.imagen_tmp = String(reader.result);
            };
        }
    }

    public btnImportar() {
        /*
        let cantidad: number = this.archivo.nativeElement.files.length;
        if (cantidad === 0) {
          DialogUtility.alert({
            title: 'Imagen Pregunta',
            content: 'Selecciona una imagen!',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
          return;
        }
        */
        this.formulario.controls[this.imagen_id].setValue(String(this.imagen_tmp));
        this.dlgimagenPregunta.hide();
    }

    public selectedImg(args: UploaderComponent) {
        this.archivos = args;
        const reader = new FileReader();
        reader.readAsDataURL(args.filesData[0].rawFile as any);
        reader.onload = (_event: any) => {
            this.imageFile = {
                link: _event.target.result,
                file: args.filesData[0].rawFile,
                name: args.filesData[0].name,
            };
            const img = new Image();
            img.src = String(reader.result);
            img.onload = () => {
                /**
                 * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
                 */
                let width = img.width;
                let height = img.height;
                let MAX_WIDTH: any = 1000;
                let MAX_HEIGHT: any = 600;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                const data = ctx.canvas.toDataURL();
                this.imagen_tmp = data;
            };
            //this.imagen_tmp = String(reader.result);
        };
    }

    public browseClickImg() {
        document
            .getElementsByClassName('e-file-select-wrap')[1]
            .querySelector('button')
            .click();
        return false;
    }

    public btnPegarImagen() {
        navigator.clipboard
            .read()
            .then((items) => {
                for (const item of items) {
                    for (let type of item.types) {
                        if (type.indexOf('image') === 0) {
                            item.getType('image/png').then((imageBlob) => {
                                if (imageBlob !== null) {
                                    const fileFromBlob: File = new File(
                                        [imageBlob],
                                        'imagen.jpg'
                                    );
                                    const reader = new FileReader();
                                    reader.onload = (evt: any) => {
                                        const img = new Image();
                                        img.src = String(reader.result);
                                        img.onload = () => {
                                            /**
                                             * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
                                             */
                                            let width = img.width;
                                            let height = img.height;
                                            let MAX_WIDTH: any = 1000;
                                            let MAX_HEIGHT: any = 600;
                                            if (width > height) {
                                                if (width > MAX_WIDTH) {
                                                    height *= MAX_WIDTH / width;
                                                    width = MAX_WIDTH;
                                                }
                                            } else {
                                                if (height > MAX_HEIGHT) {
                                                    width *= MAX_HEIGHT / height;
                                                    height = MAX_HEIGHT;
                                                }
                                            }
                                            const elem = document.createElement('canvas');
                                            elem.width = width;
                                            elem.height = height;
                                            const ctx = elem.getContext('2d');
                                            ctx.drawImage(img, 0, 0, width, height);
                                            const data = ctx.canvas.toDataURL();
                                            this.imagen_tmp = data;
                                        };

                                        this.imagen_tmp = String(evt.target.result);
                                        this.imgRenderer.nativeElement.src = this.imagen_tmp;
                                    };
                                    reader.readAsDataURL(imageBlob);
                                }
                            });
                        }
                    }
                }
            })
            .catch((err) => {
                // maybe user didn't grant access to read from clipboard
                console.log('Something went wrong', err);
            });
    }
}
